
export const dashboardImages = {
    gameCard: require('./images/exampleImage.jpg'),
    resultsCard: require('./images/exampleImage.jpg'),
    leaderboardCard: require('./images/exampleImage.jpg')
}

export const logos = {
    gameOn: require('./images/logos/RedCard.png'),
    croppedGameOn: require('./images/logos/RedCard.png'),
    headerGameOnLogo: require('./images/logos/header_logo.png'),
    bid4cars: require('./images/logos/Bid4Cars.jpg'),
    // images: [
    //     require('./images/exampleImage.jpg'),
    // ]
}

export const gameCategoriesImages = { // make array
    gameCard: require('./images/exampleImage.jpg'),
    images: [
        require('./images/exampleImage.jpg'),
        require('./images/exampleImage.jpg'),
        require('./images/exampleImage.jpg'),
    ]
}

export const trophies = {
    gold: require('./images/trophies/GoldTrophy.png'),
    silver: require('./images/trophies/SilverTrophy.png'),
    bronze: require('./images/trophies/BronzeTrophy.png'),
    grey: require('./images/trophies/GreyedOutTrophy.png'),
}

export const images =  // make array
    [
        require('./images/BusinessImages1_3/Business-integration.png'),
        require('./images/BusinessImages1_3/Business-ideation.png'),
        require('./images/BusinessImages1_3/Business-brainstorm.png'),
        require('./images/BusinessImages1_3/Business-communication.png'),
        require('./images/BusinessImages1_3/Business-growth.png'),
        require('./images/BusinessImages1_3/Business-ideation-team.png'),
        require('./images/BusinessImages1_3/Teamwork-progress.png'),
        // require('./images/BusinessImages1_3/Business-announcement.png'),
        require('./images/BusinessImages1_3/Business-go-live.png'),
        require('./images/BusinessImages1_3/Business-idea.png'),
        require('./images/BusinessImages1_3/Business-investment.png'),
        require('./images/BusinessImages1_3/Business-making-money.png'),
        require('./images/BusinessImages1_3/Business-present.png'),
        require('./images/BusinessImages1_3/Business-motivation.png'),
        require('./images/BusinessImages1_3/Business-process-teamwork.png'),
        require('./images/BusinessImages1_3/Business-proposal.png'),
        require('./images/BusinessImages1_3/Business-proposal_1.png'),
        require('./images/BusinessImages1_3/Business-saving.png'),
        require('./images/BusinessImages1_3/Business-teamwork-all.png'),
        require('./images/BusinessImages1_3/Business-teamwork-graph.png'),
        require('./images/BusinessImages1_3/Business-work-from-home.png'),
        require('./images/BusinessImages1_3/Teamwork-graph.png'),
        require('./images/BusinessImages1_3/Teamwork-progress.png'),
    ]

export const backgroundImages =  // make array
    [
        require('./images/backgroundImages/Artboard41.png'),
        require('./images/backgroundImages/Artboard42.png'),
        require('./images/backgroundImages/Artboard43.png'),
        require('./images/backgroundImages/Artboard44.png'),
        require('./images/backgroundImages/Artboard45.png'),
        require('./images/backgroundImages/Artboard46.png'),
        require('./images/backgroundImages/Artboard47.png'),
        require('./images/backgroundImages/Artboard48.png'),
        require('./images/backgroundImages/Artboard49.png'),
        
        require('./images/backgroundImages/Artboard63.png'),
        require('./images/backgroundImages/Artboard64.png'),
        require('./images/backgroundImages/Artboard65.png'),
        require('./images/backgroundImages/Artboard66.png'),
        require('./images/backgroundImages/Artboard67.png'),
        require('./images/backgroundImages/Artboard68.png'),
        require('./images/backgroundImages/Artboard69.png'),
        require('./images/backgroundImages/Artboard70.png'),
        require('./images/backgroundImages/Artboard71.png'),
        require('./images/backgroundImages/Artboard72.png'),
        require('./images/backgroundImages/Artboard73.png'),
    ]

export const emptyPage = require('./images/empty_page.png')