// To avoid redux and save time

module.exports = {
    currentScreenTitle: "Dashboard",
    portraitView: true,
    isWebView: false,
    DBPath: { // key=collectionId, value=documentId
        companyId: undefined,
        departmentId: undefined,
        learningSubTopicId: undefined,
        trainingMaterialTopicId: undefined,
        gameId: undefined,
        gameCategoryId: undefined,
        userId: undefined
    },
    userProfile: {},

    isLeaderboardFunctionReady: undefined,
    whereToFindAnswer: "",
    offlineMode: false,
    companyLogoUrl: undefined,
}