import { Text, View, Image, Dimensions, Alert, BackHandler, StyleSheet, TouchableOpacity, ImageBackground } from 'react-native';
import React, { useState, useCallback, useEffect, useRef } from "react"
import { useNavigation, StackActions, NavigationActions } from '@react-navigation/native';
import { Icon } from 'react-native-elements';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';
import { WebView } from 'react-native-webview';

import { getElement, Firebase } from '../firebase.config';
import { GameButton } from '../components/game/GameButton';
import { ThinButton } from '../components/game/ThinButton';
import { CountDownTimer } from '../components/game/CountDownTimer';
import { QuestionAnsweredBanner } from '../components/game/QuestionAnsweredBanner';
import { LeaderboardModal } from '../components/game/LeaderboardModal';
import { LOADING_QUESTION } from '../assets/text/eng';
import { styles, button1Style, button2Style, button3Style, button4Style } from '../styles/Standard/screens/GameScreen.style.js';
import { images, backgroundImages } from '../assets/images';
import { LoadingIndicator } from '../components/common/LoadingIndicator';
//  architechtural decision: gamestate vs separate properties: speed is faster when separate since whole game pbject does not have to get updated due to how setStte hooks work

import { StyledButton } from '../components/common/StyledButton';
import { playSound } from '../utils/playSound';
import { incorrectAnswerSound, answerCorrectSound } from '../assets/sounds'
import { unstable_renderSubtreeIntoContainer } from 'react-dom';
import { checkProfileFields } from '../utils/checkProfileFields'

/**
 * @param {Object{<key:string>}} globalState.DBPath Context information
 */
export function GameScreen(props) {

	// TODO test maximum question length and cut if off at max characters!
	//  "m".repeat(40)

	// game properties:
	const questionsToAskCount = 5; // adapt?
	const questionTime = 30// can later be specific to questions or categories
	const [isInitialised, setIsInitialised] = useState(false);
	const [isLoading, setIsLoading] = useState(true)

	const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
	const [isQuestionActive, setIsQuestionActive] = useState(false);
	const [currentQuestion, setCurrentQuestion] = useState();
	const [questionKeys, setQuestionKeys] = useState();
	const [questionResult, setQuestionResult] = useState("unanswered");
	const [questionsAnsweredCorrect, setQuestionsAnsweredCorrect] = useState({});
	const [gameInfo, setGameInfo] = useState();
	const [userAnswers, setUserAnswers] = useState([]);
	const [score, setScore] = useState(0);
	const [shouldStopTimer, setShouldStopTimer] = useState(false);
	const [shouldStartTimer, setShouldStartTimer] = useState(false);
	const [triggerRefresh, setTriggerRefresh] = useState(false);
	const [playTime, setPlayTime] = useState(0)
	const [correctAnswerCount, setCorrectAnswerCount] = useState(0)

	const [buttonStatus, setButtonStatus] = useState(Array(4).fill("unanswered")); // was 4
	const [showModal, setShowModal] = useState(false);
	const [localMaximumGameScore, setLocalMaximumGameScore] = useState(0)
	const [navigatedToAfterGame, setNavigatedToAfterGame] = useState(false);
	const [imageBackgroundIndex, setImageBackgroundIndex] = useState(Math.floor(Math.random() * (backgroundImages.length - 1)))

	// have composite objects for each game type
	const [matchedTerms, setMatchedTerms] = useState([]) //index on left side is array index, index on right side is value
	const [matchTermState, setMatchTermState] = useState( // reset for new question
		{
			leftTerms: [], //to check if correct answer, check if their indexes match
			rightTerms: [],
			// leftTermsOrder: [],
			// rightTermsOrder:[],
			matchedTerms: { // The order of this is important for correctness, display should randomise this but rememver the randomising //change correctness checking, randomise this
				0: -1,
				1: -1,
				2: -1,
				3: -1,
				4: -1
			},
			selectedLeftTermIndex: -1,
			selectedRIghtTermIndex: -1,
		}
	)


	const [bucketState, setBucketState] = useState( // reset for new question
		{
			buckets: {
				left: [],
				right: []
			},
			// leftTermsOrder: [],
			// rightTermsOrder:[],
			selectedItemIndex: -1,
		}
	)

	const [tapsLeft, setTapsLeft] = useState(undefined)
	const [gridData, setGridData] = useState(undefined)
	const [wasGroupSelected, setWasGroupSelected] = useState(undefined)

	const [wordBuckets, setWordBuckets] = useState([[], []]) //contains index of word
	const [refreshTimer, setRefreshTimer] = useState(false);


	// const [afterGameInfo, setAfterGameInfo] = useState([])

	const navigation = useNavigation();
	const windowWidth = Dimensions.get('window').width
	const windowHeight = Dimensions.get('window').height

	var globalState = require('../assets/GlobalState')
	var gameState = require('../assets/GameState')

	const companyName = globalState.DBPath.companyId
	const departmentId = globalState.DBPath.departmentId
	const gameId = globalState.DBPath.gameId
	const categoryId = globalState.DBPath.gameCategoryId // nb set  in game when coming from learning center 
	const userId = globalState.DBPath.userId


	const dto = {
		gridWidth: 10,
		gridHeight: 11,
		image: {
			width: 720,
			height: 526,
			url: "https://firebasestorage.googleapis.com/v0/b/test-fddd5.appspot.com/o/company_images%2F9830889b-21ff-477e-b136-bf5cfd6d9237?alt=media&token=87230f8c-4d9e-475f-92b7-e7042dd3a09e",
			path: ""
		},
		questionText: "",
		selectables: [
			{
				id: "dnds93hnd",
				clickables: [
					{ x: 1, y: 8 },
					{ x: 1, y: 9 },
				]
			},
			{
				id: "dnds93hnd",
				clickables: [
					{ x: 5, y: 7 },
					{ x: 5, y: 7 },
				]
			},
		]
	};


	function checkInputContext(globalState) {
		const inputs = [globalState.DBPath.companyId, globalState.DBPath.departmentId, globalState.DBPath.gameId, globalState.DBPath.gameCategoryId, globalState.DBPath.userId]
		for (var i in inputs) {
			var currentInput = inputs[i]
			if (currentInput == undefined || currentInput.length < 1) {
				return false
			}
		}
		return true
	}

	function getAndSetLocalLeaderboard() {
		// console.log("getLeaderboard")
		if (globalState.DBPath.companyId == undefined || globalState.DBPath.departmentId == undefined || globalState.DBPath.gameId == undefined) {
			console.log("DBpath field invalid in get leaderboard");
			console.log(path)
			// Alert.alert("Category Document not found.")
			navigation.replace('dashboard')
			return
		}

		var path = ['Companies', globalState.DBPath.companyId, 'Departments', globalState.DBPath.departmentId, 'Leaderboard', globalState.DBPath.gameId]
		getElement(path).get().
			then(function (doc) {
				if (doc.exists) {
					if (doc.data() == undefined || doc.data().users == undefined || Object.keys(doc.data().users).length < 1) {
						console.log("leaderboard empty")
					} else {
						var leaderboardObj = doc.data().users
						var leaderboardKeys = Object.keys(leaderboardObj)
						leaderboardKeys.sort()
						var newLeaderboard = []
						for (var i = 0; i < leaderboardKeys.length; i++) {
							var leaderboardEntry = leaderboardObj[leaderboardKeys[i]]
							newLeaderboard.push(leaderboardEntry)
						}
						gameState.localGameLeaderboard = newLeaderboard
					}
				} else {
					console.log("Leaderboard Document not found");
				}
			}).catch(function (error) {
				console.log("Error getting leaderboard doc", error);
			});
	}


	function onBackPressDisable() {
		//  use globalstate to enable back for some screens later
		console.log("back pressed")
		return true
	}

	//does not currently retry game request because of setInitliased. Might add haveRequested too stop rerequests while waiting for another, after moving setInitialised inside .then
	useEffect(() => {
		if (currentQuestion != undefined && isQuestionActive == false) {
			setIsQuestionActive(true);
		}
	},
		[currentQuestion]
	);

	if (!isInitialised) {
		console.log("initialise")
		getAndSetLocalLeaderboard()
		if (!checkInputContext(globalState)) {
			Alert.alert("Input context varialbe not defined.")
			navigation.replace('dashboard')
			return null
		}

		BackHandler.addEventListener('hardwareBackPress', onBackPressDisable);

		setCurrentQuestionIndex(0);

		if (globalState.DBPath.companyId == undefined || globalState.DBPath.departmentId == undefined || globalState.DBPath.gameId == undefined || globalState.DBPath.gameCategoryId == undefined) {
			console.log("DBpath field invalid");
			console.log(path)
			// Alert.alert("Category Document not found.")
			navigation.replace('dashboard')
		}

		if (gameState.gameInfo.maximumGameScore == undefined || gameState.gameInfo.maximumGameScore == 0) { //retry if bad response?
			var path = ['Companies', globalState.DBPath.companyId, 'Departments', globalState.DBPath.departmentId, 'Games', globalState.DBPath.gameId]
			getElement(path).get().then(function (doc) {
				if (doc.exists) {
					// console.log(doc.data())
					if (doc.data() == undefined || doc.data().maximumCorrect == undefined || doc.data().maximumCorrect < 1) {
						console.log("MaximumScore is invalid.")
					} else {
						gameState.gameInfo.maximumGameScore = doc.data().maximumCorrect
						console.log("set maximumscore " + doc.data().maximumCorrect)
					}
				} else {
					console.log("Category Document not found");
					Alert.alert("Category Document not found.")
					navigation.replace('dashboard')
				}
			}).catch(function (error) {
				console.log("Error getting doc", error);
			});
		}

		console.log("requesting cat")
		var path = ['Companies', globalState.DBPath.companyId, 'Departments', globalState.DBPath.departmentId, 'Games', globalState.DBPath.gameId, 'Categories', globalState.DBPath.gameCategoryId]
		getElement(path).get().then(function (doc) {
			if (doc.exists) {
				if (doc.data().questions == undefined || Object.keys(doc.data().questions).length < 1) {
					setCategoryResults(globalState.DBPath.gameCategoryId, 0)
					nextCategory()
				} else {
					setGameInfo(doc.data())
					//questions are in random order because questionKeys are shuffled and used sequentially
					const newQuestionKeys = shuffleFisherYates(Object.keys(doc.data().questions))
					setQuestionKeys(newQuestionKeys);
					setCurrentQuestion(doc.data().questions[newQuestionKeys[0]])

					const localCurrentQuestion = doc.data().questions[newQuestionKeys[0]]
					//load and setup for questionType, have serparate function?
					if (doc.data().questions[newQuestionKeys[0]].questionType != undefined) {
						if (doc.data().questions[newQuestionKeys[0]].questionType == 0 || doc.data().questions[newQuestionKeys[0]].questionType == 1) {
							setButtonStatus(Array(5).fill("unanswered"))
							setQuestionResult("unanswered")
						}
						if (doc.data().questions[newQuestionKeys[0]].questionType == 2) {
							// check fields here

							// setQuestionResult("grey")
							setButtonStatus(Array(5).fill("grey")) //10?
							var newMatchTermState = matchTermState

							// newMatchTermState.leftTermsOrder = shuffleFisherYates(Array.from(Array(5).keys()))
							// newMatchTermState.rightTermsOrder = shuffleFisherYates(Array.from(Array(5).keys()))

							newMatchTermState.leftTerms = shuffleFisherYates(localCurrentQuestion.terms[0].items)
							newMatchTermState.rightTerms = shuffleFisherYates(localCurrentQuestion.terms[1].items)
							setMatchTermState(newMatchTermState)
							// navigation.replace('dashboard')
						} else if (doc.data().questions[newQuestionKeys[0]].questionType == 3) {
							// check fields her eand in loadQuestion

							// / use loadQuestioin() here?

							// setQuestionResult("grey")
							setButtonStatus(Array(5).fill("grey")) //10?
							var newBucketState = bucketState

							// set titles here?
							// var allItems = 
							// newMatchTermState.leftTermsOrder = shuffleFisherYates(Array.from(Array(5).keys()))
							var shuffledItemIndexes = shuffleFisherYates(Array.from(Array(10).keys())) // support less items?

							// console.log(localCurrentQuestion)
							for (let i = 0; i < 5; i++) {
								if (localCurrentQuestion.items[shuffledItemIndexes[i]] != undefined) {
									newBucketState.buckets.left.push(localCurrentQuestion.items[shuffledItemIndexes[i]])
								}
								if (localCurrentQuestion.items[shuffledItemIndexes[i + 5]] != undefined) {
									newBucketState.buckets.right.push(localCurrentQuestion.items[shuffledItemIndexes[i + 5]])
								}
							}

							setBucketState(newBucketState)
						} else if (doc.data().questions[newQuestionKeys[0]].questionType == 4) {
							if (doc.data().questions[newQuestionKeys[0]].selectables == undefined) {
								console.log("selectables not defined. should load next question")
								navigation.replace('dashboard')
							}
							loadNextQuestion(doc.data().questions[newQuestionKeys[0]])
							// setWasGroupSelected(Array(doc.data().questions[newQuestionKeys[0]].selectables.length).fill(false)) // only in initialised phase
						} else {
							console.log("questionType undefined")
						}

						increaseMaximumScore(doc.data().questions[newQuestionKeys[0]])

					} else {
						Alert.alert("Question type is undefined.")
						navigation.replace('dashboard')
					}

					gameState.live = 0
					setIsLoading(false)
					setShouldStartTimer(true)
					gameState.shouldStartTimer = true
					// refreshTimer = !refreshTimer
					setRefreshTimer(!refreshTimer)
					console.log("G start timer " + gameState.shouldStartTimer)


				}
			} else {
				console.log("Category Document not found");
				Alert.alert("Category Document not found.")
				navigation.replace('dashboard')
			}
		}).catch(function (error) {
			console.log("Error getting category doc", error);
		});

		//do based on  questionType
		setQuestionResult("unanswered")
		// if(currentQuestion.questionType)
		setButtonStatus(Array(10).fill("grey"))
		setIsInitialised(true);
	}

	// ideal is having an interchangeable object per game type
	// answer is an index for multhichoice questions 
	function setAnswerResult(answer) {

		function setMultiChoiceResult(answer) {
			var newButtonStatus = Array(4).fill("multi-unselected")
			newButtonStatus[currentQuestion.correctAnswerIndex] = "true"
			var newQuestionsAnsweredCorrect = questionsAnsweredCorrect

			var isCorrect = (answer === currentQuestion.correctAnswerIndex) ? 1 : 0

			if (answer === currentQuestion.correctAnswerIndex) {
				console.log("inc score" + "newscore " + (score + Number(1)))
				setScore(score + 1)
				gameState.live = gameState.live + 1
				playSound(answerCorrectSound)
			} else {
				newButtonStatus[answer] = "false"
				playSound(incorrectAnswerSound)
			}
			setQuestionResult((isCorrect == 1) ? "correct" : "incorrect")
			newQuestionsAnsweredCorrect[(questionKeys[currentQuestionIndex])] = isCorrect
			setQuestionsAnsweredCorrect(newQuestionsAnsweredCorrect)

			// check length too
			const whereToFindAnswer = {
				learningTopicId: (currentQuestion.learningTopicId != undefined) ? currentQuestion.learningTopicId : undefined,
				learningSubTopicId: (currentQuestion.learningSubTopicId != undefined) ? currentQuestion.learningSubTopicId : undefined,
				whereToFind: (currentQuestion.whereToFind != undefined) ? currentQuestion.whereToFind : ""
			}

			// note test answertext
			var answerText = ""
			if (answer > 3 || answer < -1) {
				answerText = "unanswered"
			} else {
				answerText = currentQuestion['answers'][answer]
			}

			var newAfterGameInfo = gameState.state.afterGameInfo
			newAfterGameInfo.push({
				questionText: currentQuestion.questionText,
				answerText: answerText,
				isCorrect: isCorrect,
				whereToFindAnswer: whereToFindAnswer
			})
			gameState.state.afterGameInfo = newAfterGameInfo
			// console.log(newAfterGameInfo)

			setButtonStatus(newButtonStatus)

			const newUserAnswers = [...userAnswers, answer]
			setUserAnswers(newUserAnswers); // add questionType?
		}

		function setMatchTheTermResult(answer) {
			// This is to show correct answer: may need to do this in next instead
			var newButtonStatus = Array(10).fill("multi-unselected")

			// newButtonStatus[currentQuestion.correctAnswerIndex] = "true"
			var newQuestionsAnsweredCorrect = questionsAnsweredCorrect


			var isCorrect = 0 // make correct if >3 correct? or show # correct
			var questionScore = 0
			var answerText = "\n"
			// calculate correct score
			for (var i = 0; i < matchTermState.leftTerms.length; i++) {
				console.log("matchedTerms[i] " + matchTermState.matchedTerms[i])
				console.log("LeftTerms.index " + matchTermState.leftTerms[i].index)
				if (matchTermState.leftTerms[i].index == matchTermState.rightTerms[matchTermState.matchedTerms[i]].index) { //ensure not affected by shuffling - that matchindex and lefterms are synced // -5?
					questionScore += 1
				}
				if (matchTermState.leftTerms[i] != undefined && matchTermState.rightTerms[matchTermState.matchedTerms[i]] != undefined) {
					answerText += matchTermState.leftTerms[i].name + " - " + matchTermState.rightTerms[matchTermState.matchedTerms[i]].name + "\n" // find way to get text // display mapping?//same order as display mapping ? // order items?
				} else {
					console.log("Property is undefined in matchedTerm aftergame anwers set")
					console.log(matchTermState.leftTerms[i])
					console.log(matchTermState.rightTerms[matchTermState.matchedTerms[i]])
				}
			}
			console.log("matchQuestionScore  = " + questionScore)
			setScore(score + questionScore) // add totalpoints too
			gameState.live = gameState.live + questionScore

			if (questionScore >= matchTermState.leftTerms.length) {
				setQuestionResult("correct")
				playSound(answerCorrectSound)
				isCorrect = 1
				// newButtonStatus[answer] = "true"
			} else {
				// newButtonStatus[answer] = "false"
				setQuestionResult("incorrect")
				playSound(incorrectAnswerSound)
				isCorrect = 0
			}

			newQuestionsAnsweredCorrect[(questionKeys[currentQuestionIndex])] = isCorrect
			setQuestionsAnsweredCorrect(newQuestionsAnsweredCorrect)

			// check length too
			const whereToFindAnswer = {
				learningTopicId: (currentQuestion.learningTopicId != undefined) ? currentQuestion.learningTopicId : undefined,
				learningSubTopicId: (currentQuestion.learningSubTopicId != undefined) ? currentQuestion.learningSubTopicId : undefined,
				whereToFind: (currentQuestion.whereToFind != undefined) ? currentQuestion.whereToFind : ""
			}




			var newAfterGameInfo = gameState.state.afterGameInfo
			newAfterGameInfo.push({
				questionText: currentQuestion.questionText,
				answerText: answerText,
				matchedTerms: matchTermState.matchedTerms,
				questionType: currentQuestion.questionType, // add to other info as well
				questionScore: questionScore,
				isCorrect: isCorrect,
				whereToFindAnswer: whereToFindAnswer
			})
			gameState.state.afterGameInfo = newAfterGameInfo
			// console.log(newAfterGameInfo)

			// setButtonStatus(newButtonStatus) //what does this do? show correct?

			const newUserAnswers = [...userAnswers, matchTermState.matchedTerms]
			setUserAnswers(newUserAnswers); // add questionType?
		}

		function setWordBucketResult() {
			var newQuestionsAnsweredCorrect = questionsAnsweredCorrect

			var isCorrect = 0 // make correct if >3 correct? or show # correct
			var questionScore = 0
			var answerText = "\n"

			answerText += currentQuestion.buckets[0].name + ":\n"
			for (let i = 0; i < bucketState.buckets.left.length; i++) {
				answerText += "\t" + bucketState.buckets.left[i].name + "\n"
				if (bucketState.buckets.left[i].matchIndex == 0) {
					questionScore += 1
				}
			}

			answerText += "\n" + currentQuestion.buckets[1].name + ":\n"
			for (let i = 0; i < bucketState.buckets.right.length; i++) {
				answerText += "\t" + bucketState.buckets.right[i].name + "\n"
				if (bucketState.buckets.right[i].matchIndex == 1) {
					questionScore += 1
				}
			}

			questionScore = questionScore / 2
			setScore(score + questionScore) // add totalpoints too
			gameState.live = gameState.live + questionScore
			console.log("bucketScore = " + questionScore)

			if (questionScore >= (bucketState.buckets.left.length + bucketState.buckets.right.length)/4) {
				setQuestionResult("correct")
				playSound(answerCorrectSound)
				isCorrect = 1
				// newButtonStatus[answer] = "true"
			} else {
				// newButtonStatus[answer] = "false"
				// number of incorrect?
				// highlight incorrect items
				setQuestionResult("incorrect")
				playSound(incorrectAnswerSound)
				isCorrect = 0
			}

			newQuestionsAnsweredCorrect[(questionKeys[currentQuestionIndex])] = isCorrect
			setQuestionsAnsweredCorrect(newQuestionsAnsweredCorrect)

			// check length too
			const whereToFindAnswer = {
				learningTopicId: (currentQuestion.learningTopicId != undefined) ? currentQuestion.learningTopicId : undefined,
				learningSubTopicId: (currentQuestion.learningSubTopicId != undefined) ? currentQuestion.learningSubTopicId : undefined,
				whereToFind: (currentQuestion.whereToFind != undefined) ? currentQuestion.whereToFind : ""
			}


			var newAfterGameInfo = gameState.state.afterGameInfo
			newAfterGameInfo.push({
				questionText: currentQuestion.questionText,
				answerText: answerText,
				// matchedTerms: matchTermState.matchedTerms,
				questionType: currentQuestion.questionType, // add to other info as well
				questionScore: questionScore,
				isCorrect: isCorrect,
				whereToFindAnswer: whereToFindAnswer
			})
			gameState.state.afterGameInfo = newAfterGameInfo
			// console.log(newAfterGameInfo)

			// setButtonStatus(newButtonStatus) //what does this do? show correct?

			// const newUserAnswers = [...userAnswers, matchTermState.matchedTerms]
			// setUserAnswers(newUserAnswers); // add questionType?
			console.log("finished upating changes")
		}

		function setImageAreaSelectAnswer(answer) {
			var newQuestionsAnsweredCorrect = questionsAnsweredCorrect

			var isCorrect = 0
			var questionScore = 0
			var answerText = "\n"

			for (let i = 0; i < answer.length; i++) {
				if (answer[i] == true) {
					questionScore += 1
				}
			}

			answerText += "Correct areas tapped: " + questionScore
				if(currentQuestion.selectables.length > 0) {
				answerText +=" / " + currentQuestion.selectables.length
			}

			setScore(score + questionScore) // add totalpoints too
			gameState.live = gameState.live + questionScore
			console.log("imgeAreaScore = " + questionScore)

			if (questionScore >= answer.length / 2) {
				setQuestionResult("correct")
				playSound(answerCorrectSound)
				isCorrect = 1
			} else {
				setQuestionResult("incorrect")
				playSound(incorrectAnswerSound)
				isCorrect = 0
			}

			newQuestionsAnsweredCorrect[(questionKeys[currentQuestionIndex])] = isCorrect
			setQuestionsAnsweredCorrect(newQuestionsAnsweredCorrect)

			// check length too
			const whereToFindAnswer = {
				learningTopicId: (currentQuestion.learningTopicId != undefined) ? currentQuestion.learningTopicId : undefined,
				learningSubTopicId: (currentQuestion.learningSubTopicId != undefined) ? currentQuestion.learningSubTopicId : undefined,
				whereToFind: (currentQuestion.whereToFind != undefined) ? currentQuestion.whereToFind : ""
			}


			var newAfterGameInfo = gameState.state.afterGameInfo
			newAfterGameInfo.push({
				questionText: currentQuestion.questionText,
				answerText: answerText,
				// matchedTerms: matchTermState.matchedTerms,
				questionType: currentQuestion.questionType, // add to other info as well
				questionScore: questionScore,
				isCorrect: isCorrect,
				whereToFindAnswer: whereToFindAnswer
			})
			gameState.state.afterGameInfo = newAfterGameInfo

			// const newUserAnswers = [...userAnswers, answer]
			// setUserAnswers(newUserAnswers); // add questionType?

			console.log("finished upating changes")
		}


		if (currentQuestion == undefined || currentQuestion.questionType == undefined) {
			Alert.alert("The current question or question type is undefined")
			navigation.replace('dashboard') // test
		}

		const questionType = currentQuestion.questionType
		if (questionType == 0 || questionType == 1) {
			setMultiChoiceResult(answer)
		} else if (questionType == 2) {
			setMatchTheTermResult(answer)
		} else if (questionType == 3) {
			setWordBucketResult()
		} else if (questionType == 4) {
			setImageAreaSelectAnswer(answer)
		}


	}

	function selectMatchedTerm(answer) {
		var newMatchTermState = matchTermState
		if (newMatchTermState.selectedLeftTermIndex < 0) {
			if (answer >= 0 && answer < 5) {
				newMatchTermState.selectedLeftTermIndex = answer // in compositbe onject
				// set button colour
			}

		} else {
			if (answer >= 5 && answer < 10) {
				newMatchTermState.matchedTerms[newMatchTermState.selectedLeftTermIndex] = answer - 5
				newMatchTermState.selectedLeftTermIndex = -1
				// selectedRIghtTermIndex = answer
				// set button colour
			} else {
				newMatchTermState.matchedTerms[newMatchTermState.selectedLeftTermIndex] = -1
			}
		}
		var areAllMatched = true
		// newMatchTermState.matchedTerms.forEach(e => {
		// 	// if (e < 0) {
		// 	// 	areAllMatched = false
		// 	// }
		// })

		for (var i = 0; i < newMatchTermState.leftTerms.length; i++) {
			if (newMatchTermState.matchedTerms[i] < 0) {
				areAllMatched = false
			}
		}
		if (areAllMatched) {
			console.log("all matched")
			// submit answer
			setAnswerResult(answer)
			setTimeout(next, 1500)
		}
		setMatchTermState(newMatchTermState)
		setTriggerRefresh(!triggerRefresh)
	}

	function selectBucketItem(answerIndex) {
		// console.log(matchTermState)
		// use leftTerms.length instead of magic values
		console.log("selected")
		var newBucketState = bucketState

		newBucketState.selectedItemIndex = answerIndex
		setBucketState(newBucketState)

		// for (var i = 0; i < newMatchTermState.leftTerms.length; i++) {
		// 	if (newMatchTermState.matchedTerms[i] < 0) {
		// 		areAllMatched = false
		// 	}
		// }
		// if (areAllMatched) {
		// 	console.log("all matched")
		// 	// submit answer
		// setAnswerResult(answer)
		// setTimeout(next, 1500)
		// }
		// setMatchTermState(newMatchTermState)
		setTriggerRefresh(!triggerRefresh)
	}

	function moveBucketItem(bucketIndex) {
		var newBucketState = bucketState

		//convert button id to idd of item in bucket
		const selectedItemBucketIndex = newBucketState.selectedItemIndex < 10 ? 0 : 1
		const indexInBucket = newBucketState.selectedItemIndex < 10 ? newBucketState.selectedItemIndex : newBucketState.selectedItemIndex - 10  // no magic numbers nb

		// get actual index of the item that is selected?
		// const selectedItemIndex = 

		//if term unselected
		if (newBucketState.selectedItemIndex == -1 || selectedItemBucketIndex == bucketIndex) {
			console.log("no term selected or term already part of bucket")
			newBucketState.selectedItemIndex = -1
			setBucketState(newBucketState)
			setTriggerRefresh(!triggerRefresh)
			// trigger refresh of the movebucketHanddlers
			return
		}

		//do not move if already in same bucket - then deselect

		//find item to move
		console.log(indexInBucket)
		// console.log(butcketIndex)

		if (selectedItemBucketIndex == 0) {
			console.log("different bucket")
			newBucketState.buckets.right.push(newBucketState.buckets.left[indexInBucket]) // deep copy?
			newBucketState.buckets.left.splice(indexInBucket, 1)
		} else {
			newBucketState.buckets.left.push(newBucketState.buckets.right[indexInBucket]) // deep copy?
			newBucketState.buckets.right.splice(indexInBucket, 1)
		}

		newBucketState.selectedItemIndex = -1
		setBucketState(newBucketState)
		setTriggerRefresh(!triggerRefresh)
		return

		// for (let i = 0; i < newBucketState.buckets.left.length; i++) {
		// 	if (newBucketState.buckets.left[i].matchIndex == bucketState.selectedItemIndex) {
		// 		if (bucketIndex == 0) {
		// 			console.log("same bucket")
		// 			newBucketState.selectedItemIndex = -1
		// 			setBucketState(newBucketState)
		// 			setTriggerRefresh(!triggerRefresh)
		// 			return
		// 		} else {
		// 			console.log("different bucket")
		// 			newBucketState.buckets.right.push(newBucketState.buckets.left[i]) // deep copy?
		// 			// remove from here and move to other bucket
		// 			newBucketState.buckets.left.splice(i, 1)
		// 			newBucketState.selectedItemIndex = -1
		// 			setBucketState(newBucketState)
		// 			setTriggerRefresh(!triggerRefresh)
		// 			return
		// 		}
		// 		// first move to other bucket
		// 	}
		// }


		// for (let i = 0; i < newBucketState.buckets.right.length; i++) {
		// 	if (newBucketState.buckets.right[i].matchIndex == bucketState.selectedItemIndex) {
		// 		if (bucketIndex == 1) {
		// 			console.log("same bucket")
		// 			newBucketState.selectedItemIndex = -1
		// 			setBucketState(newBucketState)
		// 			setTriggerRefresh(!triggerRefresh)
		// 			return
		// 		} else {
		// 			console.log("different bucket")
		// 			// first move to other bucket
		// 			newBucketState.buckets.left.push(newBucketState.buckets.right[i]) // deep copy?
		// 			// remove from here and move to other bucket
		// 			newBucketState.buckets.right.splice(i, 1)
		// 			newBucketState.selectedItemIndex = -1
		// 			setBucketState(newBucketState)
		// 			setTriggerRefresh(!triggerRefresh)
		// 			return
		// 		}
		// 	}
		// }

		// console.log("did not find matching")
	}

	function submitBucketAnswer() {
		setAnswerResult(1)
		setIsQuestionActive(false)
		setTimeout(next, 1000) // maybe rather in setAnswerResult
	}

	function submitImageSelectAnswer(categoriesClicked) {
		console.log("before next")
		setAnswerResult(categoriesClicked)
		setIsQuestionActive(false)
		// show incorrect areas
		setTimeout(next, 1000) // maybe rather in setAnswerResult
	}

	// use questionType from scope
	// answer is an answer index for multichoice questions 
	function selectAnswer(answer) {

		setShouldStopTimer(true) // should use the gameState one!
		if (isQuestionActive == true && currentQuestion != undefined && currentQuestionIndex < questionsToAskCount) {

			if (currentQuestion.questionType == 0 || currentQuestion.questionType == 1) {
				setIsQuestionActive(false)
				// const selectedAnswerText = (answerIndex >= 0) ? currentQuestion.answers[Object.keys(currentQuestion.answers)[answerIndex]] : ""
				setAnswerResult(answer)
				setTimeout(next, 1000)
			} else if (currentQuestion.questionType == 2) { // handle matching both ways? // put in separate function /// limit matching to 2? // deselect when reselected?
				selectMatchedTerm(answer)
			} else if (currentQuestion.questionType == 3) {

			} else if (currentQuestion.questionType == 4) {
				submitImageSelectAnswer(answer)
			}
		}
	}

	// have separateloadquestions per type
	function loadNextQuestion(question) {
		if (question.questionType == 2) {
			console.log("loading type 2")
			var newMatchTermState = {
				leftTerms: [],
				rightTerms: [],
				// leftTermsOrder: [],
				// rightTermsOrder:[],
				matchedTerms: { // The order of this is important for correctness, display should randomise this but rememver the randomising //change correctness checking, randomise this
					0: -1,
					1: -1,
					2: -1,
					3: -1,
					4: -1
				},
				selectedLeftTermIndex: -1,
				selectedRIghtTermIndex: -1,
			}

			// newMatchTermState.leftTermsOrder = shuffleFisherYates(Array.from(Array(5).keys()))
			// newMatchTermState.rightTermsOrder = shuffleFisherYates(Array.from(Array(5).keys()))

			newMatchTermState.leftTerms = shuffleFisherYates(question.terms[0].items) // may need to disable shuffling of left column with current setup
			newMatchTermState.rightTerms = shuffleFisherYates(question.terms[1].items)
			setMatchTermState(newMatchTermState)

		} else if (question.questionType == 3) {
			setQuestionResult("grey")
			setButtonStatus(Array(5).fill("grey")) //10?
			var newBucketState = bucketState

			// set titles here?
			// var allItems = 
			// newMatchTermState.leftTermsOrder = shuffleFisherYates(Array.from(Array(5).keys()))
			var shuffledItemIndexes = shuffleFisherYates(Array.from(Array(10).keys())) // support less items?
			newBucketState.buckets.left = []
			newBucketState.buckets.right = []

			// console.log(localCurrentQuestion)
			for (let i = 0; i < 5; i++) {
				if (question.items[shuffledItemIndexes[i]] != undefined) {
					newBucketState.buckets.left.push(question.items[shuffledItemIndexes[i]])
				}
				if (question.items[shuffledItemIndexes[i + 5]] != undefined) {
					newBucketState.buckets.right.push(question.items[shuffledItemIndexes[i + 5]])
				}
			}

			setBucketState(newBucketState)
		} else if (question.questionType == 4) {
			var gridData = createGrid(question.gridWidth, question.gridHeight)
			gridData = addToGrid(gridData, question)
			setTapsLeft(question.selectables.length)
			setWasGroupSelected(Array(question.selectables.length).fill(false))
		}
	}

// add maximumscore increase here
	
	function increaseMaximumScore(question) {
		var questionMaximumScore = 1
		if (question.questionType == 0 || question.questionType == 1) {
			questionMaximumScore = 1
		} else if (question.questionType == 2) { // handle matching both ways? // put in separate function /// limit matching to 2? // deselect when reselected?
			questionMaximumScore = question.terms[0].items.length
		} else if (question.questionType == 3) {
			questionMaximumScore = question.items.length / 2
		} else if (question.questionType == 4) {
			questionMaximumScore = question.selectables.length
		}
		console.log("max score for question = " + questionMaximumScore)
		gameState.maximumGameScore = gameState.maximumGameScore + questionMaximumScore 
	}
	
	function next() {
		console.log("next")
		const newQuestionIndex = currentQuestionIndex + 1;
		setCurrentQuestionIndex(newQuestionIndex);

		if (newQuestionIndex < questionsToAskCount && questionKeys != undefined && newQuestionIndex < questionKeys.length) {
			// gameState.gameInfo.maximumScore += 1
			setCurrentQuestion(gameInfo.questions[questionKeys[newQuestionIndex]]);
			loadNextQuestion(gameInfo.questions[questionKeys[newQuestionIndex]])
			increaseMaximumScore(gameInfo.questions[questionKeys[newQuestionIndex]])
			//reset
			setButtonStatus(Array(4).fill("unanswered"))
			setQuestionResult("unanswered")
			setImageBackgroundIndex(Math.floor(Math.random() * (backgroundImages.length - 1)))
			setIsQuestionActive(true)
			setShouldStartTimer(true)
			gameState.shouldStartTimer = true
			// refreshTimer = !refreshTimer
			setRefreshTimer(!refreshTimer)
			// console.log("g start timer" + gameState.shouldStartTimer)
		} else {
			// console.log("end")
			setShouldStopTimer()

			// in an extreme case, the last userAnswer may not be updated yet due to the async state update
			// if (isQuestionActive) {
			// console.log(questionsAnsweredCorrect)

			console.log("\nGameState.live = " + gameState.live)
			console.log("\nscore = " + score)

			var correctCount = 0
			for (let key in questionsAnsweredCorrect) {
				if (questionsAnsweredCorrect[key] == 1) {
					correctCount++
				}
			}
			// setCorrectAnswerCount(correctCount)

			console.log(correctCount)

			setCategoryResults(categoryId, gameState.live)
			nextCategory(correctCount)

			// if ( userAnswers != undefined && userAnswers[0] != undefined) {


			setIsQuestionActive(false);

			// Showdone modal with stars, score, time etc. 
			// play sound
			// setIsInitialised(false)

			//only navigate after uploads have been successful?
			// }
			setIsQuestionActive(false)
		}
	}

	function setCategoryResults(currentCategoryID, currentCategoryScore) {
		gameState.state.categoryScores[currentCategoryID] = currentCategoryScore
		var remainingCategories = (gameState.state.uncompletedCategories).filter(function (e) { return e !== currentCategoryID })
		gameState.state.uncompletedCategories = remainingCategories  // remove the id one
		gameState.live = 0
	}

	/**
	 * 
	 * @param correctCount used in end game calculations 
	 */
	function nextCategory(correctCount) {
		const remainingCategoriesCount = gameState.state.uncompletedCategories.length

		if (remainingCategoriesCount > 0) {
			const nextCategoryIndex = Math.floor(Math.random() * Math.floor(remainingCategoriesCount)); /////////////test
			const nextCategory = gameState.state.uncompletedCategories[nextCategoryIndex]

			console.log("remaining cat" + remainingCategoriesCount + ". next cat")
			globalState.DBPath.gameCategoryId = nextCategory
			navigation.replace('game')
		} else {
			console.log("remaining cat count <= 0. Executing endgame flow")
			if (userAnswers != undefined) { //otherise wont's navigate!!!
				uploadAnswers()
				getAndSetGameInfo(correctCount)
			} else {
				console.log("UsersAnswers undefined!!!")
			}
			// if (navigatedToAfterGame == false) {
			// 	// console.log("nav cat")
			// 	// navigation.replace('after_game')
			// 	// setNavigatedToAfterGame(true)
			// }
			//  would rather have this if ouside of nextCategory
		}
	}

	// dependency issue: User, Department,Leaderboard info required , maybe rather make a getInfo function and then call other functions after

	function getAndSetGameInfo(correctCount) {
		var path = ['Companies', companyName, 'Users', userId]
		getElement(path).get().then(function (doc) {
			if (doc.exists) {
				const profileData = doc.data()

				var path = ['Companies', companyName, 'Departments', departmentId]
				getElement(path).get().then(function (doc2) {
					if (doc2.exists) {

						var path = ['Companies', companyName, 'Departments', departmentId, 'Leaderboard', 'Leaderboard'] // do not have to get, not necessary anymore
						getElement(path).get().then(function (doc3) {
							// if (doc3.exists) {
							gameState = require('../assets/GameState')
							// console.log(gameState.maximumScore)
							// const maximumGameScore = If( gameState != undefined && gameState.gameInfo != undefined && gameState.maximumScore ) gameState.maximumScore // (doc2.data().maximumGameScore != undefined)?  doc2.data().maximumGameScore : gameState.maximumScore
							// var maximumGameScore = (gameState != undefined && gameState.gameInfo != undefined && gameState.gameInfo.maximumGameScore != undefined && gameState.gameInfo.maximumGameScore > 0) ? gameState.gameInfo.maximumGameScore : localMaximumGameScore
							var maximumGameScore = gameState.gameInfo.maximumGameScore
							// if (gameState != undefined && gameState.gameInfo != undefined && gameState.gameInfo.maximumGameScore != undefined && gameState.gameInfo.maximumGameScore > 0) {
							// const maximumGameScore = gameState.gameInfo.maximumGameScore // (doc2.data().maximumGameScore != undefined)?  doc2.data().maximumGameScore : gameState.gameInfo.maximumGameScore
							// console.log(gameState.gameInfo)
							console.log("maximumGameScore=" + maximumGameScore)
							// console.log("local maximumGameScore=" + localMaximumGameScore)

							var leaderboard = doc3 != undefined ? doc3.data() : undefined
							updateGameInfo(profileData, maximumGameScore, leaderboard, correctCount)

							// } else {
							// 	console.log("Important: MaximumScore is not set")
							// }

							// } else {
							// 	console.log("Document not found");
							// 	navigation.replace('after_game')//, { passedData: { afterGameInfo: afterGameInfo, score: score } })
							// }
						}).catch(function (error) {
							console.log("Error getting leaderboard  document", error);
							navigation.replace('after_game')//, { passedData: { afterGameInfo: afterGameInfo, score: score } })
						});
					} else {
						console.log("Document not found");
						navigation.replace('after_game')// { passedData: { afterGameInfo: afterGameInfo, score: score } })
					}
				}).catch(function (error) {
					console.log("Error getting departement document", error);
					navigation.replace('after_game')// { passedData: { afterGameInfo: afterGameInfo, score: score } })
				});
			} else {
				console.log("Document not found");
				navigation.replace('after_game')// { passedData: { afterGameInfo: afterGameInfo, score: score } })
			}
		}).catch(function (error) {
			console.log("Error getting user docoment", error);
			navigation.replace('after_game')// { passedData: { afterGameInfo: afterGameInfo, score: score } })
		});
	}

	//ensure accurate score is used!
	function updateGameInfo(profileData, maximumScore, leaderboard, categoryScore) {
		// console.log("update so current  score is used (using gameState)")
		// move profile changes to cloud function
		var newProfileData = profileData // maybe deep copy
		if (newProfileData.gamesCompleted != undefined) {
			// test to see if works when keys not created yet and need to be created sequentially
			newProfileData.gamesCompleted[departmentId][gameId][categoryId] = categoryScore
		} else {
			newProfileData.gamesCompleted = {}
			if (newProfileData.gamesCompleted[departmentId] == undefined) {
				newProfileData.gamesCompleted[departmentId] = {}
			}
			if (newProfileData.gamesCompleted[departmentId][gameId] == undefined) {
				newProfileData.gamesCompleted[departmentId][gameId] = {}
			}
			// if (newProfileData.gamesCompleted[departmentId][gameId][categoryId] == undefined) {
			newProfileData.gamesCompleted[departmentId][gameId][categoryId] = categoryScore
			// }
			// rather just set fields
			globalState.userProfile = newProfileData
		}


		const departmentScorePercentage = calculateDepartmentScore(newProfileData.gamesCompleted[departmentId], maximumGameScore) * 100

		const gameScorePercentage = calculateDepartmentScore(newProfileData.gamesCompleted[departmentId], maximumGameScore) * 100

		var maximumGameScore = (gameState != undefined && gameState.gameInfo != undefined && gameState.gameInfo.maximumGameScore != undefined && gameState.gameInfo.maximumGameScore > 0) ? gameState.gameInfo.maximumGameScore : localMaximumGameScore
		maximumGameScore = maximumGameScore > 0 ? maximumGameScore : 0


		updateLeaderboard(newProfileData, departmentScorePercentage, leaderboard) // new leaderboard position could trigger achievement

		// Add gamestate requirement here?


		// if (globalState.isLeaderboardFunctionReady == true) {
		var uploadToLeaderboard = Firebase.functions().httpsCallable('uploadToLeaderboard');
		gameState.isLeaderboardUpdated = false

		console.log("leaderboard scores")
		console.log(gameState.state.categoryScores)
		var gameScore = calculateGameAnswersCorrect(gameState.state.categoryScores)
		// Alert.alert(gameScore)
		console.log("leaderboard gameScore" + gameScore)
		// uploadToLeaderboard({ dbContext: globalState.DBPath, profile: newProfileData, score: (!isNaN(gameState.live) && gameState.live > 0) ? gameState.live : 0, isGameLeaderboard: true })
		uploadToLeaderboard({ dbContext: globalState.DBPath, profile: newProfileData, score: (!isNaN(gameScore) && gameScore > 0) ? gameScore : 0, isGameLeaderboard: true, maximumGameScore: gameState.maximumGameScore })
			.then(function (response) {
				// console.log()
				gameState.isLeaderboardUpdated = true
			})
			.catch(error => {
				gameState.isLeaderboardUpdated = true
				console.log("uploadLeaderboard, ", error)
			})
			.catch(error => {
				gameState.isLeaderboardUpdated = true
				console.log("uploadLeaderboard, ", error)
			})
		// } else {
		// 	// console.log("updating leaderboard")
		// }

		updateProfile(newProfileData, departmentId, gameId)

		console.log("updateAchievements")
		var updateAchievements = Firebase.functions().httpsCallable('updateAchievements');
		updateAchievements({ dbContext: globalState.DBPath })
			.then(function (response) {
				// console.log()
			})
			.catch(error => {
				gameState.isLeaderboardUpdated = true
				console.log("uploadLeaderboard, ", error)
			})
	}

	// uploadAnswers is 
	//  only execute if local
	function uploadAnswers() {
		// console.log(userAnswers)
		// console.log("userAnswers")
		// console.log(userAnswers)
		const currentDate = new Date()
		const answerDocName = currentDate.getFullYear() + '_' + currentDate.getMonth() + '_' + currentDate.getDate() + '_' + currentDate.getHours() + '_' + currentDate.getMinutes() + '_' + currentDate.getSeconds()
		var path = ['Companies', globalState.DBPath.companyId, 'Users', globalState.DBPath.userId, 'Answers', answerDocName]
		// console.log(path)
		// console.log({
		// 	answerID: 1,
		// 	departmentId: departmentId,
		// 	gameId: gameId,
		// 	categoryId: categoryId,
		// 	date: currentDate,
		// 	answers: userAnswers,
		// 	answersCorrect: questionsAnsweredCorrect,
		// 	score: score
		// })
		getElement(path).set({
			answerID: 1,
			departmentId: departmentId,
			gameId: gameId,
			categoryId: categoryId,
			date: currentDate,
			answers: userAnswers,
			answersCorrect: questionsAnsweredCorrect,
			score: score
		})
			.catch(e => {
				console.log("uploadAnswerIssue", e)
			})
	}

	// only execute if local and offline
	function updateLeaderboard(profileData) {

		var gameState = require('../assets/GameState')
		// console.log(gameState.state.categoryScores)
		var thisGameScore = calculateGameAnswersCorrect(gameState.state.categoryScores)
		// console.log("game answers correct = " + thisGameScore)

		console.log("UL maximumGameScroe " +gameState.maximumGameScore )
		var maximumScore = gameState.maximumGameScore
		if (gameState.maximumGameScore == undefined || gameState.maximumGameScore == 0) {
			maximumScore = thisGameScore * 2
		}

		if (isNaN(thisGameScore) || thisGameScore < 1) {
			thisGameScore = 0
		}

		var thisGameScore = (thisGameScore / maximumScore) * 100

		var newEntry
		if (profileData.userInfo.imageUrl != undefined && profileData.userInfo.imageUrl.length > 0) {
			newEntry = {
				userId: userId,
				name: profileData.userInfo.firstName + ' ' + profileData.userInfo.lastName,
				imageUrl: profileData.userInfo.imageUrl, //NB update here
				score: Math.round(thisGameScore)
			}
		} else {
			newEntry = {
				userId: userId,
				name: profileData.userInfo.firstName + ' ' + profileData.userInfo.lastName,
				score: Math.round(thisGameScore)
			}
		}


		var leaderboard = gameState.localGameLeaderboard //maybe need deep copy
		console.log("leaderboard")
		// calculate new leaderboard
		// var newLeaderboard
		if (leaderboard == undefined || leaderboard.length < 1) {
			leaderboard = [newEntry]
			gameState.localGameLeaderboard = leaderboard
			console.log("leaderboard empty")
		} else {
			// leaderboard = leaderboard.users

			console.log("here1")
			// find existing user entry and score, and remove
			var userPositionInLeaderboard = 0
			var isUserInLeaderboard = false
			for (let position = 0; position < leaderboard.length; position++) {
				if (leaderboard[position].userId == userId) {
					isUserInLeaderboard = true
					// the following is to keep user's previous good score instead of new score
					// if (Number(leaderboardArray[position].score) >= thisGameScore) {
					// console.log("better score found")
					// thisGameScore = leaderboardArray[position].score
					// newEntry.score = thisGameScore
					// }
					userPositionInLeaderboard = position
					break;
				}
			}

			console.log("here2")
			if (isUserInLeaderboard) {
				leaderboard.splice(userPositionInLeaderboard, 1)
			}

			//find new position
			var positionToInsert = 0
			for (let position = 0; position < leaderboard.length; position++) {
				// if (leaderboardArray[position].userId == userId) {
				if (thisGameScore < leaderboard[position].score) {
					positionToInsert = position + 1
				}
			}
			leaderboard.splice(positionToInsert, 0, newEntry)

			console.log("final leaderboard")
			gameState.localGameLeaderboard = leaderboard
			// console.log(leaderboardArray)
		}

		// console.log("New leaderboard")
		// console.log(newLeaderboard)
	}

	//gamestats and achievements
	function updateProfile(profileData, departmentId, currentGameID) {

		if (gameState != undefined && gameState.state.categoryScores != undefined && Object.keys(gameState.state.categoryScores).length > 0) {
			var totalGameAnswersCorrect = calculateGameAnswersCorrect(gameState.state.categoryScores)
			console.log("total correct: " + totalGameAnswersCorrect)

			const completionRequirement = (gameInfo.completionThresholdPercent != undefined) ? gameInfo.completionThresholdPercent : 50
			var isGameCorrect = false

			// if (totalGameAnswersCorrect /  (Object.keys(gameState.state.categoryScores).length * 5) * 100  > gameInfo.completionThresholdPercent) {
			if ((totalGameAnswersCorrect / gameState.gameInfo.maximumGameScore) * 100 > gameInfo.completionThresholdPercent) {
				// game is won/completed
				isGameCorrect = true
			}

			var additionalStats = {
				answersCorrectCount: totalGameAnswersCorrect,
				possibleAnswersCorrectCount: Object.keys(gameState.state.categoryScores).length,
				isGameCorrect: isGameCorrect,
				playTime: gameState.state.startTime != undefined ? Math.round((new Date() - gameState.state.startTime) / 1000) : 0, //convert to second
				recentGameDate: new Date()
			}

			console.log("Final Update scores")
			console.log(gameState.state.categoryScores)
			console.log("gameMaximumScore:" + gameState.maximumGameScore)
			var updateUserGameStats = Firebase.functions().httpsCallable('updateUserGameStats');
			updateUserGameStats({ dbContext: globalState.DBPath, additionalStats: additionalStats, categoryScores: gameState.state.categoryScores, maximumGameScore: gameState.maximumGameScore }).then(function (response) {
			}).catch((e) => {
				// run local update if failed?
				console.log(e)
			})
			var updateGameStats = Firebase.functions().httpsCallable('updateGameStats');
			updateGameStats({ dbContext: globalState.DBPath, additionalStats: additionalStats, maximumGameScore: gameState.maximumGameScore }).then(function (response) {
				// console.log(response)
			}).catch((e) => {
				console.log(e)
			})
		} else {
			console.log("failed to update profile: gameState not set")
		}
		console.log("going aftergame in updateProfile")
		navigation.replace('after_game')
		// setNavigatedToAfterGame(true)
	}

	function calculateGameAnswersCorrect(categoryScores) {
		// console.log(categoryScores)
		var totalAnswersCorrect = 0
		for (var currentCategoryID in categoryScores) {
			// console.log(currentCategoryID)
			totalAnswersCorrect += categoryScores[currentCategoryID]
		}
		return totalAnswersCorrect
	}

	// function calculateGameStats(previousGameStats, gameScore) {
	// 	const newGameStats = (previousGameStats != undefined) ? previousGameStats : {
	// 		gamesWon: 0,
	// 		gamesPlayed: 0,
	// 		overallVictories: 0,
	// 	}
	// 	const completionRequirement = (gameInfo.completionThresholdPercent != undefined) ? gameInfo.completionThresholdPercent : 50
	// 	if (gameScore > gameInfo.maximumScore * completionRequirement / 100) {
	// 		// game is won/completed
	// 		newGameStats.gamesWon = newGameStats.gamesWon + 1
	// 	}

	// 	newGameStats.gamesPlayed = (newGameStats.gamesPlayed != undefined) ? newGameStats.gamesPlayed + 1 : 1
	// 	newGameStats.overallVictories = (newGameStats.overallVictories != undefined) ? newGameStats.overallVictories : 1 // overall victories?
	// 	return newGameStats
	// }

	function calculateUnlistedGameStats(previousUnlistedGameStats, gameScore) {
		const newUnlistedGameStats = (previousUnlistedGameStats != undefined) ? previousUnlistedGameStats : {}
		// if (gameScore > gameInfo.maximumScore * gameInfo.completionThresholdPercent / 100) {
		// 	// game is won/completed
		// 	newGameStats.gamesWon = previousUnlistedGameStats.gamesWon + 1
		// }

		newUnlistedGameStats.recentGameDate = Date.now()
		newUnlistedGameStats.playTime = (newUnlistedGameStats.playTime != undefined) ? previousUnlistedGameStats.playTime + playTime : playTime // overall victories?
		return newUnlistedGameStats
	}

	// need list of achievements to continue
	// function calculateAchievements(previousAchievements) {
	// 	var updatedAchievements = previousAchievements
	// 	//add additional metadata for achievements e.g. last played date/ streak start date in db
	// 	// update achievement progress
	// 	//check if new achievement received
	// 	//add achievement to recent achievements
	// 	return updatedAchievements
	// }

	// temporary until cloud funciton does it
	function calculateDepartmentScore(departmentGameScores) {
		var gameState = require('../assets/GameState')

		var totalAnswersCorrect = 0
		for (var currentGameID in departmentGameScores) {
			for (var currentCategoryID in departmentGameScores[currentGameID]) {
				totalAnswersCorrect += departmentGameScores[currentGameID][currentCategoryID]
			}
		}
		console.log("gameState.gameInfo.maximumGameScore")
		console.log(gameState.gameInfo.maximumGameScore)
		if (gameState.gameInfo.maximumGameScore != undefined && gameState.gameInfo.maximumGameScore > 0) {
			return totalAnswersCorrect / gameState.gameInfo.maximumGameScore
		} else {
			Alert.alert("The game's maximumscore is not valid.")
			return 0.5
		}
	}

	// for use by children
	function setModalDisplay(shouldDisplay) {
		setShowModal(shouldDisplay);
	}

	// called by timer
	function timeUp() {
		selectAnswer(-1)

		//show timeup
	}

	// called by timer
	function setTimeTaken(timeTaken) {
		setPlayTime(playTime + timeTaken)
	}

	function timerIsStarted() {
		console.log(shouldStartTimer)
		if (shouldStartTimer == true) {
			setShouldStartTimer(false);
			// gameState.shouldStartTimer = true
			console.log("G timer is started ")
		}
	}
	function timerIsStopped() {
		console.log(shouldStopTimer)
		if (shouldStopTimer == true) {
			setShouldStopTimer(false);
			console.log("G timer is stopped ")
		}
	}

	// credit: https://stackoverflow.com/questions/49555273/how-to-shuffle-an-array-of-objects-in-javascript
	function shuffleFisherYates(array) {
		let i = array.length;
		while (i--) {
			const ri = Math.floor(Math.random() * (i + 1));
			[array[i], array[ri]] = [array[ri], array[i]];
		}
		return array;
	}

	function parseQuestionText(questionText) {
		const fillInSpaceLength = 7
		if (questionText != undefined) {
			var newText = questionText
			for (var i = questionText.length - 1; i >= 0; i--) {
				if (questionText[i] == "_") { // To support wrods that use underscore && ((i - 1 > 0 && questionText[i-1] == " ") || i == 0)) {
					newText = newText.slice(0, i) + "_".repeat(fillInSpaceLength) + newText.slice(i)
				}
			}
			return newText
		} else {
			console.log("questionText undefined. CurrentQuestion" + currentQuestion)
			return LOADING_QUESTION
		}

	}

	function renderLearningMedia(mediaUrl, mediaType) {
		const validMediaTypes = ["video"]

		if (mediaUrl == undefined && (mediaType != undefined || mediaType != "none")) {
			Alert.alert("Media is undefined. To address this, notify the department manager.")
			return null
		}

		if (validMediaTypes.indexOf(mediaType) < 0) {
			return null
		}

		// console.log(mediaUrl)
		if (mediaType == "video") {
			return (
				<View style={styles.videoContainer}>
					<WebView
						style={styles.WebView}
						javaScriptEnabled={true}
						domStorageEnabled={true}
						// source={{uri: 'https://www.youtube.com/embed/YE7VzlLtp-4' }}
						source={{ uri: mediaUrl }}
					/>
				</View>
			)
		}
		// return(
		// <View style={styles.videoContainer}>
		// 	<WebView
		// 		style={styles.WebView}
		// 		javaScriptEnabled={true}
		// 		domStorageEnabled={true}
		// 		source={{uri: 'https://www.youtube.com/embed/YE7VzlLtp-4' }}
		// 		// source={{ uri: mediaUrl }}
		// 	/>
		// 	</View>
		// )
	}

	function renderLeaderboardModal() {
		// console.log("globalState.isWebDisplay" + globalState.isWebDisplay)
		if (globalState.isWebDisplay == true) {
			return null
		} else {
			return (
				<LeaderboardModal setModalDisplay={setModalDisplay} modalVisible={showModal} />
			)
		}
	}


	// conditionally move questionnumber up when ther s questionMedia

	// imageBackgroundIndex // set in state

	function renderMultiChoiceButtons() {
		var customRowStyle = StyleSheet.create({
			answerButtonRowTop: {
				minHeight: hp('18%'),
				maxHeight: hp('18%'),
				// backgroundColor: '#000'
			},
			answerButtonRowBottom: {
				minHeight: hp('18%'),
				maxHeight: hp('18%'),
				// backgroundColor: '#000'
			}
		})
		return (
			<View style={styles.answerButtonsContainer}>
				<View style={[styles.answerButtonRowTop, customRowStyle.answerButtonRowTop]}>
					<GameButton answerIndex={0} answerOptionText={currentQuestion != undefined && currentQuestion.answers != undefined ? currentQuestion.answers["0"] : "A?"} selectAnswer={selectAnswer} buttonStatus={buttonStatus[0]} disabled={!isQuestionActive}></GameButton>
					<GameButton answerIndex={1} answerOptionText={currentQuestion != undefined && currentQuestion.answers != undefined ? currentQuestion.answers['1'] : "B?"} selectAnswer={selectAnswer} buttonStatus={buttonStatus[1]} customStyle={button2Style.button} disabled={!isQuestionActive}></GameButton>
				</View>
				<View style={[styles.answerButtonRowBottom, customRowStyle.answerButtonRowBottom]}>
					<GameButton answerIndex={2} answerOptionText={currentQuestion != undefined && currentQuestion.answers != undefined ? currentQuestion.answers['2'] : "C?"} selectAnswer={selectAnswer} buttonStatus={buttonStatus[2]} customStyle={button3Style.button} disabled={!isQuestionActive}></GameButton>
					<GameButton answerIndex={3} answerOptionText={currentQuestion != undefined && currentQuestion.answers != undefined ? currentQuestion.answers['3'] : "D?"} selectAnswer={selectAnswer} buttonStatus={buttonStatus[3]} customStyle={button4Style.button} disabled={!isQuestionActive}></GameButton>
				</View>
			</View>
		)
	}

	// put in columns instead?
	function renderMatchTermButtons() {
		const defaultForegroundColours = ["#FF5454", "#549EFF", "#FF5488", "#FF924A", "#FF5454"]
		const defaultBackgroundColours = ['#EB3C3C', '#5378FF', '#DB3D6C', '#FF6500', '#EB3C3C']

		var selectedButtonColours = []
		for (var i = 0; i < defaultForegroundColours.length; i++) {
			selectedButtonColours.push({
				foreground: defaultForegroundColours[i],
				background: defaultBackgroundColours[i]
			})
		}

		var buttonColours = []
		for (var i = 0; i < 10; i++) {
			buttonColours.push({
				foreground: "#F0DEDE",
				background: "#DEBEBE"
			})
		}

		Object.keys(matchTermState.matchedTerms).forEach((leftIndex) => {
			if (matchTermState.matchedTerms[leftIndex] > -1) {
				buttonColours[leftIndex] = selectedButtonColours[leftIndex]
				// matchTermState.matchedTerms[leftIndex] + 5
				console.log(matchTermState.matchedTerms[leftIndex])
				buttonColours[matchTermState.matchedTerms[leftIndex] + 5] = selectedButtonColours[leftIndex]
			}
		});

		if (matchTermState.selectedLeftTermIndex >= 0) {
			buttonColours[matchTermState.selectedLeftTermIndex] = selectedButtonColours[matchTermState.selectedLeftTermIndex]
		}



		function mapMatchTermRow() {
			if (matchTermState == undefined || matchTermState.leftTerms == undefined || matchTermState.rightTerms == undefined) {

				console.log("required property not set")
				return
			}
			console.log(matchTermState.termsDisplayedLeft)
			return ( // added keys, may break
				matchTermState.leftTerms.map((term, i) =>
					<View style={styles.answerButtonRowTop} key={i + "v"}>
						<GameButton answerIndex={i} answerOptionText={matchTermState != undefined && matchTermState.leftTerms != undefined ? matchTermState.leftTerms[i].name : "A?"} key={i + "a"}
							selectAnswer={selectAnswer} customForegroundColor={buttonColours[i].foreground} customBackgroundColor={buttonColours[i].background} buttonStatus={buttonStatus[0]} disabled={!isQuestionActive}></GameButton>
						<GameButton answerIndex={i + 5} answerOptionText={matchTermState != undefined && matchTermState.rightTerms != undefined ? matchTermState.rightTerms[i].name : "B?"} key={i + "b"}
							selectAnswer={selectAnswer} customForegroundColor={buttonColours[i + 5].foreground} customBackgroundColor={buttonColours[i + 5].background} buttonStatus={buttonStatus[1]} customStyle={button2Style.button} disabled={!isQuestionActive}></GameButton>
					</View>
				)
			)
		}

		// add loop
		return (
			<View style={styles.answerButtonsContainer}>
				{mapMatchTermRow()}
				{/* <View style={styles.answerButtonRowTop}>
					<GameButton answerIndex={0} answerOptionText={currentQuestion != undefined && currentQuestion.answers != undefined ? currentQuestion.answers["0"] : "A?"}
						selectAnswer={selectAnswer} customForegroundColor={buttonColours[0].foreground} customBackgroundColor={buttonColours[0].background} buttonStatus={buttonStatus[0]} disabled={!isQuestionActive}></GameButton>
					<GameButton answerIndex={5} answerOptionText={currentQuestion != undefined && currentQuestion.answers != undefined ? currentQuestion.answers['1'] : "B?"}
						selectAnswer={selectAnswer} customForegroundColor={buttonColours[5].foreground} customBackgroundColor={buttonColours[5].background} buttonStatus={buttonStatus[1]} customStyle={button2Style.button} disabled={!isQuestionActive}></GameButton>
				</View>
				<View style={styles.answerButtonRowTop}>
					<GameButton answerIndex={1} answerOptionText={currentQuestion != undefined && currentQuestion.answers != undefined ? currentQuestion.answers["0"] : "A?"}
						selectAnswer={selectAnswer} customForegroundColor={buttonColours[1].foreground} customBackgroundColor={buttonColours[1].background} buttonStatus={buttonStatus[0]} disabled={!isQuestionActive}></GameButton>
					<GameButton answerIndex={6} answerOptionText={currentQuestion != undefined && currentQuestion.answers != undefined ? currentQuestion.answers['1'] : "B?"}
						selectAnswer={selectAnswer} customForegroundColor={buttonColours[6].foreground} customBackgroundColor={buttonColours[6].background} buttonStatus={buttonStatus[1]} customStyle={button2Style.button} disabled={!isQuestionActive}></GameButton>
				</View>
				<View style={styles.answerButtonRowTop}>
					<GameButton answerIndex={2} answerOptionText={currentQuestion != undefined && currentQuestion.answers != undefined ? currentQuestion.answers["0"] : "A?"}
						selectAnswer={selectAnswer} customForegroundColor={buttonColours[2].foreground} customBackgroundColor={buttonColours[2].background} buttonStatus={buttonStatus[0]} disabled={!isQuestionActive}></GameButton>
					<GameButton answerIndex={7} answerOptionText={currentQuestion != undefined && currentQuestion.answers != undefined ? currentQuestion.answers['1'] : "B?"}
						selectAnswer={selectAnswer} customForegroundColor={buttonColours[7].foreground} customBackgroundColor={buttonColours[7].background} buttonStatus={buttonStatus[1]} customStyle={button2Style.button} disabled={!isQuestionActive}></GameButton>
				</View>
				<View style={styles.answerButtonRowTop}>
					<GameButton answerIndex={3} answerOptionText={currentQuestion != undefined && currentQuestion.answers != undefined ? currentQuestion.answers["0"] : "A?"}
						selectAnswer={selectAnswer} customForegroundColor={buttonColours[3].foreground} customBackgroundColor={buttonColours[3].background} buttonStatus={buttonStatus[0]} disabled={!isQuestionActive}></GameButton>
					<GameButton answerIndex={8} answerOptionText={currentQuestion != undefined && currentQuestion.answers != undefined ? currentQuestion.answers['1'] : "B?"}
						selectAnswer={selectAnswer} customForegroundColor={buttonColours[8].foreground} customBackgroundColor={buttonColours[8].background} buttonStatus={buttonStatus[1]} customStyle={button2Style.button} disabled={!isQuestionActive}></GameButton>
				</View>
				<View style={styles.answerButtonRowBottom}>
					<GameButton answerIndex={4} answerOptionText={currentQuestion != undefined && currentQuestion.answers != undefined ? currentQuestion.answers['2'] : "C?"}
						selectAnswer={selectAnswer} customForegroundColor={buttonColours[4].foreground} customBackgroundColor={buttonColours[4].background} buttonStatus={buttonStatus[2]} customStyle={button3Style.button} disabled={!isQuestionActive}></GameButton>
					<GameButton answerIndex={9} answerOptionText={currentQuestion != undefined && currentQuestion.answers != undefined ? currentQuestion.answers['3'] : "D?"}
						selectAnswer={selectAnswer} customForegroundColor={buttonColours[9].foreground} customBackgroundColor={buttonColours[9].background} buttonStatus={buttonStatus[3]} customStyle={button4Style.button} disabled={!isQuestionActive}></GameButton>
				</View> */}
			</View>
		)
	}


	function renderWordBucketAnswerInterface() {
		// console.log(matchTermState)
		// console.log("rendering buttons")

		// randomise colours later?
		const defaultForegroundColours = ["#FF5454", "#549EFF", "#FF5488", "#FF924A", "#FF5454"]
		const defaultBackgroundColours = ['#EB3C3C', '#5378FF', '#DB3D6C', '#FF6500', '#EB3C3C']

		var selectedButtonColours = []
		for (var i = 0; i < defaultForegroundColours.length; i++) { // not size 10 = issue
			selectedButtonColours.push({
				foreground: defaultForegroundColours[i],
				background: defaultBackgroundColours[i]
			})
		}

		var buttonColours = []
		for (var i = 0; i < 20; i++) {
			buttonColours.push({
				foreground: "#549EFF",
				background: '#5378FF'
			})
		}



		//fixed color per item? same colour for bucket?

		// Object.keys(matchTermState.matchedTerms).forEach((leftIndex) => {
		// 	if (matchTermState.matchedTerms[leftIndex] > -1) {
		// 		buttonColours[leftIndex] = selectedButtonColours[leftIndex]
		// 		// matchTermState.matchedTerms[leftIndex] + 5
		// 		console.log(matchTermState.matchedTerms[leftIndex])
		// 		buttonColours[matchTermState.matchedTerms[leftIndex] + 5] = selectedButtonColours[leftIndex]
		// 	}
		// });

		//probable culprit
		if (bucketState.selectedItemIndex >= 0) {
			buttonColours[bucketState.selectedItemIndex].foreground = "#FF924A" //make green when selected
			buttonColours[bucketState.selectedItemIndex].background = '#FF6500' //make green when selected
		}

		// should be columns but no time to do it.
		function mapBucketRow() {
			if (bucketState == undefined || bucketState.buckets == undefined || bucketState.buckets.left == undefined || bucketState.buckets.right == undefined) {

				console.log("required property not set")
				return
			}

			// disable view of button
			var numRows = bucketState.buckets.left.length >= bucketState.buckets.right.length ? bucketState.buckets.left.length : bucketState.buckets.right.length
			var rows = Array(numRows).fill(0)

			// check fields earlier
			// console.log(bucketState.buckets)
			// console.log(rows)

			return (
				rows.map((term, i) =>
					<View style={styles.answerButtonRowTop} key={i + "v"}>
						<GameButton answerIndex={i} isVisible={i < bucketState.buckets.left.length ? true : false}
							answerOptionText={bucketState != undefined && bucketState.buckets != undefined && bucketState.buckets.left != undefined && bucketState.buckets.left[i] != undefined ? bucketState.buckets.left[i].name : "A?"}
							selectAnswer={selectBucketItem} customForegroundColor={buttonColours[i].foreground} customBackgroundColor={buttonColours[i].background} buttonStatus={buttonStatus[0]} disabled={!isQuestionActive}>
							key={i + "a"}
						</GameButton>
						<GameButton answerIndex={i + 10} isVisible={i < bucketState.buckets.right.length ? true : false}
							// answerOptionText={bucketState != undefined && bucketState.buckets != undefined && bucketState.buckets.right != undefined && bucketState.buckets.right[i] != undefined ? bucketState.buckets.right[i].name : "B?"}
							answerOptionText={bucketState != undefined && bucketState.buckets != undefined && bucketState.buckets.right != undefined && bucketState.buckets.right[i] != undefined ? bucketState.buckets.right[i].name : "B?"}
							selectAnswer={selectBucketItem} customForegroundColor={buttonColours[Number(i + 5)].foreground} customBackgroundColor={buttonColours[i + 5].background} buttonStatus={buttonStatus[1]} customStyle={button2Style.button} disabled={!isQuestionActive}>
							key={i + "b"}
						</GameButton>
					</View>
				)
			)
		}




		// add loop
		return (
			<View style={styles.answerButtonsContainer}>
				{/* // maybe put outside */}
				<View style={styles.answerButtonRowTop} >
					<View style={{ minWidth: wp(49), maxWidth: wp(49), justifyContent: 'center', alignItems: 'center' }} >
						<Text style={{ maxWidth: wp(49), textAlign: 'center', fontSize: hp('3%'), backgroundColor: "#FF924A", borderRadius: hp('2%'), color: '#FFF', opacity: 0.87, paddingHorizontal: wp(1) }}> {currentQuestion.buckets[0].name}</Text>
					</View>
					<View style={{ minWidth: wp(49), maxWidth: wp(49), justifyContent: 'center', alignItems: 'center' }} >
						<Text style={{ maxWidth: wp(49), textAlign: 'center', fontSize: hp('3%'), backgroundColor: "#FF5488", borderRadius: hp('3%'), color: '#FFF', opacity: 0.87, paddingHorizontal: wp(1) }}> {currentQuestion.buckets[1].name}</Text>
					</View>
					{/* <Text> {"SMALL CARS"}</Text> */}
				</View>
				{mapBucketRow()}
				<View style={{ maxHeight: hp(10), minHeight: hp(10) }}>
					<StyledButton text={"Done"} onPressFunction={submitBucketAnswer} backgroundColor={"#1FC75A"} shadowColour={'#009936'}></StyledButton>
				</View>
			</View>
		)
	}


	function bucketSelectAreas() {
		// console.log("bucketSelectAreasrender\n")
		if (currentQuestion == undefined || currentQuestion.questionType != 3) {
			return null
		}


		if (bucketState.selectedItemIndex == -1) {
			// console.log("not rendering")
			return null
		} else {
			console.log("rendering areas")
			return (
				<View style={{
					position: 'absolute',
					// bottom: windowHeight,
					bottom: 0,
					left: 0,
					minHeight: 0.5 * windowHeight,
					maxHeight: 0.5 * windowHeight,
					// flex: 1,
					width: windowWidth,
					height: 0.5 * windowHeight,
					minWidth: windowWidth, maxWidth: windowWidth,
					// backgroundColor: '#000',
					opacity: 0.1,
					flexDirection: 'row'
				}} >
					<TouchableOpacity
						style={{
							minWidth: wp(49), maxWidth: wp(49), backgroundColor: '#FF924A', minHeight: 0.5 * windowHeight
						}}
						activeOpacity={0.5}
						onPress={() => {
							// props.selectAnswer(answerIndex);
							moveBucketItem(0)
							// console.log("works 1")
						}}
						disabled={props.disabled}
					>
					</TouchableOpacity>
					<TouchableOpacity
						style={{
							minWidth: wp(49), maxWidth: wp(49), backgroundColor: '#FF5488', minHeight: 0.5 * windowHeight
						}}
						activeOpacity={0.5}
						onPress={() => {
							// props.selectAnswer(answerIndex);
							moveBucketItem(1)
							// console.log("works 2")
						}}
						disabled={props.disabled}
					>
					</TouchableOpacity>
				</View>
			)
		}
	}


	function renderImageAreaSelect(questionData, gridData) {
		// const questionData = {
		// 	gridWidth: 10,
		// 	gridHeight: 11,
		// 	image: {
		// 		width: 720,
		// 		height: 526,
		// 		url: "https://firebasestorage.googleapis.com/v0/b/test-fddd5.appspot.com/o/company_images%2F9830889b-21ff-477e-b136-bf5cfd6d9237?alt=media&token=87230f8c-4d9e-475f-92b7-e7042dd3a09e",
		// 		path: ""
		// 	},
		// 	questionText: "",
		// 	selectables: [
		// 		{
		// 			id: "dnds93hnd",
		// 			clickables: [
		// 				{ x: 1, y: 8 },
		// 				{ x: 1, y: 9 },
		// 			]
		// 		},
		// 		{
		// 			id: "dnds93hnd",
		// 			clickables: [
		// 				{ x: 5, y: 7 },
		// 				{ x: 5, y: 7 },
		// 			]
		// 		},
		// 	]
		// };


		const numRows = questionData.gridHeight
		const numColumns = questionData.gridWidth

		const imageAspectRatio = questionData.image.width / questionData.image.height

		const effectiveImageWidth = windowWidth
		const scaling = effectiveImageWidth / questionData.image.width
		const effectiveImageHeight = questionData.image.height * scaling
		// const cellHeight = image

		const cellHeight = effectiveImageHeight / numRows
		const cellWidth = windowWidth / numColumns

		// if (wasGroupSelected == undefined) {
		// 	setWasGroupSelected(Array(questionData.selectables.length).fill(false)) // only in initialised phase
		// }

		var gridStyles = StyleSheet.create({
			cellContent: {
				margin: 0,
				// backgroundColor: "#000004",
				// opacity: 0.5,
				opacity: 0.0,
				padding: 0,
				borderWidth: 0,
				height: '100%'
			},
			cell: {

				maxHeight: cellHeight,
				minHeight: cellHeight,
				maxWidth: cellWidth,
				minWidth: cellWidth,
			},
			gridImage: {
				minWidth: windowWidth,
				maxWidth: windowWidth,

				minHeight: effectiveImageHeight,
				maxHeight: effectiveImageHeight
			},
			grid: {
				flexDirection: 'column',
			},
			row: {
				flexDirection: 'row'
			}
		})


		function renderCell(cell) {
			if (cell == undefined) {
				return undefined
			}
			return (
				// <View style={gridStyles.cell}>
				//     <Text style={{textAlign:'center'}}>1</Text>
				// </View>
				<TouchableOpacity
					style={gridStyles.cell}
					activeOpacity={0.98}
					onPress={
						() => {
							if (isQuestionActive == false) {
								console.log("Question not active")
								return
							}

							if (wasGroupSelected == undefined) {
								console.log("wasGroupSelected is undefined")
								navigation.replace('dashboard')
								return
							}

							var newWasGroupSelected = wasGroupSelected
							var newTapsLeft = tapsLeft 
							console.log("tapsLeft: " + newTapsLeft)
							if (cell < 0) {
								console.log("incorrect area selected, reduce taps left")
								newTapsLeft = tapsLeft - 1
								setTapsLeft(newTapsLeft)
								// setTapsLeft(tapsLeft - 1)
							} else {
								console.log(wasGroupSelected)
								console.log("cell " +  cell)
								if (wasGroupSelected[cell] == false) {
									newWasGroupSelected[cell] = true
									setWasGroupSelected(newWasGroupSelected)
									newTapsLeft = tapsLeft - 1
									setTapsLeft(newTapsLeft)

									// ensure order is correct. previous things may not be set before next is called


								} else {
									console.log("already selected")
								}
								// next()

							}


							if (newTapsLeft <= 0) {
								console.log("Submit answer")
								selectAnswer(newWasGroupSelected)
								//next
							}
						}
					}
				>
					<View style={gridStyles.cellContent}>
						<Text style={{ color: '#FFF', fontSize: 15, opacity: 0.87, textAlign: 'center' }}>
							{cell >= 0 ? cell : ""}
						</Text>

					</View>
				</TouchableOpacity>
			)
		}

		function renderRow(rowData) {
			if (rowData == undefined) {
				return undefined
			}
			
			return (
				<View style={gridStyles.row}>
					{rowData.map((cell, i) =>
						(renderCell(cell, i)
						)
					)}
				</View>
			)
		}

		function renderGrid(gridData) {
			if (gridData == undefined) {
				return null
			}
			return (

				<View style={gridStyles.grid}>
					{gridData.map((rowData, i) =>
						(renderRow(rowData, i)
						)
					)}
				</View>

			)
			// return null
		}

		// const backgroundImageUrl = "https://firebasestorage.googleapis.com/v0/b/test-fddd5.appspot.com/o/company_images%2F9830889b-21ff-477e-b136-bf5cfd6d9237?alt=media&token=87230f8c-4d9e-475f-92b7-e7042dd3a09e"
		const backgroundImageUrl = questionData.image.url

		return (
			// <View>
			<ImageBackground source={{ uri: backgroundImageUrl }} style={gridStyles.gridImage} resizeMode="contain">
				{renderGrid(gridData)}
			</ImageBackground>
			// </View>
		);
	}

	function createGrid(width, height) {
		var grid = []
		for (let i = 0; i < height; i++) {
			grid.push(Array(width).fill(-1))
		}
		setGridData(grid)
		return grid
	}

	function addToGrid(gridData, questionData) {
		if (questionData == undefined) {
			console.log("error questionData is undefined")
		}
		var newGridData = gridData
		const selectableGroups = questionData.selectables // NB SET SELECTABLES to maintain order? or at least keep i question?
		selectableGroups.forEach((selectableGroup, groupIndex) => {
			const currentGroupIndex = groupIndex
			// console.log("groupindex" + currentGroupIndex)
			var clickableGroup = selectableGroup.clickables
			clickableGroup.forEach(selectablePosition => {
				// console.log(currentGroupIndex)
				newGridData[selectablePosition.y][selectablePosition.x] = currentGroupIndex
			})
			// console.log("endgroup")
		});
		// console.log(newGridData)
		// printGridData(newGridData)
		return newGridData
	}

	// function printGridData(gridData) {
	// 	var printString = ""
	// 	gridData.forEach(element => {
	// 		element.forEach(element2 => {
	// 			printString += printString + " "
	// 		});
	// 		printString +='\n'
	// 	});
	// 	console.log(printString)
	// }


	function renderAnswerButtons(questionType) {
		if (questionType == 0 || questionType == 1) {
			return (renderMultiChoiceButtons())
		} else if (questionType == 2) {
			return (renderMatchTermButtons())
		} else if (questionType == 3) {
			return (renderWordBucketAnswerInterface())
		} else if (questionType == 4) {
			// gridData
			return (renderImageAreaSelect(currentQuestion, gridData))
		}

	}

	// make sure gamestate is correct here

	return (
		<View style={styles.gameContainer}>
			<Image source={backgroundImages[imageBackgroundIndex]} style={styles.backgroundImage}></Image>
			<View style={styles.headerContainerStyle}>
				{/* <CountDownTimer questionTime={questionTime} refresh={refreshTimer} setTimeTaken={setTimeTaken}
					timeUp={timeUp} isQuestionActive={isQuestionActive} timerIsStarted={timerIsStarted} timerIsStopped={timerIsStopped} shouldStartTimer={gameState.shouldStartTimer}
					shouldStopTimer={gameState.shouldStopTimer}></CountDownTimer> */}
				{/* <CountDownTimer questionTime={questionTime} refresh={refreshTimer} setTimeTaken={setTimeTaken} timeUp={timeUp} isQuestionActive={isQuestionActive}
					timerIsStarted={timerIsStarted} timerIsStopped={timerIsStopped} ></CountDownTimer> */}
			</View>
			<View style={styles.iconContrainer}>
				{/* TODO trophy icon. It's not in the standard set: https://wiki.in.tum.de/System/MaterialIcons */}
				<Icon name='view-headline' size={hp('5%')} color='#000' onPress={() => { setModalDisplay(true) }} iconStyle={{ opacity: 0.8, width: '100%' }} />
			</View>
			{renderLeaderboardModal()}
			<View style={styles.questionTextContainer}>
				<Text style={styles.questionNumberText}>Question {currentQuestionIndex + 1}</Text>
				<Text style={styles.questionText}>{currentQuestion != undefined ?
					((currentQuestion.questionType != 4) ?
						parseQuestionText(currentQuestion.questionText)
						: parseQuestionText(currentQuestion.questionText) + "\n" + tapsLeft + " taps left")
					: LOADING_QUESTION}</Text>
				{/* <Image source={images[18-currentQuestionIndex]} style={styles.image} /> */}
				{/* <QuestionText currentQuestionText={currentQuestion != undefined ? current Question.questionText : LOADING_QUESTION}></QuestionText> */}
			</View>
			{currentQuestion ? renderLearningMedia(currentQuestion.mediaLink, currentQuestion.mediaType) : null}
			{/* <QuestionAnsweredBanner result={questionResult}></QuestionAnsweredBanner> */}
			{renderAnswerButtons(currentQuestion != undefined && currentQuestion.questionType != undefined ? currentQuestion.questionType : 0)}
			{bucketSelectAreas()}
			<QuestionAnsweredBanner result={questionResult}></QuestionAnsweredBanner>
			{/* {re} */}
			{/* <View style={styles.answerButtonsContainer}>
				<View style={styles.answerButtonRowTop}>
					<GameButton answerIndex={0} answerOptionText={currentQuestion != undefined && currentQuestion.answers != undefined ? currentQuestion.answers["0"] : "A?"} selectAnswer={selectAnswer} buttonStatus={buttonStatus[0]} disabled={!isQuestionActive}></GameButton>
					<GameButton answerIndex={1} answerOptionText={currentQuestion != undefined && currentQuestion.answers != undefined ? currentQuestion.answers['1'] : "B?"} selectAnswer={selectAnswer} buttonStatus={buttonStatus[1]} customStyle={button2Style.button} disabled={!isQuestionActive}></GameButton>
				</View>
				<View style={styles.answerButtonRowBottom}>
					<GameButton answerIndex={2} answerOptionText={currentQuestion != undefined && currentQuestion.answers != undefined ? currentQuestion.answers['2'] : "C?"} selectAnswer={selectAnswer} buttonStatus={buttonStatus[2]} customStyle={button3Style.button} disabled={!isQuestionActive}></GameButton>
					<GameButton answerIndex={3} answerOptionText={currentQuestion != undefined && currentQuestion.answers != undefined ? currentQuestion.answers['3'] : "D?"} selectAnswer={selectAnswer} buttonStatus={buttonStatus[3]} customStyle={button4Style.button} disabled={!isQuestionActive}></GameButton>
				</View>
			</View> */}
			{/* <Image source={backgroundImages[0]} style={styles.backgroundImage}></Image> */}
			<LoadingIndicator isLoading={isLoading} />
		</View>
	);
}