import { StyleSheet, View, Text, Button, Dimensions, ScrollView, Alert, FlatList, ImageBackground, TouchableOpacity } from 'react-native';
import React, { useState, useEffect } from "react";
import { ListItem, Avatar, Input } from 'react-native-elements';
import { heightPercentageToDP as hp } from 'react-native-responsive-screen';

import { primaryColour, primarySideColour } from '../styles/globalColours'
// import { styles, inputContainerStyle, buttonColor, buttonShadowColor, loginButtonStyle } from '../styles/Standard/screens/Profile.styles'; //rename
import { getElement, getStorageRef } from '../firebase.config';
import { LoadingIndicator } from '../components/common/LoadingIndicator';
import { StyledButton } from '../components/common/StyledButton';
import * as ImagePicker from 'expo-image-picker';
import Constants from 'expo-constants';
import { images } from '../assets/images';
import { achievementSound } from '../assets/sounds';

import { SafeAreaView, StatusBar } from "react-native";
import { styles } from '../styles/Standard/game/GameButton';
// import { create } from 'core-js/fn/object';

const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height
const cardsContainerWidth = (windowWidth / windowHeight > 1) ? 0.5 * windowHeight * 1.2 : windowWidth

// input.current.focus();

// ROBOTO FONT: https://stackoverflow.com/questions/57066075/console-error-fontfamily-roboto-medium-is-not-a-system-font-and-has-not-been

export function TempGameScreen(props) {

 

    const [tapsLeft, setTapsLeft] = useState(undefined)
    const [wasGroupSelected, setWasGroupSelected] = useState(undefined)


    const globalState = require('../assets/GlobalState')

    // using  componet with props instead of an  local function with parameters might enable refreshing when props change 

    // console.log("cant use windowwidth for web.")

    console.log("store grid so doesnt have to be rerendered")

    const dto = {
        gridWidth: 10,
        gridHeight: 11,
        image: {
            width: 720,
            height: 526,
            url: "https://firebasestorage.googleapis.com/v0/b/test-fddd5.appspot.com/o/company_images%2F9830889b-21ff-477e-b136-bf5cfd6d9237?alt=media&token=87230f8c-4d9e-475f-92b7-e7042dd3a09e",
            path: ""
        },
        questionText: "",
        selectables: [
            {
                id: "dnds93hnd",
                clickables: [
                    { x: 1, y: 8 },
                    { x: 1, y: 9 },
                ]
            },
            {
                id: "dnds93hnd",
                clickables: [
                    { x: 5, y: 7 },
                    { x: 5, y: 7 },
                ]
            },
        ]
    };

    if (wasGroupSelected == undefined) {
        setWasGroupSelected(Array(dto.selectables.length).fill(false)) // only in initialised phase
    }

    console.log("use effective display width instead of windowWidth")

    const numRows = dto.gridHeight
    const numColumns = dto.gridWidth


    const imageAspectRatio = dto.image.width / dto.image.height

    const effectiveImageWidth = windowWidth
    const scaling = effectiveImageWidth /dto.image.width
    const effectiveImageHeight = dto.image.height * scaling
    // const cellHeight = image

    const cellHeight = effectiveImageHeight / numRows
    const cellWidth =  windowWidth/ numColumns

    function createGrid(width, height) {
        var grid = []

        for (let i = 0; i <height; i++) {
            grid.push( Array(width).fill(-1))
            
        }
        return grid
    }

    // deep copy?
    function addToGrid(gridData, questionData) {
        var newGridDate = gridData
        const selectableGroups = questionData.selectables // NB SET SELECTABLES to maintain order? or at least keep i question?
        selectableGroups.forEach( (selectableGroup, groupIndex) => {
            var clickableGroup = selectableGroup.clickables
            clickableGroup.forEach(selectablePosition => {
                //check for invalid values here?
                // especially if position within grid bounds
                // console.log(selectablePosition)
                // console.log(selectablePosition.x)
                // console.log(selectablePosition.y)
                newGridDate[selectablePosition.x][selectablePosition.y] = groupIndex
            })
        });
        return newGridDate
    }
    // initial  20x10
    //  window 5 iwide
    // scaling = 5/20]
    // image height = 10 * 5/20 ?



    var gridData = createGrid(dto.gridWidth, dto.gridHeight)
    gridData = addToGrid(gridData, dto)

    // // force height of block to be same as width

    const backgroundImageUrl = "https://firebasestorage.googleapis.com/v0/b/test-fddd5.appspot.com/o/company_images%2F9830889b-21ff-477e-b136-bf5cfd6d9237?alt=media&token=87230f8c-4d9e-475f-92b7-e7042dd3a09e"

    var gridStyles = StyleSheet.create({
        cellContent: {
            margin: 0,
            backgroundColor: "#000004",
            // opacity: 0.5,
            opacity: 0.7,
            padding: 0,
            borderWidth: 0,
            height: '100%'
        },
        cell: {
            // color: '#000',
            // opacity: 0.87,
            // fontSize: heightPercentageToDP(8),
            // fontWeight: '700',
            // marginHorizontal: windowWidth * 0.07,
            // marginTop: windowHeight * 0.03,
            // flex: 1,
            // flexShrink: 1,
            // flexBasis: 0,
            maxHeight: cellHeight,
            minHeight: cellHeight,
            maxWidth: cellWidth,
            minWidth: cellWidth,
            // color: '#FFF',
            // borderRadius: windowHeight * 0.03,
            // paddingHorizontal: heightPercentageToDP(2),
            // paddingVertical: heightPercentageToDP(1)
            // fontWeight: '800'
            // fontFamily: "Roboto-heavy"
            // justifyContent: "center"
            // marginBottom: windowHeight * 0.02,
            // overflow:'hidden'
        },
        gridImage: {
            minWidth: windowWidth,
            maxWidth: windowWidth,

            minHeight: effectiveImageHeight,
            maxHeight: effectiveImageHeight
        },
        grid: {
            flexDirection: 'column',
            // backgroundImage:
            // backgroundImage: "url(" + backgroundImageUrl + ")"
        },
        row: {
            flexDirection: 'row'
        }
    })


    function renderCell(cell) {
        if (cell == undefined) {
            return undefined
        }
        return (
            // <View style={gridStyles.cell}>
            //     <Text style={{textAlign:'center'}}>1</Text>
            // </View>
            <TouchableOpacity
                style={gridStyles.cell}
                activeOpacity={0.98}
                onPress={
                () => {
                    if (cell < 0 ) {
                        console.log("incorrect area selected, reduce taps left")
                        setTapsLeft(tapsLeft-1)
                    } else {
                        if (wasGroupSelected[cell] == false) {
                            var newWasGroupSelected = wasGroupSelected
                            newWasGroupSelected[cell] = true
                            setWasGroupSelected(newWasGroupSelected)
                            setTapsLeft(tapsLeft-1)
                        } else {

                        }

                    }
                }
            }
            >
                <View style={gridStyles.cellContent}>
                    <Text style={{ color: '#FFF', fontSize: 15, opacity: 0.87, textAlign: 'center' }}>
                        {cell}
                </Text>

                </View>
            </TouchableOpacity>
        )
    }

    function renderRow(rowData) {
        if (rowData == undefined) {
            return undefined
        }
        return (
            <View style={gridStyles.row}>
                {rowData.map((cell, i) =>
                    (renderCell(cell, i)
                    )
                )}
            </View>
        )
    }

    function renderGrid(gridData) {
        if (gridData == undefined) {
            return undefined
        }
        return (
            
                <View style={gridStyles.grid}>
                    {gridData.map((rowData, i) =>
                        (renderRow(rowData, i)
                        )
                    )}
                </View>
            
        )
        // return null
    }



    return (
        // <View>
            <ImageBackground source={{ uri: backgroundImageUrl }} style={gridStyles.gridImage} resizeMode="contain">
            {renderGrid(gridData)}
            </ImageBackground>
        // </View>
    );


}

// export 