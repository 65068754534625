
import React from "react";
import { StyleSheet, Text, TouchableOpacity, View, Dimensions, ProgressViewIOSComponent } from 'react-native';
import { styles } from '../../styles/Standard/common/StyledSubTitleButton.style';
import { useNavigation } from '@react-navigation/native';
import { Icon } from 'react-native-elements'
import { heightPercentageToDP as hp } from "react-native-responsive-screen";

const windowWidth = Dimensions.get('window').width

export function StyledSubTitleButton(props) {
    const navigation = useNavigation();
    const answerIndex = props.answerIndex;

    const customShadowStyle = StyleSheet.create({
        view: {
            backgroundColor: props.shadowColour,
        }
    });

    const customButtonStyle = StyleSheet.create({
        button: {
            backgroundColor: props.backgroundColor,
        }
    });
    // backgroundColor:props.shadowColour

    // console.log("render")

    // props.onPressFn = undefined
    var onPressFunction = (props.onPressFn != undefined) ? props.onPressFn : console.log("onpress undefined")
    // var onPressFunction = console.log("onpress undefined")

    const isExamTaken = props.isExamTaken != undefined && props.isExamTaken == true ? true:false

    return (
        <View style={[styles.thickShadowView, customShadowStyle.view]}>
            <TouchableOpacity
                style={[styles.button, customButtonStyle.button]}
                activeOpacity={0.5}
                //    rather set styles?
                onPress={() => {
                    // console.log("pressed")
                    onPressFunction()
                }}
                disabled={props.disabled}
            >
                <View style={styles.textBox}>
                    <View style={styles.examHeadingRow}>
                        <Text style={styles.buttonText}> {props.text == undefined ? "Option" : props.text}</Text>
                        <Icon name={isExamTaken ? 'done' : 'check-box-outline-blank'} size={hp(5)} color={isExamTaken ? '#79fc68' :'#ffbf66'} iconStyle={{ marginRight: windowWidth * 0.06 }} />
                    </View>
                    <Text style={styles.descriptionText}> {props.description == undefined ? "Option" : props.description}</Text>
                </View>
            </TouchableOpacity>
        </View>
    )
}