import { ScrollView, Text, Image, View, Alert } from 'react-native';
import React, { useState } from "react";
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { useNavigation } from '@react-navigation/native';
import { WebView } from 'react-native-webview';

import { styles } from '../styles/Standard/screens/GameIntro.styles'; //rename
import { images } from '../assets/images';
import { MediaDisplay } from '../components/common/MediaDisplay'

const Tab = createMaterialTopTabNavigator();

export function GameIntro({ route, navigation }, props) {
    const [selectedGameInfo, setSelectedGameInfo] = useState(undefined)
    const [gameMediaArray, setGameMediaArray] = useState(undefined)

    const globalState = require('../assets/GlobalState')
    const gameState = require('../assets/GameState')
    navigation.setOptions({ title: globalState.currentScreenTitle })

    if (route.params == undefined) {
        navigation.replace('dashboard')
    }

    if (selectedGameInfo == undefined) {
        if (route.params == undefined && globalState.gameInfo != undefined) {

            // setSelectedGameInfo(globalState.gameInfo)
        } else if (route.params != undefined) {
            const { gameInfo } = route.params
            // console.log(gameInfo)
            var newGameInfo = gameInfo
            globalState.gameInfo = newGameInfo // should be gamestate but working atm
            if (gameState.gameInfo == undefined) {
                gameState.gameInfo = {
                    maximumScore: 0
                }
            }
            if (gameInfo.maximumCorrect != undefined) {
                gameState.gameInfo.maximumScore = gameInfo.maximumCorrect
                gameState.gameInfo.maximumGameScore = gameInfo.maximumCorrect
                gameState.maximumScore = gameInfo.maximumCorrect
                // console.log( gameInfo.maximumCorrect)
            } else {
                console.log("game's maximumscore undefined")
            }
            setSelectedGameInfo(gameInfo)

            var gameMediaObject = gameInfo.media
            if (gameMediaObject != undefined) {
                var mediaArray = []
                Object.keys(gameMediaObject).map(function (key, i) {
                    mediaArray.push(gameMediaObject[key])
                })
                // console.log(mediaArray)
                setGameMediaArray(mediaArray)
            } else {
                console.log("No game media.")
                // navigation.navigate('game_categories')
            }
        }
        console.log("gameInfo undeined")

    } else {
        console.log("gameInfo defined")
        // show error
    }


    return (
        <MediaDisplay media={gameMediaArray} isLoading={false} isEmptyPage={gameMediaArray == undefined || gameMediaArray.length < 1? true: false}/>       
    );
}
