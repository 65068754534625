import { Audio } from 'expo-av';
import Constants from 'expo-constants';
import { Platform, Dimensions } from 'react-native';

const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height

export  function isPortraitView() {
    if (Constants.platform != 'android' && Constants.platform != 'ios'  && windowWidth / windowHeight > 0.9) {
        return false
    } else {
        return true
    }
}