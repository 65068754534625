import { StyleSheet } from 'react-native';
import { Dimensions } from 'react-native';
import GlobalState from '../../../assets/GlobalState'; // require instead?

const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height
//can this handle resizing

// user is web display function in stylesheet?
// GlobalState.isWebDisplay

var globalState = require('../../../assets/GlobalState')

var webContainerWidth = windowWidth * 0.6 
var containerWidth = globalState.isWebDisplay ? webContainerWidth : windowWidth
console.log(containerWidth)
console.log(globalState.isWebDisplay)
	
export var styles = StyleSheet.create({
	scrollView: {
		backgroundColor: "#FFF",
		height: '100%',
		width: '100%',
	},
	scrollViewContentContainer: {
		alignItems:'center'
	},
	text: {
		minHeight: windowHeight * 0.1,
		flex: 1,
		marginHorizontal: windowWidth * 0.04,
		fontSize: 22,
		maxWidth: containerWidth,
		// backgroundColor: '#000',
	},
	textContainer: {
		flex: 1,
		minWidth: containerWidth,
		maxWidth: containerWidth,
		minHeight: windowHeight * 0.1,
		backgroundColor: '#FFF',
		marginBottom: 0.05* windowHeight
		// backgroundColor: '#000',
	},
	image: {
        flex: 1,
		marginBottom: windowHeight * 0.01,
		width: '100%',
		minWidth: '100%',
        minHeight: windowHeight * 0.04,
		// maxHeight: windowHeight * 0.4,
		maxWidth: '100%',
		// backgroundColor: '#000'
	},
	videoContainer: {
		flex: 1,
		minWidth: windowWidth * 0.5,
		minHeight: windowHeight * 0.4,
		backgroundColor: '#FFF',
		marginBottom: 0.05 * windowHeight,
		// height: 500
		// backgroundColor: '#FF0'
		// alignItems: "center",
		// backgroundColor: '#000000',
		// flex: 0,
		// width: windowWidth,
		// minHeight: windowHeight * 0.5
	},
	WebView: {
		// margin: 20,
		minWidth: containerWidth,
		maxWidth: containerWidth,
		minHeight: 9/16 * containerWidth,
		maxHeight: 9/16 * containerWidth	
	},
	AzureView: {
		// minWidth: containerWidth,
		// maxWidth: containerWidth,
		minWidth: '100%',
		maxHeight: '100%',
		minHeight: 0.3 * windowHeight, // only for web!!!!
		maxHeight: '100%',
		// minHeight: 9/16 * containerWidth,
		// maxHeight: 9 / 16 * containerWidth,	
		backgroundColor: '#000',
	},
	scrollView:{
		minHeight: 0.7 * windowHeight,
		minWidth: windowWidth,
		// alignItems: 'center',
		backgroundColor: '#FFF'
	}
});