//from  https://stackoverflow.com/questions/33628677/react-native-responsive-font-size

import { Dimensions, Platform, PixelRatio } from 'react-native';

const windowWidth = Dimensions.get('window').width
// const windowHeight = Dimensions.get('window').height

// calibrate (360 worked for android 720p)
//  720 works on desktop
const scale = windowWidth / 360

export function adjustFontSize(unscaledSize) {
    // need more info
    return unscaledSize
    // const newSize = unscaledSize * scale
    // if (Platform.OS === 'ios') {
    //     return Math.round(PixelRatio.roundToNearestPixel(newSize))
    // } else {
    //     return Math.round(PixelRatio.roundToNearestPixel(newSize)) - 2
    // }
}