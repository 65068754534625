import { StyleSheet } from 'react-native';
import { Dimensions } from 'react-native';

import { primaryColour } from '../../globalColours'
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';
import { isPortraitView } from '../../../utils/isPortraitView'

const portraitView = isPortraitView()

const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height

export var styles = StyleSheet.create({
	listContainer: {
		// maxHeight: '100%'
		// height: '100%'
		// maxHeight: 100,
		// flex: 1,
		borderRadius: 5,
		// minHeight
		// backgroundColor: primaryColour

	},
	background: {
		// height: '100%',
		// marginBottom: 0.1 * windowHeight,
		minHeight: windowHeight* 0.4,
		maxHeight: windowHeight * 0.6,
		// width: '100%',
		marginHorizontal: 0,
		// flex: 1,
		// width: '100%',
		// minHeight: windowHeight * 0.8,
		// backgroundColor: '#FF0',
		// opacity: 0.8
	},

	loadingText: {
		// marginRight: windowWidth * 0.06,

		// top: 100,
		// minHeight: 0.1,
		// maxHeight: 0.1,
		// height: 100,
		// left: windowWidth * 0.45
		color: '#FFF',
		opacity: 0.86,
		marginBottom: windowHeight * 0.02,
	},
	loadingCircle: {
		// position: 'absolute',
		// top: windowHeight * 0.32,
		// left: windowWidth * 0.37,
		flex: 1,
		minWidth: portraitView ? hp(15) : hp(30),
		minHeight: portraitView ? hp(15) : hp(10),
		borderRadius: hp(10),
		// top: 400,
		justifyContent: "center",
		alignItems: 'center',
		backgroundColor: primaryColour
		// width: windowWidth * 0.225,
		// width: windowWidth * 0.225,
		// minHeight:  0.1 * windowHeight,
		//    maxHeight: 0.1 * windowHeight,

	},
	loadingContainer: {
		position: "absolute",
		width: '100%',
		left: 0,
		top: 0.2 * windowHeight,
		// backgroundColor: '#000',
		justifyContent: "center",
		alignItems: 'center',
	}
});

// use start en end radius for the smooth

