import { StyleSheet } from 'react-native';
import { Dimensions } from 'react-native';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';
import {isPortraitView} from '../../../utils/isPortraitView'

const portraitView = isPortraitView()

const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height
//can this handle resizing


const cardsContainerWidth = (windowWidth / windowHeight > 1) ? windowHeight * 1.2 : windowWidth



export var styles = StyleSheet.create({
	cardsContainer: {
		flex: 0.6,
		flexDirection: 'column',
		flexWrap: 'wrap',
		minHeight: windowHeight * 0.6,
		shadowOffset: {
			width: 0,
			height:windowHeight * 0.002
		},
		// marginBottom: windowHeight * 0.02,
	},
	cardView: {
		flex: 1,
		marginHorizontal: 0,
		shadowOffset: {
			width: 0,
			height:windowHeight * 0.3,
			//scale these
		},
	},
	cardStyle: {
		marginHorizontal: 0,
		marginTop: 0,
		padding: 0,
		// height: windowHeight * 0.3,
		flex: 1,
	},
	cardImageStyle: {
		marginTop: 0,
		marginHorizontal: 0,
		padding: 0,
		flex: 1,
		height: windowHeight * 0.2,
	},
	flexRow: {
        flex: 1,
		flexDirection: 'row',
		flexWrap: 'wrap',
		// height: windowHeight * 0.3,
		// marginBottom: windowHeight * 0.02,
		minHeight:  windowHeight * 0.43,
		maxHeight: windowHeight * 0.43,
		// backgroundColor: "#000",
		overflow: 'hidden',
		flexShrink: 1,
		flexBasis: 0,
	},
	wideContainer: {
		justifyContent: 'center',
		flexDirection: 'row',
		// alignItems: 'center',
		minWidth: windowWidth,
		minHeight: '100%',
		// height:  windowHeight,
		// backgroundColor: '#000',

	},
	cardsContainerContent: {
		// backgroundColor: '#000',
		justifyContent: portraitView?'flex-start': 'center',
		// alignItems: 'space-between',
		flexDirection:  'row',
		flexWrap: 'wrap',
		minWidth: cardsContainerWidth,
		maxWidth: cardsContainerWidth,
	},
	dashboardContainer: {
		backgroundColor: "#FFF",
		height: '100%',
		width: '100%',
	}
});

// use start en end radius for the smooth

var cardTouchableOpacity = {
	// width: windowWidth * 0.45,
	// flex: 1,
	// marginTop: windowHeight * 0.02,
	// // marginHorizontal: windowWidth * 0.02,
	// marginBottom: windowHeight * 0.02,
	// minHeight: windowHeight * 0.38,
	// maxHeight: windowHeight * 0.38,
	// marginBottom: 10,
	// //needs space for shadow?
	// shadowOffset: {
	// 	width: 0,
	// 	height: windowHeight * 0.002
	// 	//scale these
	// },
	// elevation: windowHeight * 0.005,
	// shadowRadius: windowHeight * 0.004,
	// shadowOpacity: 0.5,
};


// const gameCardStyleJson = { ...cardTouchableOpacity, backgroundColor: '#FF5454' }
const gameCardStyleJson = { ...cardTouchableOpacity}

export const gameCardStyle = StyleSheet.create({
	cardTouchableOpacity: gameCardStyleJson
});

// const rewardsCardStyleJson = { ...cardTouchableOpacity, backgroundColor: '#FF5488' }
const rewardsCardStyleJson = { ...cardTouchableOpacity }
export const  rewardsCardStyle = StyleSheet.create({
	cardTouchableOpacity: rewardsCardStyleJson
});

// const leaderboardCardStyleJson = { ...cardTouchableOpacity, backgroundColor: '#FF924A' }
const leaderboardCardStyleJson = { ...cardTouchableOpacity}
export const leaderboardCardStyle = StyleSheet.create({
	cardTouchableOpacity: leaderboardCardStyleJson
});