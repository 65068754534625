import { Text, Image, View, ScrollView, Dimensions, BackHandler, Alert, Platform } from 'react-native';
import React, { useState, useContext } from "react";
import { ListItem, Divider } from 'react-native-elements';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';

import { styles, gameCardStyle, rewardsCardStyle, leaderboardCardStyle } from '../styles/Standard/screens/Dashboard'; //rename
import { images, trophies } from '../assets/images';
import { CardButton } from '../components/common/CardButton';
import { cardColours } from '../styles/globalColours';
import { getElement, getFBIdToken, Firebase } from '../firebase.config';
import { LoadingIndicator } from '../components/common/LoadingIndicator';
import {
	GAME_CARD_TITLE, GAME_CARD_DESCRIPTION, REWARDS_CARD_TITLE,
	REWARDS_CARD_DESCRIPTION, LEADERBOARD_TITLE, LEADERBOARD_CARD_DESCRIPTION, LEARNING_CENTER_TITLE, LEARNING_CENTER_DESCRIPTION
} from '../assets/text/eng';
import { checkProfileFields } from '../utils/checkProfileFields';

import { ThemeContext } from '../App'; // put in another file (not app to remove circular dep)



const windowHeight = Dimensions.get('window').height
const windowWidth = Dimensions.get('window').width

export function Dashboard({ navigation }, props) {
	const [isLoading, setIsLoading] = useState(true)
	const [departmentInfo, setDepartmentInfo] = useState()
	const [recentAchivementsList, setRecentAchievementsList] = useState([])

	const [isProfileLoaded, setIsProfileLoaded] = useState(false)
	const [backgroundImageUrl, setBackgroundImageUrl] = useState()
	const [checkedLeaderboard, setCheckedLeaderboard] = useState(false)
	const [madeDepartmentRequest, setMadeDepartmentRequest] = useState(false)
	const [requestCount, setRequestCount] = useState(0)

	const globalState = require('../assets/GlobalState')
	const globalColours = require('../styles/globalColours')

	var gameState = require('../assets/GameState')
	gameState.state.afterGameInfo = []
	gameState.state.uncompletedCategories = []
	gameState.state.categoryScores = {}
	gameState.isLeaderboardUpdated = true
	gameState.gameInfo.maximumGameScore = undefined
	globalState.DBPath.gameId = undefined
	globalState.DBPath.gameCategoryId = undefined
	globalState.DBPath.learningSubTopicId = undefined
	globalState.DBPath.trainingMaterialTopicId = undefined

	const context = useContext(ThemeContext)

	globalState.isGameLeaderboard = false

	// console.log(globalColours.primaryColour)

	// if (checkedLeaderboard == false) {
	// 	if (globalState.DBPath.companyId != undefined && globalState.DBPath.departmentId != undefined) {
	// 		var path = ['Companies', globalState.DBPath.companyId, 'Departments', globalState.DBPath.departmentId, 'Leaderboard', 'Leaderboard']
	// 		getElement(path).get().then(function (doc) {
	// 			if (doc == undefined || doc.data() == undefined) {
	// 				var path2 = ['Companies', globalState.DBPath.companyId, 'Departments', globalState.DBPath.departmentId, 'Leaderboard', 'Leaderboard']
	// 				getElement(path2).set({
	// 					users: {}
	// 				})
	// 			}
	// 		})
	// 	} else {
	// 		console.log("Company Id or department Id undefined")
	// 		navigation.replace('login')
	// 	}
	// 	setCheckedLeaderboard(true)
	// }
	// temp until  leaderboard collection and doc are added in admin

	if (Platform.OS != 'android' && Platform.OS != 'ios') {
		if (windowWidth / windowHeight > 0.9) {
			globalState.portraitView = false
		} else {
			globalState.portraitView = true
		}
	} else {
		globalState.portraitView = true
	}



	if (departmentInfo == undefined && madeDepartmentRequest == false) {
		// const globalState = require('../assets/GlobalState')
		if (globalState.DBPath.companyId != undefined && globalState.DBPath.departmentId != undefined) {
			var path = ['Companies', globalState.DBPath.companyId] // primary and secondary colour set at this time might not update sylesheets
			getElement(path).get().then(function (doc) {
				if (doc != undefined && doc.data() != undefined) {
					if (doc.data().colors != undefined && doc.data().colors['0'] != undefined) {
						const newPrimaryColor = doc.data().colors['0']
						if (typeof newPrimaryColor == "string" && newPrimaryColor[0] === "#") {
							globalColours.primaryColour = newPrimaryColor
							context.setColor(globalColours.primaryColour)
							setBackgroundImageUrl(doc.data().backgroundImgUrl)
						}
						if (doc.data().logoUrl != undefined) {
							globalState.companyLogoUrl = doc.data().logoUrl
							
						} else {
							console.log("CompanyLogoUndefined")
						}
					}

					// if (doc.data().colors['1'] != undefined) {
					// 	const newPrimarySideColor = doc.data().colors['1']
					// 		if (typeof newPrimarySideColor == "string" && newPrimarySideColor[0] === "#") {
					// 			globalColours.primarySideColor = newPrimarySideColor
					// 		}
					// }

				} else {
					console.log("Document not found");
					if (requestCount < 5) {
						setRequestCount(requestCount + 1)
						setMadeDepartmentRequest(false)

					} else {
						console.log("maximum dashboard requests reached")
					}
				}
			})
			setMadeDepartmentRequest(true)

			console.log("request department")
			var path = ['Companies', globalState.DBPath.companyId, 'Departments', globalState.DBPath.departmentId]
			getElement(path).get().then(function (doc) {
				if (doc != undefined) {
					setDepartmentInfo(doc.data())
				} else {
					console.log("Document not found");
				}
				// if (checkProfileFields(globalState.userProfile)) {
				setIsLoading(false)
				// }
			}).catch(function (error) {
				// console.log(error)
				console.log("Error getting department doc", error);
				Alert.alert("Error getting department doc", error.message)
				navigation.replace('login')
			});
		} else {
			console.log('An important property for the firestore path is not set in globalState.DBPath.')
			navigation.replace('login')
			// navigation.goBack()
		}

		if (globalState.isLeaderboardFunctionReady == undefined) {
			try {
				console.log("leaderboardFunction")
				var isLeaderboardFunctionReady = Firebase.functions().httpsCallable('leaderboardFunctionReady');
				isLeaderboardFunctionReady({}).then(function (response) {
					if (response != undefined && response.data != undefined && response.data == true) {
						globalState.isLeaderboardFunctionReady = true
					} else {
						globalState.isLeaderboardFunctionReady = false
					}
				})
			} catch (e) {
				console.log("leaderboardfunctionready problem")
				globalState.isLeaderboardFunctionReady = false
				console.log(e)
			}
		}
	}

	// console.log("todo limit initialiseAchivement requests")
	if (recentAchivementsList.length < 1 && madeDepartmentRequest == false) { initialiseRecentAchievements() }

	if (recentAchivementsList == undefined || recentAchivementsList.length < 1 && globalState.userProfile != undefined && globalState.userProfile.achievements != undefined && globalState.userProfile.achievements.recentAchievements) {
		setRecentAchievementsList(objectToArray(globalState.userProfile.achievements.recentAchievements))
	}

	const cardBackgroundColours = ["#FF5454", "#549EFF", "#FF924A", globalColours.primaryColour];
	const cardShadowColours = ['#EB3C3C', '#5378FF', '#FF6500', globalColours.primarySideColour];

	// function loadProfile() {
	// 	if (globalState.DBPath.companyId != undefined && globalState.DBPath.departmentId != undefined ) {
	// 		console.log("req achi")
	// 		var path = ['Companies', globalState.DBPath.companyId, 'Users', globalState.DBPath.userId]
	// 		getElement(path).get().then(function (doc) {
	// 			if (doc.exists) {
	// 				const profileData = doc.data()
	// 				globalState.userProfile = profileData

	// 				setRecentAchievementsList(objectToArray(recentAchievementsObject))
	// 				// setIsProfileLoaded(true)
	// 			} else {
	// 				console.log("Document not found");
	// 			}
	// 		}).catch(function (error) {
	// 			console.log("Error getting doc", error);
	// 		});
	// 	} else {
	// 		console.log("Company or department id not defined")
	// 		navigation.replace('login')
	// 	}
	// }

	// if (isProfileLoaded == false && checkProfileFields(globalState.userProfile) && madeDepartmentRequest == false) {
	// 	loadProfile()
	// } else {
	// 	if (isProfileLoaded == false) {
	// 		setIsProfileLoaded(true)
	// 	}
	// 	if (isLoading == true) {
	// 		setIsLoading(false)
	// 	}
	// }

	function renderTierIcon(tier) {
		if (tier != undefined) { // make sure refreshes between pages
			// console.log("card is tierd")
			// console.log(props.tier)
			var iconImage = ""
			if (tier == 0) {
				iconImage = trophies.gold

			} else if (tier == 1) {
				iconImage = trophies.silver

			} else if (tier == 2) {
				iconImage = trophies.bronze

			} else if (tier == -1) {
				iconImage = trophies.grey
			} else {
				return null
			}
		} else {
			return null
		}
		return (
			<Image
				disabled={tier == undefined || iconImage == undefined}
				style={styles.tieredAchievementImageStyle}
				activeOpacity={1}
				resizeMode="contain"
				source={iconImage} />
		)
	}

	function renderAppCategories() {

		// disable cards until profile loaded
		return (
			<View style={styles.cardsContainerContent}>
				<CardButton destination='games_list' title={GAME_CARD_TITLE} description={GAME_CARD_DESCRIPTION} disabled={!isProfileLoaded} image={images[10]} shadowColour={cardColours[0].shadow} backgroundColour={cardColours[0].background} customStyle={gameCardStyle} />
				<CardButton destination='rewards' title={REWARDS_CARD_TITLE} description={REWARDS_CARD_DESCRIPTION} disabled={!isProfileLoaded} image={images[11]} shadowColour={cardColours[1].shadow} backgroundColour={cardColours[1].background} customStyle={rewardsCardStyle} />
				<CardButton destination='leaderboard' title={LEADERBOARD_TITLE} description={LEADERBOARD_CARD_DESCRIPTION} disabled={!isProfileLoaded} image={images[12]} shadowColour={cardColours[2].shadow} backgroundColour={cardColours[2].background} customStyle={leaderboardCardStyle} />
				<CardButton destination='learning_center_topics' title={LEARNING_CENTER_TITLE} description={LEARNING_CENTER_DESCRIPTION} disabled={!isProfileLoaded} image={images[13]} shadowColour={cardColours[3].shadow} backgroundColour={cardColours[3].background} customStyle={leaderboardCardStyle} />
			</View>
		)
	}

	var imageUri = (backgroundImageUrl != undefined) ? { uri: backgroundImageUrl } : ""
	// const imageUri = {uri: "https://firebasestorage.googleapis.com/v0/b/test-fddd5.appspot.com/o/company_images%2Fd8188881-1383-4556-8468-af356d64b82e?alt=media&token=8bc0e24d-1228-4b02-b226-e7cc1d06af43"}
	// const imageUri =""


	function renderRecentAchievements() {
		if (recentAchivementsList != undefined && recentAchivementsList.length > 0) {


			return (
				<View style={styles.wideContainer}>
					<View style={styles.recentAchivementsContainer}>
						<View style={styles.recentAchivementsTextContainer}>
							<Text style={styles.recentAchivementsText}>Recent Achievements</Text>
						</View>
						<View style={styles.recentAchivementsList}>
							{
								recentAchivementsList.map((l, i) => (
									<View key={'view' + l.name + i.toString()}>
										<ListItem
											key={l.name + i.toString()}
											leftAvatar={{ source: (l.imgUrl != undefined && l.imgUrl.length > 0) ? { uri: l.imgUrl } : images[Math.floor(Math.random() * (images.length - 1))], size: hp(5) }} // works on android
											title={l.name}
											// title={l.tier}
											titleStyle={{ color: '#000', opacity: 0.87, marginLeft: globalState.isWebDisplay? wp(1) : wp(3), fontSize: hp(3) , minWidth: '100%'}}
											containerStyle={{ minWidth: globalState.isWebDisplay? '100%':   windowWidth * 0.9, marginBottom: 0, minHeight: 0.05 * windowHeight, maxHeight: 0.5 * windowHeight, padding: hp(0.4) }}
											rightElement={renderTierIcon(l.tier)}
										/>
										<Divider key={'div ' + l.name + (i).toString()} style={{ marginLeft: 0 }} />
									</View>
								))
							}

						</View>
					</View>
				</View>
			)
		} else {
			return null
		}
	}

	return (

		<ScrollView >

			<View style={styles.dashboardContainer}>

				<Image source={imageUri} style={styles.backgroundImage}></Image>
				<Text style={styles.departmentTitle}>{(departmentInfo != undefined) ? departmentInfo.name : ""}</Text>
				<Text style={styles.departmentDescription}>{(departmentInfo != undefined) ? departmentInfo.description : ""}</Text>
				<View style={styles.wideContainer}>
					{renderAppCategories()}
				</View>
				<LoadingIndicator isLoading={isLoading} />


				{renderRecentAchievements()}
			</View>
		</ScrollView>
	);

	function initialiseRecentAchievements() {
		const globalState = require('../assets/GlobalState')
		if (globalState.userProfile != undefined) {
			const profileData = globalState.userProfile
			// var path = ['Companies', globalState.DBPath.companyId, 'Users', globalState.DBPath.userId]
			// getElement(path).get().then(function (doc) {
			// 	if (doc.exists) {
			// 		const profileData = doc.data()
			// 		globalState.userProfile = profileData
			// 		if (profileData.achievements != undefined && profileData.achievements.recentAchievements != undefined) {
			// 			const recentAchievementsObject = profileData.achievements.recentAchievements
			// 			setRecentAchievementsList(objectToArray(recentAchievementsObject))
			// 		} else {
			// 			console.log("recentAchievements undefined")
			// 		}

			// 	} else {
			// 		console.log("Document not found");
			// 	}
			// }).catch(function (error) {
			// 	console.log("Error getting doc", error);
			// });

			if (profileData.achievements != undefined && profileData.achievements.recentAchievements != undefined) {
				const recentAchievementsObject = profileData.achievements.recentAchievements
				setRecentAchievementsList(objectToArray(recentAchievementsObject))
			}
		} else {
			console.log("userprofile not set")
			navigation.replace('login')
		}
	}

	function objectToArray(object) {
		const objectKeys = Object.keys(object)
		var array = []
		for (let objectKeyIndex = 0; objectKeyIndex < objectKeys.length; objectKeyIndex++) {
			array.push(object[objectKeys[objectKeyIndex]])
		}
		return array
	}
}