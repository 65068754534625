
import { StyleSheet, Text, View, Image } from 'react-native';
import React, { useState } from "react";
import { useNavigation } from '@react-navigation/native';

import { styles } from '../../styles/Standard/common/CardButton.style';
import { TouchableOpacity } from 'react-native-gesture-handler';

import { Dimensions } from 'react-native';
import { Icon, Avatar } from 'react-native-elements'

import { trophies } from '../../assets/images'

const windowHeight = Dimensions.get('window').height
const windowWidth = Dimensions.get('window').width

export function CardButton(props) {

    const navigation = useNavigation();
    const [iconImage, setIconImage] = useState(undefined)
    // const customStyle = (props.customStyle != undefined) ? Object.assign(styles, props.customStyle) : styles;
    // const customStyle = (props.customStyle != undefined) ? StyleSheet.compose(styles, props.customStyle) : styles;

    // ------------------------------------------

    // or use Stylesheet.compose
    const destination = (props.destination != undefined) ? props.destination : '';
    const imageHeightFactor = (props.imageHeight != undefined) ? props.imageHeight : 0.1; // as a factor window height
    // const imageHeightFactor = (props.imageHeight != undefined) ? props.imageHeight : 0.06; // as a factor window height
    var disabled = false;
    const isReward = (props.isReward == true) ? true : false

    const customShadowStyle = StyleSheet.create({
        cardTouchableOpacity: {
            backgroundColor: props.shadowColour,
            opacity: (!props.isTransparent) ? 1 : 0.4,
        }
    });

    const cardContainerStyle = StyleSheet.create({
        cardContainerStyle: {
            backgroundColor: props.backgroundColour,
            // backgroundColor: '#000',
        }
    });

    const cardImageStyle = StyleSheet.create({
        cardImageStyle: {
            backgroundColor: props.backgroundColour,
            minHeight: windowHeight * imageHeightFactor,
            maxHeight: windowHeight * imageHeightFactor,
            // backgroundColor: '#000',
        }
    });

    if (destination === '') { //.isEmpty later // can put this in the onpress too
        disabled = true;
    }

    if (iconImage == undefined && props.tier != undefined) { // make sure refreshes between pages
        // console.log("card is tierd")
        // console.log(props.tier)
        if (props.tier == 0) {
            setIconImage(trophies.gold)

        } else if (props.tier == 1) {
            setIconImage(trophies.silver)

        } else if (props.tier == 2) {
            setIconImage(trophies.bronze)

        } else if (props.tier == -1) {
            setIconImage(trophies.grey)
        }
    }

    //need to move onPress logic outside to make more general, and add  onpress parameter

    return (
        <TouchableOpacity onPress={() => {
            if (props.title) {
                var globalState = require('../../assets/GlobalState');
                var newScreenTitle = props.title;
                globalState.currentScreenTitle = newScreenTitle;
            }
            if (props.collection && props.document) {
                var globalState = require('../../assets/GlobalState');
                var documentName = props.document;
                globalState['DBPath'][props.collection] = documentName;
            }
            if (destination != '' || destination.length > 0) {
                navigation.navigate(destination, props.navigationData); //what f navigationdata does not exists? thne add separate navigate
            }
        }
        } disabled={disabled} style={[styles.cardTouchableOpacity, props.customStyle.cardTouchableOpacity, customShadowStyle.cardTouchableOpacity]}>
            <View style={[styles.cardContainerStyle, cardContainerStyle.cardContainerStyle]}>
                {/* Or rather put in below component */}
                <View style={styles.cardView}>
                    <Image
                        style={styles.cardImageStyle}
                        activeOpacity={0.9}
                        resizeMode="cover"
                        source={props.image} />
                    <View style={styles.titleRow}>
                        <View>
                            <Text style={styles.cardHeaderTextStyle}>{props.title}</Text>
                            <Text style={styles.cardDescriptionTextStyle}>{props.description}</Text>
                        </View>
                        <Icon disabled={!isReward} disabledStyle={{ display: 'none' }} name='monetization-on' color='#FFBF00' iconStyle={{ marginRight: windowWidth * 0.02 }} />
                        {/* <Icon disabled={props.tier == undefined || iconImage==undefined} disabledStyle={{ display: 'none' }} name='monetization-on' color='#FFBF00' iconStyle={{ marginRight: windowWidth * 0.02 }} /> */}
                        {/* <Avatar disabled={props.tier == undefined || iconImage == undefined} disabledStyle={{ display: 'none' }} source={iconImage} /> */}
                        <Image
                            disabled={props.tier == undefined || iconImage == undefined}
                            style={styles.tieredAchievementImageStyle}
                            activeOpacity={1}
                            resizeMode="contain"
                            source={iconImage} />
                        {/* FFBF00 */}
                    </View>
                    {/* <View style={styles.subTitleTextContainer}> */}
                    {/* <Text style={styles.cardDescriptionTextStyle}>{props.description}</Text> */}

                    {/* </View> */}
                </View>
            </View>
        </TouchableOpacity>
    )
};