import { ScrollView, View, Dimensions, Alert } from 'react-native';
import React, { useState } from "react";
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';
import { ListItem } from 'react-native-elements';

import { styles } from '../styles/Standard/screens/LearningSubTopics.styles'; //rename
import { StyledSubTitleButton } from '../components/common/StyledSubTitleButton';
import { primaryColour, primarySideColour } from '../styles/globalColours';
import { getElement } from '../firebase.config';
import { LoadingIndicator } from '../components/common/LoadingIndicator';
import { checkProfileFields } from '../utils/checkProfileFields'
import { EmptyPageBackground } from '../components/common/EmptyPageBackground'

const windowHeight = Dimensions.get('window').height
const windowWidth = Dimensions.get('window').width

export function Messages({ navigation }, props) {
	var globalState = require('../assets/GlobalState')

	globalState.currentScreenTitle = "Communication";

	const isTesting = false
	if (isTesting) {
			globalState.DBPath.companyId = 'aCompanyName'
		globalState.DBPath.departmentId = 'FinanceDepartment'
		globalState.DBPath.trainingMaterialTopicId = 'KIAPicanto'
	}
	
	navigation.setOptions({ title: globalState.currentScreenTitle })
	const [isLoading, setIsLoading] = useState(true)
	const [isWaitingForResponse,setIsWaitingForResponse] = useState(false)

	const [messageList, setMessageList] = useState([])
	// const [topicInfo, setTopicInfo] = useState([])
	const [areasCovered, setAreasCovered] = useState([])
	const [triggerRefresh, setTriggerRefresh] = useState(false)
	const [examGameId, setExamGameId] = useState(undefined)

	const [numberOfFetchTries, setNumberOfFetchTries] = useState(0)

	// make sure errors wont cause frequest re-requiesting

	if (globalState.DBPath.companyId == undefined || globalState.DBPath.companyId.length < 1 || globalState.DBPath.departmentId == undefined || globalState.DBPath.departmentId.length < 1) {
		navigation.replace('login')
		console.log("dbpath not set in messages")
	}


	if ( (messageList == undefined || messageList.length == 0) && isWaitingForResponse == false && numberOfFetchTries < 3) { // do request seaprately?
		// getAndSetTopic()
			retrieveMessages()
	} else {
		if (isLoading == true && isWaitingForResponse == false && numberOfFetchTries > 2 && (messageList == undefined || messageList.length == 0)) {
			// console.log("numberOfFetchTries")
			setIsLoading(false)
		}
	}
	
	var isEmptyPage = false
	if (isLoading == false && (messageList == undefined || messageList.length < 1)) {
		isEmptyPage = true
	}


	function retrieveMessages() {
		if (globalState.DBPath.companyId != undefined && globalState.DBPath.companyId.length> 0 &&  globalState.DBPath.departmentId != undefined &&  globalState.DBPath.departmentId.length>0 ) {
			var path = ['Communication']
			setIsWaitingForResponse(true)
			getElement(path)
				
				// .where('company', 'in', [globalState.DBPath.companyId, ""]) // for app-wide, 2 'in' filter not supported atm.
				.where('company', '==', globalState.DBPath.companyId)
				.where('department', 'in', [globalState.DBPath.departmentId, ""]) //for both companywide and departmentwide
				// .where('department', '==', globalState.DBPath.departmentId) //for both companywide and departmentwide
				.orderBy('sendDate', 'desc').get().then(function (docs) {
					if (docs != undefined && docs.docs != undefined) {
					// console.log(docs.docs[0])
					var newMessageList = []
					docs.docs.map(doc => {
						var newMessage = doc.data()
						newMessage['documentId'] = doc.id
						// if message read
						// if (globalState.userProfile != undefined && globalState.userProfile.learningCentreProgress != undefined &&
						// 	globalState.userProfile.learningCentreProgress.subTopicsRead != undefined &&
						// 	globalState.userProfile.learningCentreProgress.subTopicsRead.indexOf(globalState.DBPath.trainingMaterialTopicId + "_" + newSubTopic.subTitle) > -1) {
						// 	newSubTopic.icon = 'done'
						// } else {
						// 	newSubTopic.icon = 'check-box-outline-blank'
						// }
						newMessageList.push(newMessage)
						// console.log(newSubTopic.media)
					})
					setMessageList(newMessageList)
					setIsLoading(false)
					} else {
						setIsWaitingForResponse(false)
					console.log("Document not found");
				}
			}).catch(function (error) {
				console.log("Error getting doc", error);
				setNumberOfFetchTries(numberOfFetchTries + 1)
				setIsWaitingForResponse(false)
			});
		} else {
			console.log(globalState.DBPath.companyId + " " +globalState.DBPath.departmentId + " " +  globalState.DBPath.trainingMaterialTopicId)
			setIsWaitingForResponse(false)
			Alert.alert("Context information not set.")
		}
	}

	// not sure about scope of gameId

	function renderMessageList() {
		// setHasIconChanged(false)
		const globalState = require('../assets/GlobalState')
		return (messageList.map((message, i) => (
			<ListItem
				key={i}
				// leftAvatar={{ source: { uri: message.imageUrl }, size:hp(5) }}
				leftIcon={{ name: message.icon, color: primaryColour, size: hp(4) }}
				// leftIcon={{ name: 'check', color: primaryColour, size: hp(4) }}   
				contentContainerStyle={{ marginLeft: 0.03 * windowWidth }}
				// containerStyle={{ width: globalState.portraitView ? windowWidth :0.5* windowHeight * 1.0, maxHeight: windowHeight * 0.15, marginVertical: 0 }}
				containerStyle={{ width: globalState.portraitView ? windowWidth : '100%', maxHeight: windowHeight * 0.15, marginVertical: 0 }}
				title={message.title}
				titleStyle={{ fontSize: hp(3) }}
				subtitle={message.subTitle}
				subtitleStyle={{ fontSize: hp(2) }}
				badge={{ value:  message.sendDate!= undefined? message.sendDate.toDate().toString().substring(4,15) : "", textStyle: { color: '#000', opacity: 0.6, fontWeight: '500', fontSize: hp(2) }, badgeStyle: { backgroundColor: '#FFF' }, containerStyle: { marginTop: 0 } }}
				onPress={() => {
					var globalState = require('../assets/GlobalState');
					if (message.title) {
						var newScreenTitle = message.title;
						globalState.currentScreenTitle = newScreenTitle;
					}
					message.icon = 'done'
					setTriggerRefresh(!triggerRefresh)

					// For setting read progress permanently
					// var newUserProfile = globalState.userProfile
					// // console.log("\n neuserprofile")
					// // console.log(newUserProfile)
					// if (newUserProfile != undefined) {
					// 	if (newUserProfile.learningCentreProgress == undefined) {
					// 	}
					// 	if (newUserProfile.learningCentreProgress.messagesRead == undefined) {
					// 		newUserProfile.learningCentreProgress.messagesRead = []
					// 	}
						
						// newUserProfile.learningCentreProgress.messagesRead.push(globalState.DBPath.trainingMaterialTopicId + "_" + message.subTitle)
						// if (checkProfileFields(newUserProfile)) {
						// 	globalState.userProfile = newUserProfile
						// 	var path = ['Companies', globalState.DBPath.companyId, 'Users', globalState.DBPath.userId]
						// 	getElement(path).set(newUserProfile)
						// } else {
						// 	// Alert.alert("Profile fields not valid. Not Updating profile.")
						// }
					// } else {
					// 	console.log("Profile not defined")
					// }
					globalState.currentScreenTitle = message.title
					navigation.navigate('message', { message: message })
				}
				}
			/>
		)))
	}

	return (
		<View>
			<ScrollView style={styles.background}>
			<EmptyPageBackground isEmpty={isEmptyPage}/>
				<View style={styles.wideContainer}>
					<View style={styles.cardsContainerContent}>
						<View style={{ marginVertical: 0, width: '100%' }}>{renderMessageList()}</View>
						{/* {renderExamButton((examGameId != undefined) ? examGameId : undefined)} */}
					</View>
				</View>
			</ScrollView>
			<LoadingIndicator isLoading={isLoading} />
		</View>
	);
}