import { Dimensions, View, Alert} from 'react-native';
import React, { useState } from "react";
import { ListItem } from 'react-native-elements';
import { heightPercentageToDP } from 'react-native-responsive-screen';

import { styles } from '../styles/Standard/screens/GameCategories.styles'; 
import { getElement } from '../firebase.config';
import { LoadingIndicator } from '../components/common/LoadingIndicator';

import {EmptyPageBackground} from '../components/common/EmptyPageBackground'

const windowHeight = Dimensions.get('window').height
const windowWidth = Dimensions.get('window').width


//  if this is not killed when navigating to game, the prvious categorylist survives and it wont get a new list of catgories
export function GameCategories({ navigation }, props) {
	const [gameCategories, setGameCategories] = useState([])
	const [gameCategoriesDocumentIds, setGameCategoriesDocumentIds] = useState([])
	const [isLoading, setIsLoading] = useState(true)
	const [haveMadeRequest, setHaveMadeRequest] = useState(false)
	const globalState = require('../assets/GlobalState')
	var gameState = require('../assets/GameState')  
	navigation.setOptions({ title: globalState.currentScreenTitle })

	// console.log("\n\nCategories")
	// console.log(globalState.DBPath.gameId)

	if (gameCategories != undefined && gameCategories.length < 1 && haveMadeRequest == false) {
		setGameCategoriesDocumentIds([])
		getGameCategories()
	}

	var isEmptyPage = false
	if (isLoading == false && (gameCategories == undefined || gameCategories.length < 1)) {
		isEmptyPage = true
	}

	function getGameCategories() {
		if(globalState.DBPath == undefined || globalState.DBPath.companyId == undefined || globalState.DBPath.departmentId == undefined || globalState.DBPath.gameId == undefined ||
			globalState.DBPath.companyId.length < 1 || globalState.DBPath.departmentId.length < 1 || globalState.DBPath.gameId.length < 1) {
			console.log("dbpath item not set")
			navigation.replace('login')
				return null
		}
		var path = ['Companies', globalState.DBPath.companyId, 'Departments', globalState.DBPath.departmentId, 'Games', globalState.DBPath.gameId, 'Categories']
		// var path = ['Companies', 'cunMKmkGGJ9g2ReRrVBl', 'Departments','cB3EhsDlt6x7shAQEkeq', 'Games','08fNuPo89ZX5WB9Gkhqh', 'Categories']
		// console.log("gamecatPath")
		// console.log(path)
		setHaveMadeRequest(true)
		getElement(path).get().then(function (docs) {
			if (docs != undefined && docs.docs != undefined) {
				var topics = []
				docs.docs.map(doc => {
					var newTopic = doc.data()
					newTopic['documentId'] = doc.id
					topics.push(newTopic)
					var newCategoryDocumentIds = gameCategoriesDocumentIds
					newCategoryDocumentIds.push(newTopic['documentId']) //ensure deep copy
					setGameCategoriesDocumentIds(newCategoryDocumentIds)
				})
				setGameCategories(topics)
				setIsLoading(false)
			} else {
				console.log("Document not found");
			}
		}).catch(function (error) {
			console.log("Error getting doc", error);
		});
	}

	return (
		<View style={styles.background}>
			<View style={styles.wideContainer}>
				<View style={styles.cardsContainerContent}>
					{
						gameCategories.map((l, i) => (
							<ListItem
								key={i}
								// leftAvatar={{ source: { uri: l.imageUrl } , size:heightPercentageToDP(6)}}
								title={l.name}
								titleStyle={{fontSize: heightPercentageToDP(3)}}
								subtitle={(l.dueDate != undefined) ?l.subtitle +  '\n' + l.dueDate: l.subtitle}
								subtitleStyle={{ fontSize: globalState.portraitView?heightPercentageToDP(2.5):heightPercentageToDP(2), marginVertical: 0}}
								containerStyle={{width: '100%', padding: 0}}
								contentContainerStyle={{ marginLeft: 0.05 * windowWidth, width: '100%', paddingVertical:heightPercentageToDP(0.5), marginVertical:0 }}
								badge={{
									value: (l.timeToComplete != undefined && l.timeToComplete.length > 0) ? l.timeToComplete + " min" : "",
									textStyle: { color: '#000', opacity: 0.6, fontWeight: '500', fontSize: heightPercentageToDP(2) }, badgeStyle: { backgroundColor: '#FFF' }, containerStyle: { marginTop: 0 }
								}}
								onPress={() => {
									// console.log(l.questions)
									if (l.questions != undefined && Object.keys(l.questions).length > 0) {
										var categoryId = l.documentId;
										globalState['DBPath']['gameCategoryId'] = categoryId;
										// gameState.gameInfo.maximumGameScore = 0 // for when counting locally
										gameState.state.uncompletedCategories = gameCategoriesDocumentIds
										gameState.state.startTime = new Date()
										gameState.live = 0
										gameState.isLeaderboardUpdated = true
										gameState.maximumGameScore = 0
										setGameCategories([])
										navigation.replace('game')
									} else {
										Alert.alert("Category does not have questions.")
									}
								}}
								bottomDivider
							/>
						))
					}
				</View>
			</View>
			<LoadingIndicator isLoading={isLoading} />
			<EmptyPageBackground isEmpty={isEmptyPage}/>
		</View>
	);
}