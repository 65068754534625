import { StyleSheet } from 'react-native';
import { Dimensions } from 'react-native';
import { heightPercentageToDP  as hp, widthPercentageToDP as wp } from 'react-native-responsive-screen';
import { isPortraitView } from '../../../utils/isPortraitView'

const portraitView = isPortraitView()

const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height
//can this handle resizing

export var styles = StyleSheet.create({
	// scrollView: {
	// 	backgroundColor: "#FFF",
	// 	height: '100%',
	// 	// width: '100%',
		

	// },
	// text: {
	// 	minHeight: windowHeight * 0.4,
	// 	flex: 1,
	// 	marginHorizontal: windowWidth * 0.04,
	// 	fontSize: 22,
	// 	// backgroundColor: '#000',
	// },
	// image: {
    //     flex: 1,
    //     marginBottom: windowHeight * 0.01,
    //     minHeight: windowHeight * 0.4,
	// 	maxHeight: windowHeight * 0.4,
	// 	maxWidth: '100%'
	// },
	standardHeaderTitle: {
		fontSize: hp(4)
	},
	webContainer: {
		minWidth: portraitView? windowWidth: wp(60),
		maxWidth: portraitView? windowWidth: wp(60),
		height: '100%',
		// backgroundColor: '#000',
		flex: 1,
		// borderLeftWidth: 100
        // justifyContent: "center",
        // alignItems: "center",
	},
	fullContainer: {
		// borderRightWidth: 100,
		// marginRight: 100,
		maxWidth: '100%',
		minWidth: '100%',
		alignItems: "center",
		justifyContent: 'center',
		flexDirection: 'row',
		flex: 1,
		minWidth: windowWidth,
		maxWidth: windowWidth,
	},



	scrollView: {
		backgroundColor: "#FFF",
		height: '100%',
		width: '100%',
	},
	text: {
		minHeight: windowHeight * 0.1,
		flex: 1,
		marginHorizontal: windowWidth * 0.04,
		fontSize: 22,
		// backgroundColor: '#000',
	},
	image: {
        flex: 1,
		marginBottom: windowHeight * 0.01,
		width: '100%',
		minWidth: windowWidth,
        minHeight: windowHeight * 0.04,
		// maxHeight: windowHeight * 0.4,
		maxWidth: '100%',
		// backgroundColor: '#000'
	},
	videoContainer: {
		flex: 1,
		minWidth: windowWidth * 0.5,
		minHeight: windowHeight * 0.4,
		backgroundColor: '#FFF'
	},
	WebView: {
		// margin: 20,
		// minWidth: windowWidth,
		// minHeight: windowHeight * 0.1
	}
});