import { StyleSheet } from 'react-native';
import { Dimensions } from 'react-native';

const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height
//can this handle resizing

const cardsContainerWidth = (windowWidth / windowHeight > 1) ? 0.7 * windowHeight * 1.2 : windowWidth

export var styles = StyleSheet.create({
	list: {

	},
	background: {
		height: '100%',
		backgroundColor: '#FFF',
		// width: '100%',
		// minHeight: windowHeight * 0.8,
	},
	wideContainer: {
		justifyContent: 'center',
		flexDirection: 'row',
		// alignItems: 'center',
		minWidth: windowWidth,
		minHeight: '100%',
		// height:  windowHeight,
		// backgroundColor: '#000',

	},
	cardsContainerContent: {
		// backgroundColor: '#000',
		// minHeight: windowHeight,
		justifyContent: 'flex-start',
		// alignItems: 'space-between',
		flexDirection: 'column',
		flexWrap: 'wrap',
		minWidth: cardsContainerWidth,
		maxWidth: cardsContainerWidth,
	},
});

// use start en end radius for the smooth

