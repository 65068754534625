
import { StyleSheet } from 'react-native';
import { Dimensions } from 'react-native';
import { widthPercentageToDP, heightPercentageToDP } from 'react-native-responsive-screen';

const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height

export var styles = StyleSheet.create({
    emptyBackground: {
        minHeight: 0.7 * windowHeight,
        maxHeight: 0.7 * windowHeight,
        minWidth: windowWidth,
        maxWidth: windowWidth,
        width: windowWidth,
        position: 'absolute',
        top: 0, //works for all mobile
        opacity: 0.5,
        justifyContent: 'center',
        alignItems: 'center'
    },
    emptyScreenImage: {
        // minWidth: windowHeight * 0.3,
        maxWidth: windowWidth * 0.7,
        maxHeight: 0.3 * windowHeight,
        // backgroundColor: '#FF0',
        resizeMode: 'contain'
    },
    emptyText: {
        color: '#000',
        fontSize: 20,
        textAlign: 'center',
        // minHeight: windowHeight * 0.2,
        // minWidth: windowWidth * 0.2
    },
    emptyTextContainer: {
        justifyContent: 'center',
    },
    emptyContentContainer: {
        // maxHeight: windowHeight * 0.5
    }
})