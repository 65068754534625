import { StyleSheet } from 'react-native';
import { Dimensions } from 'react-native';
import { heightPercentageToDP } from 'react-native-responsive-screen';
//todo Animation

const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height

export const styles = StyleSheet.create({
	view: {
		// textAlign: "center",
		// minHeight: windowHeight * 0.08,
		// minWidth: windowWidth*0.9,
		// flex:1,
		// backgroundColor: "#F00",
		width: windowWidth,
		opacity: 1,
		alignItems: "center",
		justifyContent: "center",
		marginBottom: 0,
		position: "absolute",
		// marginTop: heightPercentageToDP(20),
		top: windowHeight * 0.4,
		// color: '#FFF',
		minHeight: windowHeight*0.08,
		maxHeight: windowHeight*0.08,
	},
	roundBackground: {
		justifyContent: 'center',
		backgroundColor: '#1FC75A',
		// width: '60%',
		borderRadius: windowHeight * 0.045,
		paddingHorizontal: heightPercentageToDP(2),
		paddingVertical: heightPercentageToDP(2),
		flex: 1,
	},
	text: {
		textAlign: "center",
		alignItems: 'center',
		
		// minHeight: windowHeight * 0.1,
		
		color: '#FFF',
		textAlign: "center",
		textAlignVertical: "center",
		// color: '#000000',
		fontSize: heightPercentageToDP(3.5),
		opacity:0.87,
		// height: 70,
		flex: 1,
		// display: "none",
		justifyContent: "center"
	}
});