import { StyleSheet } from 'react-native';
import { Dimensions } from 'react-native';

import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';
import { isPortraitView } from '../../../utils/isPortraitView'

const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height
//can this handle resizing

const cardsContainerWidth = (windowWidth / windowHeight > 1) ? windowHeight * 1.2 : windowWidth

export var styles = StyleSheet.create({
	backgroundImage: {
        minHeight: windowHeight,
        // height: '100%',
        // maxHeight: windowHeight,
        minWidth: windowWidth,
        maxWidth: windowWidth,
        width: windowWidth,
        position: 'absolute',
        // top: -1 * 300 * windowWidth/windowHeight, //only for FDH
        // top:  ( 0.64 - 0.61 )  * wind  owHeight, //only for FDH
        // top: -180, //only for FDH
        top: 0, //works for all mobile
		// resizeMode: 'center',
		// opacity: isPortraitView()?0:1,
        resizeMode: 'cover' // cover works perfect for web but not android
    },
	departmentTitle: {
		fontWeight: '700', // dekstop only
		textAlign: "center",
		textAlignVertical: "center",
		// flexWrap: 'nowrap',
		color: '#000',
		opacity: 0.87,
		// fontSize: adjustFontSize(28), // scale to fit in 1 line?
		fontSize: hp(5),
		// fontSize:30, //todo scale
		// maxHeight: windowHeight * 0.1,
		minHeight: windowHeight * 0.1,
		marginTop:windowHeight * 0.01,
		marginHorizontal:  windowWidth * 0.04,
		flex: 0.1,
		justifyContent: "center",
		// backgroundColor: '#000',
	},
	departmentDescription: {
		color: '#000',
		opacity: 0.87,
		// fontSize: adjustFontSize(16),
		fontSize: hp('2.50%'),
		marginHorizontal: windowWidth * 0.07,
		flex: 0.5,
		flexShrink: 1,
		flexBasis: 0,
		maxHeight: windowHeight * 0.1,
		minHeight: windowHeight * 0.1,
		textAlign: "center",
		textAlignVertical: "center",
		// backgroundColor: "#000",
		// justifyContent: "center"
		marginBottom: windowHeight * 0.02,
		overflow:'hidden'
	},
	wideContainer: {
		justifyContent: 'center',
		flexDirection: 'row',
		// alignItems: 'center',
		minWidth: windowWidth,
		// minHeight: '100%',
		// height:  windowHeight,
		// backgroundColor: '#000',

	},
	cardsContainerContent: {
		// backgroundColor: '#000',
		justifyContent: 'flex-start',
		// alignItems: 'space-between',
		flexDirection: 'row',
		flexWrap: 'wrap',
		minWidth: cardsContainerWidth,
		maxWidth: cardsContainerWidth,
		marginBottom: hp(1)
	},
	cardsContainer: {
		flex: 0.6,
		flexDirection: 'column',
		flexWrap: 'wrap',
		minHeight: windowHeight * 0.6,
		shadowOffset: {
			width: 0,
			height:windowHeight * 0.002
		},
		// marginBottom: windowHeight * 0.00,
		backgroundColor: '#000',
	},
	cardView: {
		flex: 1,
		marginHorizontal: 0,
		shadowOffset: {
			width: 0,
			height:windowHeight * 0.3,
			//scale these
		},
	},
	cardStyle: {
		marginHorizontal: 0,
		marginTop: 0,
		padding: 0,
		// height: windowHeight * 0.3,
		flex: 1,
	},
	cardImageStyle: {
		marginTop: 0,
		marginHorizontal: 0,
		padding: 0,
		flex: 1,
		height: windowHeight * 0.15,
	},
	flexRow: {
        flex: 1,
		flexDirection: 'row',
		flexWrap: 'wrap',
		// height: windowHeight * 0.3,
		marginBottom: windowHeight * 0.02,
		maxHeight: windowHeight * 0.4,
		// backgroundColor: "#000",
		overflow: 'hidden',
		flexShrink: 1,
		flexBasis: 0,
	},
	dashboardContainer: {
		backgroundColor: "#FFF",
		// height:windowHeight,
		width: windowWidth,
	},

	//		Recent Achievements
	recentAchivementsText: {
		minHeight: windowHeight * 0.1,
		marginTop: 0.05 * windowHeight,
		fontSize: hp(4),
	},
	recentAchivementsContainer: {
		// minHeight: windowHeight * 0.1,
		
		maxWidth: cardsContainerWidth,
		// marginTop: 0.1 * windowHeight,
		// backgroundColor: '#FFF'
	},
	recentAchivementsTextContainer: {
		minHeight: windowHeight * 0.1,
		// marginTop: 0.01 * windowHeight,
		justifyContent:'center',
		// textAlign: 'center',
		// backgroundColor: '#AAA',
		alignItems:'center'
	},
	recentAchivementsList: {
		// minHeight: 0.7 * windowHeight
		minWidth: cardsContainerWidth * 0.3,
		marginBottom: 0.05 * windowHeight
	},
	gameOnLogoContainer: {
        position: "absolute",
        top: 0,
        left:0,
        justifyContent: 'center',
        alignItems: 'center',
        width: windowWidth,
        height: windowHeight * 0.1,
        backgroundColor: '#F00',
        margin: 0,
        // border: 0,
        padding:0,
       // minHeight: heightPercentageToDP(3),
        // maxHeight: heightPercentageToDP(7),

        // maxHeight: 0.03 * windowHeight,
        // maxHeight: 0.08 * windowHeight,

        // maxHeight: '100%',
        // height: '100%',
        // minWidth: '5%',
        // maxWidth: '100%',

        // minWidth: 10,
        // maxWidth: 20,

        // marginHorizontal: 0,
        // marginVertical: 0,
        // backgroundColor: '#000',
        // paddingHorizontal: 0,
        // flex: 1
        // maxHeight: 300,
    },
    gameOnLogo: {
        // flex: 1,
        // minHeight: heightPercentageToDP(1),
        // maxHeight: heightPercentageToDP(10),
        // maxWidth: widthPercentageToDP(31),
        // maxHeight: heightPercentageToDP(5),
        width: '50%',
        height: '100%',
        marginHorizontal: 0,
        marginVertical: 0,
        // backgroundColor: '#000',
        paddingHorizontal: 0,
        // maxHeight: 300,
	},
	tieredAchievementImageStyle: {
        marginTop: 0,
		marginHorizontal: 0,
		padding: 0,
        flex: 1,
        // borderRadius: 2,
        // borderWidth: 2,
        // borderColor: '#000',
		// borderTopLeftRadius:windowHeight * 0.05,
		// borderTopRightRadius: windowHeight * 0.05,
		// height: windowHeight * 0.1,
		minHeight: 0.06 *  windowHeight,
        maxHeight: 0.06 * windowHeight ,
        maxWidth: 0.2 * windowWidth  ,
        // opacity: 0.5,
        overflow: 'hidden',
        // width: '100%',
    }
});

// use start en end radius for the smooth

var cardTouchableOpacity = {
	// width: windowWidth * 0.45,
	// // backgroundColor: "#FFF",
	// flex: 1,
	// marginTop: 20,
	// marginHorizontal: windowWidth * 0.02,
	// // marginBottom: windowHeight * 0.02,
	// marginBottom: 10,
	// //needs space for shadow?
	// shadowOffset: {
	// 	width: 0,
	// 	height: windowHeight * 0.002
	// 	//scale these
	// },
	// elevation: windowHeight * 0.005,
	// shadowRadius: windowHeight * 0.004,
	// shadowOpacity: 0.5,
};


// const gameCardStyleJson = { ...cardTouchableOpacity, backgroundColor: '#FF5454' }
const gameCardStyleJson = { ...cardTouchableOpacity}

export const gameCardStyle = StyleSheet.create({
	cardTouchableOpacity: gameCardStyleJson
});

// const rewardsCardStyleJson = { ...cardTouchableOpacity, backgroundColor: '#FF5488' }
const rewardsCardStyleJson = { ...cardTouchableOpacity }
export const  rewardsCardStyle = StyleSheet.create({
	cardTouchableOpacity: rewardsCardStyleJson
});

// const leaderboardCardStyleJson = { ...cardTouchableOpacity, backgroundColor: '#FF924A' }
const leaderboardCardStyleJson = { ...cardTouchableOpacity}
export const leaderboardCardStyle = StyleSheet.create({
	cardTouchableOpacity: leaderboardCardStyleJson
});