
import React from "react";
import { StyleSheet, Text, TouchableOpacity, Image, View, ActivityIndicator } from 'react-native';
import { styles } from '../../styles/Standard/common/EmptyPageBackground.style';
import { useNavigation } from '@react-navigation/native';
import { Dimensions } from 'react-native';
import {emptyPage } from '../../assets/images';


export function EmptyPageBackground(props) {
    const windowWidth = Dimensions.get('window').width
    const windowHeight = Dimensions.get('window').height
    const navigation = useNavigation();

    if (props.isEmpty == true) {
        return(
        <View style={styles.emptyBackground}>
        <View style={styles.emptyContentContainer}>
            <Image source={emptyPage} style={styles.emptyScreenImage}></Image>
            <View style={styles.emptyTextContainer}>
                <Text style={styles.emptyText}>No content yet</Text>
                </View>
        </View>
            </View>
        )
    } else {
        return(null)
    }
}