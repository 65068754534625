export function clearGlobalState() {
    console.log("clear global state")
    const globalState = require('../assets/GlobalState')
    globalState.currentScreenTitle= "Login",
    globalState.portraitView= true
    globalState.isWebView= false,
    globalState.DBPath= { // key=collectionId, value=documentId
        companyId: undefined,
        departmentId: undefined,
        learningSubTopicId: undefined,
        trainingMaterialTopicId: undefined,
        gameId: undefined,
        gameCategoryId: undefined,
        userId: undefined
    },
    globalState.userProfile= {},
    globalState.isLeaderboardFunctionReady= undefined
}