import { StyleSheet } from 'react-native';
import { Dimensions } from 'react-native';

const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height
//can this handle resizing

export var styles = StyleSheet.create({
	scrollView: {
		backgroundColor: "#FFF",
		height: '100%',
		width: '100%',
	},
	text: {
		minHeight: windowHeight * 0.1,
		flex: 1,
		marginHorizontal: windowWidth * 0.04,
		fontSize: 22,
		// backgroundColor: '#000',
	},
	textContainer: {
		flex: 1,
		minWidth: windowWidth,
		minHeight: windowHeight * 0.1,
		backgroundColor: '#FFF'
		// backgroundColor: '#000',
	},
	image: {
        flex: 1,
		marginBottom: windowHeight * 0.01,
		width: '100%',
		minWidth: windowWidth,
        minHeight: windowHeight * 0.04,
		// maxHeight: windowHeight * 0.4,
		maxWidth: '100%',
		// backgroundColor: '#000'
	},
	videoContainer: {
		flex: 1,
		minWidth: windowWidth * 0.5,
		minHeight: windowHeight * 0.4,
		backgroundColor: '#FFF'
		// height: 500
		// backgroundColor: '#FF0'
		// alignItems: "center",
		// backgroundColor: '#000000',
		// flex: 0,
		// width: windowWidth,
		// minHeight: windowHeight * 0.5
	},
	WebView: {
		// margin: 20,
		// minWidth: windowWidth,
		// minHeight: windowHeight * 0.1
	}
});