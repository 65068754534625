// export const primaryColour = "#3B9AD5"
// export const primarySideColour = "#1F72A6"


// //todo calculate primarySIdeColour

// export const otherColours = [

// ]

// export const cardColours = [
//     {
//         background:"#FF5454",
//         shadow: '#EB3C3C'
//     },
//     {
//         background: "#549EFF",
//         shadow:'#5378FF'
//     },
//     {
//         background:"#FF924A",
//         shadow: '#FF6500'
//     },
//     {
//         background:primaryColour,
//         shadow:primarySideColour
//     },
// ]

module.exports = {
    // primaryColour: "#3B9AD5",
    primaryColour: "#a8a8a8",
    // primarySideColour: '#1F72A6',
    primarySideColour: '#000',
    cardColours: [
        {
            background: "#FF5454",
            shadow: '#EB3C3C'
        },
        {
            background: "#549EFF",
            shadow: '#5378FF'
        },
        {
            background: "#FF924A",
            shadow: '#FF6500'
        },
        {
            background: "#3B9AD5",
            shadow: "#1F72A6"
        },
    ]
}




