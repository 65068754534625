import { StyleSheet, ScrollView, Text, Image, View, Dimensions, Alert } from 'react-native';
import React, { useState } from "react";

import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';

import { styles } from '../../styles/Standard/common/MediaDisplay.styles'; //rename
import { useNavigation } from '@react-navigation/native';
import { dashboardImages, images } from '../../assets/images';

import { WebView } from 'react-native-webview';
import { Video } from 'expo-av';
import { Platform } from 'react-native';
import GlobalState, { currentScreenTitle } from '../../assets/GlobalState';
import { EmptyPageBackground } from '../../components/common/EmptyPageBackground'
// import { getElement } from '../firebase.config';
import { LoadingIndicator } from './LoadingIndicator';
import { isLoading } from 'expo-font';

const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height

// ______________________________________________________________________________________________

// import { WebView } from 'expo';
// import React, { CSSProperties } from 'react';

// export function NativeWebViewProps {
//     target: string;
// }

// export const NativeWebView = (props): JSX.Element => {
//     if (Platform.OS === 'web') {
//         return <iframe src={props.target} style={{height: 100, width: 100}} />;
//     }
//     return <WebView source={{ uri: props.target }} />;
// };

// ____________________________________________________________________________________________


// const Tab = createMaterialTopTabNavigator();

// https://docs.google.com/gview?embedded=true&url=https://pdfs.semanticscholar.org/257d/c8ae2a8353bb2e86c1b7186e7d989fb433d3.pdf

export function MediaDisplay(props) {
    var globalState = require('../../assets/GlobalState')
    // navigation.setOptions({ title: globalState.currentScreenTitle })
    // const [media, setMedia] = useState(undefined) // change
    // const [currentMediaPath, setCurrentMediaPath] = useState(undefined)
    // const [haveRequestionMedia, setHaveRequestionMedia] = useState(false)
    // const [isLoading, setIsLoading] = useState(true)
    // const [requestCount, setRequestCount] = useState(0)

    // console.log(props)

    const navigation = useNavigation();

    if (globalState.DBPath.departmentId == undefined) {
        console.log("globalstate undefined after refresh")
        navigation.replace("login")
    }

    var media = []
    if (props.media != undefined) {
        media = props.media
    } else {
        console.log("media is undefined")
    }

    var isLoading = false
    if (props.isLoading != undefined) {
        isLoading = props.isLoading
        // console.log(isLoading)
    }

    var isEmptyPage = false
    if (props.isEmptyPage != undefined) {
        isEmptyPage = props.isEmptyPage
    }

    if (isEmptyPage) {
        // return null
        return (<EmptyPageBackground isEmpty={isEmptyPage} />)
    }

    if (isLoading) {
        // return null
        return (<LoadingIndicator isLoading={true} />)
    }



    // might only owrk on andoird & ios not web
    function renderWebView() {
        // https://stackoverflow.com/questions/62298683/webview-alternative-for-react-native-web-expo/62300525?noredirect=1#comment110241324_62300525
        // if (Platform.OS === 'web') {
        //     console.log("web")
        //     return <iframe src={'www.google.com'} style={{ marginTop: 5, height: 500, width: 1000, minHeight: 400 }} />;
        // }
        if (globalState.isWebDisplay) {
            return (
                <View style={styles.videoContainer} key={i}>
                    <Text style={styles.text}>
                        {/* Youtube Video not supported in web version */}
                    </Text>
                </View>
                // <View style={styles.videoContainer} key={i}>
                /* <iframe src={'https://stackoverflow.com/questions/62298683/webview-alternative-for-react-native-web-expo'} style={styles} />; */
                // </View>
            )
        } else {
            return (
                <WebView
                    originWhitelist={['*']}
                    // source={{ uri:'https://docs.google.com/gview?embedded=true&url=https://alraziuni.edu.ye/uploads/pdf/book1/nursing/fundamentals%20of%20food,nutrition%20and%20diet%20therapy.pdf' }}
                    source={{ uri: 'http://www.google.com' }}
                    style={{ marginTop: 5, height: 500, width: 1000, minHeight: 400 }}
                />)
        }
        // return <WebView source={{ uri: props.target }} />;

    }

    function renderLearningMedia(mediaArray) {
        if (mediaArray == undefined) {
            Alert.alert("Subtopic media is undefined. To address this, go back to Learning Center to reload or notify the department manager")
            // navigation.goBack()
            return null
        }

        if (mediaArray.length < 1) {
            return null
        }

        return (mediaArray.map((media, i) =>
            (renderMediaItem(media, i)
            )
        ))


    }

    function renderMediaItem(media, i) {
        console.log(globalState.isWebDisplay)
        var webContainerWidth = windowWidth * 0.6
        var containerWidth = globalState.isWebDisplay ? webContainerWidth : windowWidth
        console.log(webContainerWidth)
        var dynamicContainerStyle = StyleSheet.create({
            textContainer: {
                minWidth: containerWidth,
                maxWidth: containerWidth,
            },
            videoContainer: {
                minWidth: containerWidth,
                maxWidth: containerWidth,
            },
        })

        // media.mediaType = "pdf"

        // console.log('https://mediagameondev-sano1.streaming.media.azure.net/01263e07-df14-47d9-8c49-6770ea51942b/MHWCanteenVideo1.ism/manifest'.substring(0, 25)) // preferrably add flag later instead? or keep as checking

        if (media.mediaType == "video" || media.mediaType == "Video") {
            if (media.media == undefined || media.media.length < 3) {

                const azureVideoUrlPrefix = 'https://mediagameondev-sano1.streaming.media.azure.net/01263e07-df14-47d9-8c49-6770ea51942b/MHWCanteenVideo1.ism/manifest'.substring(0, 25)

                if (media.media.substring(0, 30) != 'https://www.youtube.com/embed/') { //|| media.media.substring(0, 25) != azureVideoUrlPrefix) {
                    console.log(media.media.substring(0, 30))
                    return (
                        <View style={styles.videoContainer} key={i}>
                            <Text style={styles.text}>
                                {"The video url is invalid.\n The required prefix is https://www.youtube.com/embed/ or https://mediagameondev-sa"}
                            </Text>
                        </View>
                    )
                }
            }

            let videoWidth = Dimensions.get('window').width;
            let videoHeight = windowWidth / 11 * 9;

            const isAzure = media.processingCompleted != undefined ? true : false
            // console.log(globalState.isWebDisplay)
            // isAzure = media.media.substring(0, 30) != 'https://www.youtube.com/embed/' ? true : false
            if (isAzure) {
                return (
                    <View style={styles.videoContainer}>
                        <Video
                            // source={{ uri: 'https://mediagameondev-sano1.streaming.media.azure.net/01263e07-df14-47d9-8c49-6770ea51942b/MHWCanteenVideo1.ism/manifest' }}
                            //   source={{ uri: 'http://qthttp.apple.com.edgesuite.net/1010qwoeiuryfg/sl.m3u8' }}
                            source={{ uri: media.media }}
                            rate={1.0}
                            volume={1.0}
                            isMuted={false}
                            resizeMode="cover"
                            shouldPlay
                            // give controls
                            // style={{ width: videoWidth, height: videoHeight }} />
                            style={styles.AzureView} />

                        {/* Replace this with comment section like Youtube */}
                        {/* {/* <Text>width * height = {videoWidth} * {videoHeight}</Text> */}
                    </View>
                )
            } else {
                if (globalState.isWebDisplay) {
                    const videoUrl = "https://www.youtube.com/embed/KR1TJERFzp0"
                    return (
                        <div dangerouslySetInnerHTML={{ __html: '<iframe width="' + webContainerWidth + '" height="' + (9 / 16 * webContainerWidth) + '" src="' + media.media + '" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>' }}></div>
                    )
                } else {
                    return (
                        <View style={styles.videoContainer} key={i}>
                            <WebView
                                style={styles.WebView}
                                javaScriptEnabled={true}
                                domStorageEnabled={true}
                                // source={{uri: 'www.youtube.com/embed/YE7VzlLtp-4' }}
                                source={{ uri: media.media }}
                                key={"v" + i}
                            />
                        </View>
                    )
                }
            }
        }
        if (media.mediaType == "text" || media.mediaType == "Text") {
            return (
                <View style={[styles.textContainer, dynamicContainerStyle.textContainer]} key={i}>
                    <Text style={styles.text}>
                        {media.media}
                        {/* {"Small text"} */}
                    </Text>
                </View>
            )
        }

        if (media.mediaType == "image" || media.mediaType == "Image") {
            var img = { uri: media.media }
            return (
                <View style={[styles.videoContainer, dynamicContainerStyle.videoContainer]} key={i}>
                    <Image
                        style={styles.image}
                        activeOpacity={1}
                        resizeMode="contain"
                        source={img}
                    />
                </View >
            )
        }

        if (media.mediaType == "pdf" || media.mediaType == "Pdf" || media.mediaType == "PDF") {
            // https://docs.google.com/gview?embedded=true&url=https://pdfs.semanticscholar.org/257d/c8ae2a8353bb2e86c1b7186e7d989fb433d3.pdf

            var  viewerUrl = "https://docs.google.com/gview?embedded=true&url="
            // viewerUrl = ""
            var pdfUrl = media.media
            // pdfUrl = "https://firebasestorage.googleapis.com/v0/b/test-fddd5.appspot.com/o/learningCenter_files%2Fb1ac40ac-41a8-45fb-81d3-ff598a47510e?alt=media&token=fa9923d7-ed3e-4e87-80fa-b7cfb36a7f37"
            // pdfUrl = "pdfs.semanticscholar.org/257d/c8ae2a8353bb2e86c1b7186e7d989fb433d3.pdf"
            if (globalState.isWebDisplay) {
                //  web works
                var pdfUrl = media.media
                viewerUrl = ""
                const viewerProperties = "#toolbar=0&navpanes=0"
                return (
                    <View>
                    <div dangerouslySetInnerHTML={{ __html: '<script type="text/javascript">  document.onmousedown = disableRightclick;  var message = "Right click not allowed !!";  function disableRightclick(evt){ if(evt.button == 2){ alert(message); return false;  } }  </script>' }}></div>
                    <div dangerouslySetInnerHTML={{ __html: '<iframe width="' + webContainerWidth + '" height="' + (9 / 16 * webContainerWidth) + '" src="' + viewerUrl + pdfUrl + viewerProperties + '" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>' }}></div>
                    {/* // <div dangerouslySetInnerHTML={{ __html: '<iframe width="' +webContainerWidth+ '" height="'+(9/16 *webContainerWidth)+ '" src="'+  media.media+ '" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>' }}></div> */}
                    </View>
                )
            } else {
                // https://stackoverflow.com/questions/44625680/disable-zoom-on-web-view-react-native
                // scale page so that zoom buttons are larger (but download button will be too)
                const PdfReader = ({ url: uri }) => <WebView style={{ flex: 1 }} source={{ uri }} />
                
                const INJECTEDJAVASCRIPT = `const meta = document.createElement('meta'); meta.setAttribute('content', 'width=device-width, initial-scale=0.5, maximum-scale=0.5, user-scalable=0'); meta.setAttribute('name', 'viewport'); document.getElementsByTagName('head')[0].appendChild(meta); `
                // return (
                //     <div dangerouslySetInnerHTML={{ __html: '<iframe width="' + webContainerWidth + '" height="' + (9 / 16 * webContainerWidth) + '" src="' + viewerUrl + pdfUrl + '" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>' }}></div>
                //     // <div dangerouslySetInnerHTML={{ __html: '<iframe width="' +webContainerWidth+ '" height="'+(9/16 *webContainerWidth)+ '" src="'+  media.media+ '" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>' }}></div>
                // )

                // Check app pdf view with uploaded pdfs, which works with external hosted pdf links but maybe not for fb
                return (
                    
                    <View style={styles.videoContainer} key={i}>
                        <WebView
                            originWhitelist={['*']}
                            source={{ uri: viewerUrl + pdfUrl }}
                            // source={{ uri: 'http://www.google.com' }}
                            // source={{ uri: 'https://firebasestorage.googleapis.com/v0/b/test-fddd5.appspot.com/o/learningCenter_files%2Fb1ac40ac-41a8-45fb-81d3-ff598a47510e?alt=media&token=fa9923d7-ed3e-4e87-80fa-b7cfb36a7f37' }}
                            style={{ height: 0.5 * windowHeight, width: windowWidth, minHeight: 400, backgroundColor: '#000' }}
                            // scalesPageToFit={globalState.isWebDisplay ? false : true}
                            scalesPageToFit={true}
                            // injectedJavaScript={INJECTEDJAVASCRIPT}
                            scrollEnabled
                        />
                        {/* <PdfReader url={viewerUrl + pdfUrl } /> */}
                    </View>
                )
            }
        }

        //pdf
        //     {/* <WebView
        //   bounces={false}
        //   scrollEnabled={false} 
        //   source={{ uri: 'http://www.africau.edu/images/default/sample.pdf' }} /> */}
        // </ScrollView>

    }

    return (
        <ScrollView style={styles.scrollView} contentContainerStyle={styles.scrollViewContentContainer}>
            {/* {renderWebView()} */}
            {/* {isEmptyPage || isLoading?<Text>{}</Text>: null} */}
            <View style={{ maxWidth: globalState.isWebDisplay ? 0.6 * windowWidth : windowWidth }}>


                {renderLearningMedia(media != undefined ? media : [])}
                {/* <EmptyPageBackground isEmpty={true} />
            <LoadingIndicator isLoading={true} /> */}
            </View>
        </ScrollView>
    )
}


// https://stackoverflow.com/questions/60592332/reactnative-webview-not-rendering-on-web-but-works-fine-on-android