import { StyleSheet, Text, View, Button, Image, Dimensions, ScrollView, Alert } from 'react-native';
import React, { useState } from "react";
import { ListItem } from 'react-native-elements';
import { heightPercentageToDP, widthPercentageToDP } from 'react-native-responsive-screen';

import { styles } from '../styles/Standard/screens/Leaderboard.styles'; //rename
import { getElement, getStorageRef } from '../firebase.config';
import { LoadingIndicator } from '../components/common/LoadingIndicator';
import { images } from '../assets/images';

import { EmptyPageBackground } from '../components/common/EmptyPageBackground'
import GameState from '../assets/GameState';

const windowHeight = Dimensions.get('window').height
const windowWidth = Dimensions.get('window').width

const cardsContainerWidth = (windowWidth / windowHeight > 1) ? windowHeight * 1.2 : windowWidth

export function Leaderboard({ navigation }, props) {

	const [list, setList] = useState(undefined)
	const [listImageURLs, setListImageURLs] = useState({})
	const [isLoading, setIsLoading] = useState(true)
	const [hasImagesLoaded, setHasImagesLoaded] = useState(false)
	const [triggerRefresh, setTriggerRefresh] = useState(true)

	const [requestCount, setRequestCount] = useState(0)
	const [hasRequestedLeaderboard, setHasRequestedLeaderboard] = useState(false)

	const globalState = require('../assets/GlobalState')
	var gameState = require('../assets/GameState')
	const currentUserId = (globalState.DBPath.userId != null && globalState.DBPath.userId.length > 0) ? globalState.DBPath.userId : ""

	const [executedOnce, setExecutedOnce] = useState(false)

	var isEmptyPage = false
	if (isLoading == false && (list == undefined || list.length < 1)) {
		isEmptyPage = true
	}

	if (isLoading == true && requestCount > 9) {
		setIsLoading(false)
	}

	function getIsFinalLeaderboard() {
		var gameState = require('../assets/GameState')
		return gameState.isLeaderboardUpdated
	}


	const maxRequestCount = 10

	// console.log("renderLeaderboard")
	var isFinalLeaderboard = gameState.isLeaderboardUpdated //ensure deep copy

	console.log(isFinalLeaderboard + " " + requestCount + " " + hasRequestedLeaderboard)

	if (globalState.isGameLeaderboard) {
		if (list == undefined) {
			if (gameState.localGameLeaderboard != undefined) {
				setList(gameState.localGameLeaderboard)
			} else {
				setList([])
				// set
			}
			setIsLoading(false)
		}
	} else {
		if (((isFinalLeaderboard == false && requestCount < maxRequestCount) || list == undefined) && hasRequestedLeaderboard == false) {
			if (globalState.DBPath.companyId == undefined || globalState.DBPath.departmentId == undefined || globalState.DBPath.companyId == "" || globalState.DBPath.departmentId == "") {
				Alert.alert("Company or  department not set");
				navigation.replace('login')
				// logout?
				return null
			}

			console.log("getLeaderboard")
			if (isLoading == false) {
				setIsLoading(true)
			}
			setList([])
			var newList = []
			var path = (globalState.isGameLeaderboard != undefined && globalState.isGameLeaderboard == true) ?
				['Companies', globalState.DBPath.companyId, 'Departments', globalState.DBPath.departmentId, 'Leaderboard', globalState.DBPath.gameId] :
				['Companies', globalState.DBPath.companyId, 'Departments', globalState.DBPath.departmentId, 'Leaderboard', 'Leaderboard']

			getElement(path).get().then(function (doc) {
				if (doc.exists) {
					const preProcessedLeaderboard = doc.data()['users']
					var leaderboardKeys = Object.keys(preProcessedLeaderboard)
					leaderboardKeys.sort()
					for (var i = 0; i < leaderboardKeys.length; i++) {
						var leaderboardEntry = preProcessedLeaderboard[leaderboardKeys[i]]
						newList.push(leaderboardEntry)
					}
					setList(newList)

				} else {
					console.log("Document not found");
				}

				if (getIsFinalLeaderboard() == false) {
					setHasRequestedLeaderboard(false)
					setRequestCount(requestCount + 1)
				} else {
					console.log("isfinalLeaderboard doc exists")
					setIsLoading(false)
				}
				// setHasRequestedLeaderboard(true)
			}).catch(function (error) {
				console.log("Error getting doc", error);
				if (requestCount + 1 > maxRequestCount) {
					console.log("max request count reached")
					setIsLoading(false)
				} else {
					setHasRequestedLeaderboard(false)
					setRequestCount(requestCount + 1)
				}
			});
			setHasRequestedLeaderboard(true)
			if (isFinalLeaderboard == true) {
				setIsLoading(false)
			}
		}
	}

	if (!hasImagesLoaded) {
		loadImages()
	}

	// limit # of tries
	async function loadImages() {
		if (list != null && list.length > 0) {
			for (var i = 0; i < list.length; i++) {
				loadImage(i)
			}
			setHasImagesLoaded(true)
		}
	}

	async function loadImage(i) { // not nexessary anymore?
		// this data can be overrided by other get if it finishes after and overwriters list!
		// maybe this doesnt work because list is not available yet? no

		// maybe do together to  prevent overwriting each other? or how els prevent overwriting each other? external function?
		if (list[i].imageRef != undefined && list[i].imageRef.length > 0) {
			try {
				var storageRef = getStorageRef("/user_profile_images/" + list[i].imageRef)
				// var  imageRef = firebase.storage().ref("/user_profile_images/" + leaderboardEntry.imageRef);
				var imageUrl = await storageRef.getDownloadURL().then(function (url) {
					var leaderboardEntry = list[i]
					// console.log(i + " " + url)
					leaderboardEntry.imageUrl = url
					var newList = list
					newList[i] = leaderboardEntry
					setList(newList.concat(leaderboardEntry))
					setListImageURLs()
					setTriggerRefresh(!triggerRefresh)
					// setIsLoading(true)
					// does not refresh
				}); // maybe get images separately to allow leaderboard to load without iamges			
			} catch (e) {
				console.log(e)
			}
		}

	}

	function renderList() {
		if (list != undefined && list.length > 0) {
			return (list.map((l, i) => (
				<ListItem
					key={i}
					leftAvatar={{ source: (l.imageUrl != undefined && l.imageUrl.length > 0) ? { uri: l.imageUrl } : images[0], size: heightPercentageToDP(6) }}
					contentContainerStyle={{ marginLeft: 0.03 * windowWidth }}
					title={l.name}
					titleStyle={{ fontSize: heightPercentageToDP(2.3), fontWeight: (l.userId == currentUserId) ? '600' : '400' }}
					badge={{ value: l.score + "%", textStyle: { color: (l.score > 50) ? '#00780C' : '#FF0000', opacity: 0.87, fontWeight: (l.userId == currentUserId) ? '700' : '500', fontSize: heightPercentageToDP(2.5) }, badgeStyle: { backgroundColor: '#FFF' }, containerStyle: { marginTop: 0 } }}
					containerStyle={{ minWidth: cardsContainerWidth * 0.5, marginBottom: 0, maxHeight: windowHeight * 0.1 }}
					bottomDivider
				/>
			)))
		} else {
			return null
		}

	}



	function renderMobileList() {
		// console.log(list)
		if (list != undefined && list.length > 0) {
			// console.log(list)
			// console.log(list[0].imageUrl)
			// console.log(list[1].imageUrl)
			return (list.map((l, i) => (
				<ListItem
					key={i}
					// leftAvatar={{ source:  { uri: (l.imageUrl != undefined) ? l.imageUrl: null }, size: heightPercentageToDP(6) }}
					// leftAvatar={{ source: {uri: l.imageUrl}, size: heightPercentageToDP(6) }}
					// leftAvatar={{ source: { uri: 'https://firebasestorage.googleapis.com/v0/b/test-fddd5.appspot.com/o/user_profile_images%2F17218386_10208112506470119_1755910293452349284_o.jpg?alt=media&token=008f69e7-1768-458e-87ee-595546b439b2' }, size: heightPercentageToDP(6) }}
					leftAvatar={{ source: (l.imageUrl != undefined) ? { uri: l.imageUrl } : images[Math.floor(Math.random() * (images.length - 1))], size: heightPercentageToDP(6) }}
					// leftAvatar={{ source: { uri: '' } }}
					contentContainerStyle={{ marginLeft: 0.03 * windowWidth, height: '100%' }}
					// title={l.name}
					title={l.name} // test this by setting another poperty, like title in the url callback
					titleStyle={{ fontSize: heightPercentageToDP(3), fontWeight: (l.userId == currentUserId) ? 'bold' : '500' }}
					badge={{
						value: l.score + "%", textStyle: {
							color: (l.score > 50) ? '#00780C' : '#FF0000', opacity: 0.87, fontWeight: (l.userId == currentUserId) ? '600' : '500',
							fontSize: heightPercentageToDP(3)
						}, badgeStyle: { backgroundColor: '#FFF', padding: 0, margin: 0, minHeight: heightPercentageToDP(8) }, containerStyle: { marginTop: 0, marginRight: windowWidth * 0.02 }
					}}
					containerStyle={{ minWidth: windowWidth, marginBottom: 0, maxHeight: windowHeight * 0.1, backgroundColor: (l.userId == currentUserId) ? '#FFF' : '#FFF' }}
					bottomDivider
				/>
			)))
		} else {
			return null
		}
	}

	return (
		<View>
			<ScrollView style={styles.background}>
				<View style={styles.wideContainer}>
					<ScrollView style={styles.cardsContainerContent}>
						{globalState.portraitView ? renderMobileList() : renderList()}
					</ScrollView>
				</View>
			</ScrollView>
			<LoadingIndicator isLoading={isLoading} />
			<EmptyPageBackground isEmpty={isEmptyPage} />
		</View>
	);
}



