
import React from "react";
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { styles } from '../../styles/Standard/common/ProfileIcon.style';
import { useNavigation } from '@react-navigation/native';
import { Icon } from 'react-native-elements'
import { Dimensions } from 'react-native';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';

export function NavigateIconButton(props) {
    const windowWidth = Dimensions.get('window').width
    const navigation = useNavigation();

    return (
        <Icon name="play-arrow" size={hp(7)} color='#FFF' onPress={() => navigation.replace(props.destination)} iconStyle={{marginRight: windowWidth * 0.06}}/>
    )
}