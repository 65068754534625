// import { StyleSheet, Text, View } from 'react-native';
// import React from 'react';

// import { styles } from '../../styles/Standard/game/QuestionAnsweredBanner';

import React, { Component, useState } from "react";
import {
    Alert,
    Modal,
    StyleSheet,
    Text,
    ScrollView,
    TouchableHighlight,
    View
} from "react-native";

import {styles} from '../../styles/Standard/game/LeaderboardModal.styles'

import { InGameLeaderboard } from './InGameLeaderboard';
import { Leaderboard } from '../../screens/Leaderboard';

export function LeaderboardModal(props) {
    var modalVisible = props.modalVisible;
    // const [modalVisible, setModalVisible] = useState(false);

    // const isRecentlyOpened = () => {
    // 	const diff = new Date() - startDate;// +?
    // 	const secondsLeft = questionTime - (Math.floor((diff / 1000))); 
    // 	return secondsLeft
    // }

    // const globalState = require('./assets/GlobalState')



    var dynamicModalViewStyle = undefined
    dynamicModalViewStyle = StyleSheet.create({
        mainView: {
            width: 0,
            height: 0,
            maxHeight: 0,
            maxWidth:0,
            opacity:0,
        }
    });


    return (
        <View style={(modalVisible)? styles.mainView : styles.mainView, dynamicModalViewStyle.mainView}>
            <Modal
                animationType="slide"
                transparent={true}
                visible={(modalVisible!= undefined)?modalVisible:false}
                onRequestClose={() => {
                    Alert.alert("Modal has been closed.");
                }}
            >

                <View style={styles.centeredView}>
                    <View style={styles.modalView}>
                        <Text>Scoreboard</Text>
                        {/* <InGameLeaderboard/> */}
                        <InGameLeaderboard/>
                        <TouchableHighlight
                            style={{ ...styles.openButton, backgroundColor: "#FCFCFC" }}/// FFF
                            onPress={() => {
                                //   setModalVisible(!modalVisible);
                                console.log("pressed")
                                props.setModalDisplay(false)
                            }}
                        >
                            <Text style={styles.textStyle}>Close</Text>
                        </TouchableHighlight>
                    </View>
                </View>
            </Modal>

        </View>
    );
}

