
import React from "react";
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
// import { styles } from '../../styles/Standard/common/ProfileIcon.style';
import { useNavigation } from '@react-navigation/native';
import { Icon } from 'react-native-elements'
import { Dimensions } from 'react-native';
import { heightPercentageToDP } from "react-native-responsive-screen";
import { ProfileIcon } from './ProfileIcon'
import { MessageIcon } from './MessageIcon'

export function RightIconContainer() {
    const windowWidth = Dimensions.get('window').width
    const windowHeight = Dimensions.get('window').height
    // const navigation = useNavigation();

    return (
        // <Icon name='person' size={heightPercentageToDP(7)} color='#FFF' onPress={() => navigation.navigate('profile')} iconStyle={{marginRight: windowWidth * 0.06}}/>
        <View style={{flexDirection:"row", alignItems:'flex-end' , marginLeft: 0, paddingHorizontal: 0}}>
            <MessageIcon />
            <ProfileIcon />
        </View>
    )
}