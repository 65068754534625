
import { StyleSheet } from 'react-native';
import { Dimensions } from 'react-native';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';


const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height

//make colour setting in style sheet a props parameter and put in main function

// if more flex help needed look at: https://stackoverflow.com/questions/43143258/flex-vs-flexgrow-vs-flexshrink-vs-flexbasis-in-react-native/43147710#43147710
export const styles = StyleSheet.create({
    button: {
        // backgroundColor: "#03031c",
        flex: 1,
        borderRadius: hp('3%'),
        // marginHorizontal: ' 3%',
        width: '100%',
        marginBottom: hp('1%'),
        marginVertical: 0,
        alignItems: "center",
        justifyContent: "center",
        // textAlignVertical: 'middle',
        // width: wp('45'),
        // height: hp('45')

        // Shadow:
        // shadowOffset: {
        //     width: windowWidth*0.04,
        //     height: windowHeight * 0.01,
        //     blur: 20, // scale
        //     //scale these
        // },
        // elevation: windowHeight * 0.01,
        // shadowRadius: windowHeight * 0.004,
        // shadowOpacity: 0.75,
        // paddingBottom: windowHeight* 0.004,
        // marginBottom: windowHeight* 0.02
    },
    buttonText: {
        textAlign: "center",
        // textAlignVertical: "center",
        fontSize: hp('3%'),
        fontWeight:'500',
        color: '#FFF',
        opacity:0.87,
        alignItems: 'center',
        overflow: 'hidden',
        marginTop:0,
        // flex: 1,
        // width:
        // marginTop: windowHeight * 0.002,
        // justifyContent: "center",
        // marginVertical:'10%',
        // flexWrap: 'wrap'
        // flexShrink: 1,
        // alignSelf: 'center',
        // wordWrap: 'break-word',
        // hyphens: 'auto',
    },
    thickShadowView: {
        // maxHeight: hp('15%'),
        flex: 1,
        borderRadius: hp('3%'),
        marginHorizontal: wp('2%'),
        marginVertical: 0,
        alignItems: "center",
        justifyContent: "center",
        // shadowOffset: {
        //     width: windowWidth*0.01,
        //     height: windowHeight * 0.01,
        //     // blur: 20, // scale
        //     //scale these
        // },
        // elevation: windowHeight * 0.01,
        // shadowRadius: windowHeight * 0.004,
        shadowOffset: {
            width: 0,
            height: windowHeight * 0.002
            //scale these
        },
        // elevation: windowHeight * 0.005,
        elevation: windowHeight * 0.01,
        shadowRadius: windowHeight * 0.004,


        shadowOpacity: 0.75,
        // paddingBottom: windowHeight* 0.004,
        marginBottom: windowHeight* 0.01,
        // backgroundColor: "#FFF",
    }
})