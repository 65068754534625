import { ScrollView, Text, View } from 'react-native';
import React, { useState } from "react";

import { styles, rewardsCardStyle } from '../styles/Standard/screens/GamesList.styles'; //rename
import { images, dashboardImages } from '../assets/images';
import { CardButton } from '../components/common/CardButton';
import { LoadingIndicator } from '../components/common/LoadingIndicator';
import { getElement, getGameSchedules, Firebase } from '../firebase.config';

import {EmptyPageBackground} from '../components/common/EmptyPageBackground'

//TODO test if game works with just 1 game in database

// todo set gamelist to be empty when navigatin away! otherise it will keep previous gamelist
// https://stackoverflow.com/questions/43090884/resetting-the-navigation-stack-for-the-home-screen-react-navigation-and-react-n
export function GamesList({ navigation }, props) {
	const [isLoading, setIsLoading] = useState(true)
	const [requestCount, setRequestCount] = useState(0)
	// const [isEmptyPage]
	const globalState = require('../assets/GlobalState')
	navigation.setOptions({ title: globalState.currentScreenTitle })

	// put in style folder
	const cardBackgroundColours = ['#3C9CD7', '#8C8C8C', '#31B64D', '#FF71E6', "#FF5454", "#FF924A"];
	const cardShadowColours = ['#1F72A6', '#545454', '#108228', '#C242AC', '#EB3C3C', '#FF6500'];
	const gameCardColours = []
	for (var i = 0; i < cardBackgroundColours.length; i++) [
		gameCardColours.push({
			backgroundColour: cardBackgroundColours[i],
			shadowColour: cardShadowColours[i]
		})
	]

	const rewardsUnlocked = [true, false, true, false];

	const [gameList, setGamePairedList] = useState(undefined)

	const newLeaderboardEntryDetails = {
		userInfo: {
			name: globalState.userProfile.firstName + " " + globalState.userProfile.lastName,
			imgUrl: globalState.userProfile.imageUrl,
		},
		gameInfo: {
			companyId: globalState.DBPath.companyId,
			departmentId: globalState.DBPath.departmentId,
			gameId: globalState.DBPath.gameId,
			score: 77
		}
	}


	// function tempTestLeaderboardUpload(newLeaderboardEntryDetails) {
	// 	console.log("tempTestLeaderboardUpload")
	// 	const reqUrl = 'https://us-central1-test-fddd5.cloudfunctions.net/uploadToLeaderboard'
	// 	try {
	// 		fetch(reqUrl, {
	// 			method: 'post',
	// 			mode: 'cors',
	// 			body: JSON.stringify(newLeaderboardEntryDetails)
	// 		}).then((response) => {
	// 			console.log(JSON.stringify(response))
	// 		})
	// 	} catch (e) {
	// 		console.log(e)
	// 	}
	// }

	// function isFirstDateBeforeSecondStringDate(date_1, date_2) {
	// 	if (date_1.substring(0, 4) < date_2.substring(0, 4)) {
	// 		return true
	// 	}
	// 	if (date_1.substring(5, 7) < date_2.substring(5, 7)) {
	// 		return true
	// 	}
	// 	if (date_1.substring(8) <= date_2.substring(8)) {
	// 		return true
	// 	}
	// 	return false
	// }

	var isEmptyPage = false
	if (isLoading == false && (gameList == undefined || gameList.length < 1)) {
		isEmptyPage = true
	}


	if (gameList == undefined) {
		const globalState = require('../assets/GlobalState')
		// console.log(globalState.DBPath.departmentId + " " +globalState.DBPath.companyId )
		if (requestCount < 3) {
			if (globalState.DBPath.companyId != undefined && globalState.DBPath.departmentId != undefined) {
				// Currently getting games as a group of docs: Costs more reads but happends infrequently. If there are hundreds of games, copy this to a field in departmentDoc
				var path = ['Companies',globalState.DBPath.companyId, 'Departments',globalState.DBPath.departmentId, 'Games']
				
				var date = new Date()
				var formattedDate = date.toISOString()
				console.log(formattedDate)
				// console.log(globalState.DBPath.companyId + " " + globalState.DBPath.departmentId + " " + formattedDate)
				var getGameSchedules = Firebase.functions().httpsCallable('getGameSchedules');
				getGameSchedules({ department: globalState.DBPath.departmentId, company: globalState.DBPath.companyId, currentDate: formattedDate }).then(function (docs) {
					// console.log(docs)
					if (docs != undefined && docs.data != undefined && docs.data.games != undefined) {
						var newGameList = []
						docs.data.games.map(game => {
							// console.log(game)
							// if(game.gameId==undefined)
							game['documentId'] = game.gameId
							newGameList.push(game)

							// tempTestLeaderboardUpload(newLeaderboardEntryDetails)
						})
						setGamePairedList(assignColours(newGameList))
						setIsLoading(false)

					} else {
						console.log("Document not found");
						setGamePairedList(assignColours([]))
						setRequestCount(requestCount + 1)
					}

				}).catch(function (error) {
					 setRequestCount(requestCount + 1)
					console.log("Error getting doc", error);
				});
			} else {
				console.log('An important property for the firestore path is not set in globalState.DBPath.')
				console.log(globalState.DBPath.companyId + " " + globalState.DBPath.departmentId)
				navigation.replace('login')

				// navigation.goBack()
			}
		} else {
			console.log("maximum request reached.")
		}
	}

	// function groupGames(games) {
	// 	var groupedGames = [];
	// 	for (var i = 0; i < Math.ceil(games.length); i = i++) {
	// 		if (gameCardColours[i] != undefined) {
	// 			cardColourIndex = Math.floor(Math.random() * gameCardColours.length - 1)
	// 			games[i]["backgroundColour"] = gameCardColours[cardColourIndex].backgroundColour
	// 			games[i]["shadowColour"] = gameCardColours[cardColourIndex].shadowColour
	// 		}
	// 		games[i]["image"] = dashboardImages.resultsCard

	// 		// if (i + 1 < games.length) {
	// 		// 	if (gameCardColours[i] != undefined) {
	// 		// 		games[i + 1]["backgroundColour"] = gameCardColours[i + 1].backgroundColour
	// 		// 		games[i + 1]["shadowColour"] = gameCardColours[i + 1].shadowColour
	// 		// 	}
	// 		// 	games[i + 1]["image"] = dashboardImages.resultsCard
	// 		// }
	// 		// const row = (i + 1 < games.length) ? [games[i], games[i + 1]] : [games[i]];
	// 		groupedGames.push(row)
	// 	}
	// 	return groupedGames;
	// }

	function assignColours(list) {
		// console.log("assign")
		console.log(gameCardColours)
		for (var i = 0; i < list.length; i = i + 1) {
			if (list[i] != undefined) {
				var cardColourIndex = Math.floor(Math.random() * (gameCardColours.length -1))
				console.log(cardColourIndex)
				list[i]["backgroundColour"] = gameCardColours[cardColourIndex].backgroundColour
				list[i]["shadowColour"] = gameCardColours[cardColourIndex].shadowColour
			}
		}
		return list
	}

	function renderGames() {
		var gameListToDisplay = (gameList!= undefined)?gameList:[]
		return (gameListToDisplay.map((game, i) => {
			return (
				<CardButton key={"a" + i} destination='game_intro' navigationData={{ gameInfo: game }} collection={'gameId'}
					document={game['documentId']} title={game.name} description={game.subtitle}
					image={(game.gameImgUrl != undefined) ? { uri: game.gameImgUrl } : images[Math.floor(Math.random() * (images.length - 1))]}
					shadowColour={game.shadowColour} backgroundColour={game.backgroundColour} customStyle={rewardsCardStyle} />
				// <CardButton key={"a" + i} destination='game_intro' navigationData={{ gameInfo: game }} collection={'gameId'} document={game['documentId']} title={game.name} description={game.subtitle} image={{ uri: 'https://firebasestorage.googleapis.com/v0/b/test-fddd5.appspot.com/o/games_images%2F139-Successful%20Enterpreneur-CS4.png?alt=media&token=ba66b313-6d2b-49c2-a9d4-305b61e48749' }} shadowColour={game.shadowColour} backgroundColour={game.backgroundColour} customStyle={rewardsCardStyle} />
			)
		}))
	}

	function renderDesktopText() {
		if (globalState.portraitView) {
			return null
		} else {
			return (
				<View style={styles.gameTextContainer}>
					<Text style={styles.gameTitleStyle}>Games</Text>
					{/* <Text>Click on a game to select a category to start with. All categories will be done in a session. Category's scores add up.</Text> */}
				</View>
			)
		}

	}

	return (
		<View style={styles.dashboardContainer}>
			<ScrollView style={styles.scrollView}>
				<View style={styles.textWideContainer}>
					{/* {if(globalState.portraitView) return null } */}
					{renderDesktopText()}
				</View>
				<View style={styles.wideContainer}>

					<View style={styles.cardsContainerContent}>
						{
							renderGames()
						}
					</View>
				</View>
			</ScrollView>
			<LoadingIndicator isLoading={isLoading} />
			<EmptyPageBackground isEmpty={isEmptyPage}/>
		</View>
	);
}


