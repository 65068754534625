
import React, {useContext} from "react";
import { StyleSheet, Text, TouchableOpacity, View, ActivityIndicator } from 'react-native';
import { styles, rawStyles } from '../../styles/Standard/common/LoadingIndicator.style';
import { useNavigation } from '@react-navigation/native';
import { Icon } from 'react-native-elements'
import { Dimensions } from 'react-native';
import {ThemeContext} from '../../App'

export function LoadingIndicator(props) {
    const windowWidth = Dimensions.get('window').width
    const windowHeight = Dimensions.get('window').height
    const navigation = useNavigation();

    const context = useContext(ThemeContext)

    const globalColours = require('../../styles/globalColours')

    var updatedStyle = JSON.parse(JSON.stringify(rawStyles))
    updatedStyle.container.backgroundColor = globalColours.primaryColour
    var dynamicStyleSheet = StyleSheet.create(updatedStyle)

    if (props.isLoading == true) {
        return (

            <View style={dynamicStyleSheet.container}>
                {/* <Icon name='cloud-download' size={windowWidth * 0.1} color='#FFF' onPress={() => navigation.navigate('profile')} /> */}
                <ActivityIndicator size="large" color="#fff" style={{ marginTop: windowHeight * 0.02 }} />
                <Text style={styles.text}>{"Loading"}</Text>
            </View>
        )
    } else {
        return(null)
    }
}