import { StyleSheet, Text, ScrollView, View, Dimensions, TextInput, Image, Alert, WebView, Linking  } from 'react-native';
import React, { useState } from "react";
import { Input } from 'react-native-elements'
import { heightPercentageToDP } from 'react-native-responsive-screen';
import { useNavigation } from '@react-navigation/native';

import { SvgCssUri } from 'react-native-svg';
import { SvgUri } from 'react-native-svg';
import { SvgXml } from 'react-native-svg';
import { SvgCss } from 'react-native-svg';
// import SvgUri from 'react-native-svg-uri';

import { webStyles, mobileStyles, mobileContainerStyle, webContainerStyle, webInputContainerStyle, mobileInputContainerStyle, buttonColor, buttonShadowColor } from '../styles/Standard/screens/Login.styles'; //rename
import { StyledButton } from '../components/common/StyledButton';
import { ErrorDisplay } from '../components/common/ErrorDisplay';
import { GAME_CARD_TITLE } from '../assets/text/eng';
import { TouchableOpacity } from 'react-native-gesture-handler';

import { playSound } from '../utils/playSound';
import { clearGlobalState } from '../utils/clearGlobalState';
import { loginSuccessSound } from '../assets/sounds'
import { getElement, Firebase } from '../firebase.config';
import { logos } from '../assets/images';

// import { logos } from '../assets/images';

import * as ImagePicker from 'expo-image-picker';
import Constants from 'expo-constants';
import { Video } from 'expo-av';


const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height

const cardsContainerWidth = (windowWidth / windowHeight > 1) ? 0.5 * windowHeight * 1.2 : windowWidth

// need to be signed in to call?
// var logErrors = Firebase.functions().httpsCallable('logErrors'); //can this be executed without being logged in ?
// logErrors({test:'test'}) // log password and email just in testing
//     .then(function (response) {
//         console.log(response)
//     })
//     .catch(error => {
//         console.log("logErrors error, ", error)
//     })




export function Login({ navigation }, props) {
    const [isEmailInputSelected, setIsEmailInputSelected] = useState(false)
    const [isPasswordInputSelected, setIsPasswordInputSelected] = useState(false);

    const [typedEmailAddress, setTypedEmailAddress] = useState("");
    const [typedPassword, setTypedPassword] = useState("");
    const [hiddenPassword, setHiddenPassword] = useState("")

    const [errorText, setErrorText] = useState('')
    const [showPassword, setShowPassword] = useState(false)
    const [isFirstLoginLoad, setIsFirstLoginLoad] = useState(true)
    // const [typedEmailAddress, setTypedEmailAddress] = useState('bernardvtonder@gmail.com');
    // const [typedPassword, setTypedPassword] = useState( 'bvtX012lf94');
    const [isForgotPasswordMode, setIsForgotPasswordMode] = useState(false)
    const [isCompanyImageLoaded, setIsCompanyImageLoaded] = useState(false)
    const [hasRequestedCompany, setHasRequestedCompany] = useState(false)
    // const [companyImageUrl, setCompanyImageUrl] = useState("")
    const [companyPrimaryColour, setCompanyPrimaryColour] = useState("")
    // const [askedForNotififyPermission,setAskedForNotififyPermission] = useState(false)
    const [companyImageUrl, setCompanyImageUrl] = useState(undefined)

    var styles;
    var containerStyle;
    var inputContainerStyle;

    // var companyImageUrl = undefined

    { // keep globalState scope local
        const globalState = require('../assets/GlobalState')
        if (companyImageUrl == undefined && globalState.companyLogoUrl != undefined && globalState.companyLogoUrl.length > 1) {
            // companyImageUrl = globalState.companyLogoUrl
            setCompanyImageUrl(globalState.companyLogoUrl)
        }
    }

    
    if (companyImageUrl == undefined) {
        // console.log(2)
        Linking.getInitialURL().then((url) => {
            console.log(url);
            // url = "https://bbb.gameonmobile.co.za/login"
            // url = "https://yaypay.gameonmobile.co.za"
            const baseUrlEndPosition = url.indexOf(".co.za") + 6
            console.log(baseUrlEndPosition)
            var searchUrl = url.substring(0, baseUrlEndPosition)
            console.log(searchUrl)
    
            var path = ['Companies']
            getElement(path)
                .where("url", "==", searchUrl)
                .get()
                .then(function (response) {
                    console.log(response)
                    //handle if single too
                    const doc = response.docs[0]
                    if (response.docs.length < 1) {
                        setCompanyImageUrl("")
                    }
                    // console.log(response.docs)
                // console.log("in snapshot")
                    if (doc != undefined && doc.data() != undefined) {
                        const companyData = doc.data()
    
                        if (companyData.logoUrl != undefined && companyData.logoUrl.length > 1) {
                            setCompanyImageUrl(companyData.logoUrl)
                            
                        } else {
                            console.log("company logo url not defined")
                        }
                    }
                })
            
            // if none Foundation, search by adding s
    
        });
        
    }


    //search until co.za

        // query for  url
    //search for https (append s if not https in url)
    
    
    
    
    
    
    

    // if (askedForNotififyPermission == false) {
    //     setAskedForNotififyPermission(true)
    // }



    // console.log(globalState.companyLogoUrl)

    //stategoy 2: store imageUrl!! - dont need to get company then


    // console.log(companyImageUrl)
    // console.log(navigation)
    // console.log(navigation.setOptions) //scope 3 global
    // console.log(navigation.setOptions[Scopes]) //scope 3 global
    // console.log(navigation.setOptions[[Scopes]]) //scope 3 global

    // remember to reset these values // catch errors

    // if ((companyImageUrl == "" || companyImageUrl == undefined) && isCompanyImageLoaded == false && hasRequestedCompany == false) {
    //     const globalState = require('../assets/GlobalState') 
    //     console.log(globalState.companyIdForLoginImage)
    //     if (globalState.companyIdForLoginImage != undefined && globalState.companyIdForLoginImage.length > 0) {
    //        console.log("comp image req")
    //         //get image
    //         // var path = ['Companies', globalState.companyIdForLoginImage]
    //         var path = ['Companies', 'f7HCE1baiyyQvJPFvjzK']
    //        getElement(path).get().then(snapshot => {
    //            // console.log("in snapshot")
    //            if (snapshot != undefined && snapshot.data() != undefined) {
    //                const companyData = snapshot.data()

    //                if (companyData.colors != undefined && companyData.colors[0] != undefined) {
    //                 setCompanyPrimaryColour( companyData.colors[0])
    //                } else {
    //                    console.log("company colour not set")
    //                }

    //                if (companyData.logoUrl != undefined && companyData.logoUrl.length >0) {
    //                 setCompanyImageUrl(companyData.logoUrl)
    //                 setIsCompanyImageLoaded(true)
    //                } else {
    //                    console.log("company colour not set")
    //                }
    //            }
    //        })
    //        if (hasRequestedCompany == false) {
    //            setHasRequestedCompany(true)

    //        }
    //    }

    // }
    // if (globalState.DBPath.userId != undefined) {
    //     console.log("user defined, logging out")
    //     Firebase.auth().signOut().then(function () {
    //         console.log("sign out successful")
    //         // Sign-out successful.
    //     }).catch(function (error) {
    //         // An error happened.
    //     });
    // }



    function cleanLogin() {
        // console.log("is first load")
        clearGlobalState()
        Firebase.auth().signOut().then(function () {
        }).catch(function (error) {
            console.log("Error getting doc", error);
            setErrorText(error.message)
        });
        setIsFirstLoginLoad(false)
    }

    if (isFirstLoginLoad == true) {
        console.log("is first login")
        cleanLogin()
    }

    var isWeb = false
    if (Constants.platform != 'android' && Constants.platform != 'ios' && windowWidth / windowHeight > 0.9) {
        // console.log("webStyles")
        styles = webStyles
        containerStyle = webContainerStyle
        inputContainerStyle = webInputContainerStyle
        isWeb = true
    } else {
        styles = mobileStyles
        containerStyle = mobileContainerStyle
        inputContainerStyle = mobileInputContainerStyle
    }

    function loadUserData(user) {
        // console.log("'user exists")
        const globalState = require('../assets/GlobalState')
        // console.log(globalState)

        if (globalState.DBPath.companyId == undefined || globalState.DBPath.departmentId == undefined) {
            try {
                var currentCompanyId = user.displayName
                globalState.DBPath.companyId = currentCompanyId
                globalState.companyIdForLoginImage = currentCompanyId

                var currentUserId = user.uid
                globalState.DBPath.userId = currentUserId

                if (user.displayName != undefined && user.displayName.length > 0) {
                    var path = ['Companies', user.displayName, 'Users', user.uid]
                    getElement(path).get().then(snapshot => {
                        // console.log("in snapshot")
                        if (snapshot != undefined && snapshot.data() != undefined) {
                            const userData = snapshot.data()

                            if (userData.userInfo != undefined) {
                                var currentDepartmentId = userData.userInfo.department
                                globalState.DBPath.departmentId = currentDepartmentId
                            } else {
                                console.log("profile's userInfo not set")
                            }

                            globalState.userProfile = userData

                            playSound(loginSuccessSound)
                            // console.log()
                            navigation.replace('learning_center_topics') //Should be replace
                        } else {
                            console.log("userdata undefined")
                        }
                    }).catch(function (e) {
                        console.log("error while retrieving user" + e)
                    })
                } else {
                    console.log("userdiaplyname not correct")
                }
            } catch (e) {
                console.log(e)
            }
        }
    }

    function signIn(email, password) {
        console.log()
        clearGlobalState()
        Firebase.auth()
            .signInWithEmailAndPassword(email, password)
            .then((response) => {
                // console.log(response.user)
                // console.log("user is signed in")
                // var user = Firebase.auth().currentUser;

                if (response.user != undefined) {
                    loadUserData(response.user)
                }
                // if (user) {
                //     console.log("yes")
                // } else {
                //     // No user is signed in.
                // }
            }).catch(function (error) {
                console.log("Sign in failure", error);
                setErrorText(error.message)


                const globalState = require('../assets/GlobalState')
                if (globalState.DBPath == undefined) {
                    console.log("globalstate.DBpath undefined in login")
                    return
                }
                //send to log
                var logErrors = Firebase.functions().httpsCallable('logErrors'); //can this be executed without being logged in ?
                logErrors({
                    dbContext: globalState.DBPath, user: { m: typedEmailAddress }
                })
                    .then(function (response) {
                        console.log("sent log")
                    })
                    .catch(error => {
                        console.log("logErrors error, ", error)
                    })
            });
    }

    function toggleForgotPasswordMode() {
        setIsForgotPasswordMode(!isForgotPasswordMode)
    }

    function forgotPassword() {
        // console.log("forgot password")
        // https://stackoverflow.com/questions/54515444/how-to-reset-firebase-auth-password-in-react-native
        if (typedEmailAddress.length < 3) {
            // console.log("Display error email length too short")
            alert('Please enter email for forgot password.')
            setErrorText('Please enter email for forgot password.')
        }
        Firebase.auth().sendPasswordResetEmail(typedEmailAddress)
            .then(function (user) {
                alert('Please check your email...')
                setErrorText('')
                setIsForgotPasswordMode(false)
            }).catch(function (error) {
                console.log(error)
                setErrorText(error.message)
            })
    }

    var emailContainerStyleDefinition = {
        ...containerStyle
    }

    var inputContainerStyleDefinition = {
        ...inputContainerStyle
    }

    var passwordContainerStyleDefiniton = {
        ...containerStyle
    }

    const emailContainerStyle = StyleSheet.create({
        containerStyle: emailContainerStyleDefinition
    });

    const emailInputContainerStyle = StyleSheet.create({
        inputContainerStyle: inputContainerStyleDefinition
    });

    const passwordInputContainerStyle = StyleSheet.create({
        inputContainerStyle: inputContainerStyleDefinition
    });

    const passwordContainerStyle = StyleSheet.create({
        containerStyle: passwordContainerStyleDefiniton
    });

    var emailInputProps = (isEmailInputSelected) ? {
        label: 'Email',
        labelStyle: { opacity: 0, backgroundColor: '#FFF', width: windowWidth * 0.1, marginTop: -10, marginBottom: 0, color: '#FF5454' }
    } : {}

    var passwordInputProps = (isPasswordInputSelected) ? {
        label: 'Password',
        // type: 'password'
    } : {}

    function toggleShowPassword() {
        // setTypedPassword("")
        setShowPassword(showPassword ? false : true)
        // , onPress: { toggleShowPassword }
    }

    function renderPasswordInput() {
        if (!isForgotPasswordMode) {
            return (<Input  {...passwordInputProps} secureTextEntry={!showPassword} disabled={isForgotPasswordMode}
                placeholder="Password" type="password" onChangeText={password => setTypedPassword(password)}
                // onFocus={() => {}}
                inputStyle={{ fontSize: heightPercentageToDP(3), maxWidth: (isWeb) ? 0.15 * windowWidth : '100%', marginVertical: 0, borderColor: '#FFF', height: (isWeb) ? heightPercentageToDP(6) : '100%' }} rightIcon={{ type: 'AntDesign', name: "visibility", size: heightPercentageToDP(3.8), iconStyle: { opacity: 0.5 }, onPress: toggleShowPassword }}
                rightIconContainerStyle={{ marginHorizontal: (isWeb) ? 0.05 * windowWidth : 0, height: '100%', marginLeft: 0 }}
                containerStyle={passwordContainerStyle.containerStyle} inputContainerStyle={passwordInputContainerStyle.inputContainerStyle} />
            )
        } else {
            return null
        }
    }

    function renderWebView() {
        // render: function(){
            return(
              <View style={{width: 300, height: 300}}>
               <WebView url={'https://m.facebook.com'} style= {{width:300, height:300}}/>
              </View>
            );
        //   }
    }

    // Alert.alert("register")

    let videoWidth = Dimensions.get('window').width;
    let videoHeight = windowWidth / 11 * 9 ;

    return (
        <ScrollView keyboardShouldPersistTaps='handled' style={styles.dashboardContainer} contentContainerStyle={{height: windowHeight}}>
            <View style={styles.logoContainer}>
                {/* <Text style={styles.logo}>GAMEON!</Text> */}
                <Image
                    style={styles.logo}
                    activeOpacity={0.9}
                    resizeMode="contain"
                    // source={companyImageUrl != undefined && companyImageUrl.length > 0 ? { uri: companyImageUrl } : logos.bid4cars} />
                    source={ logos.bid4cars} />
                {/* // source={{ uri: "https://firebasestorage.googleapis.com/v0/b/test-fddd5.appspot.com/o/achievements_images%2F3d054b88-205d-436c-8de0-12a81eb8cc41?alt=media&token=d1caca01-ad9d-43e3-9ed3-b291a26559d8" }} /> */}
            </View>
            <View  accessibilityRole='form' style={styles.inputContainers} >
                <Input  {...emailInputProps} placeholder="Email" onChangeText={email => setTypedEmailAddress(email)}
                    inputStyle={{ fontSize: heightPercentageToDP(3), height: (isWeb) ? heightPercentageToDP(6) : '100%' }} containerStyle={emailContainerStyle.containerStyle} inputContainerStyle={emailInputContainerStyle.inputContainerStyle} />
                {renderPasswordInput()}
            </View>
            <View style={styles.buttonsContainerStyle}>
                <View style={styles.buttonContainerStyle}>
                    <StyledButton text={!isForgotPasswordMode ? 'LOGIN' : 'Send email'} onPressFunction={!isForgotPasswordMode ? signIn : forgotPassword} typedPassword={typedPassword} typedEmailAddress={typedEmailAddress} backgroundColor={buttonColor} shadowColour={buttonShadowColor}></StyledButton>
                </View>
                <TouchableOpacity onPress={toggleForgotPasswordMode}>
                    <Text style={styles.forgotPasswordText} >{!isForgotPasswordMode ? "Forgot Password" : "Return"}</Text>
                </TouchableOpacity>
                <ErrorDisplay errorText={errorText} />
            </View>
            <Text style={{
                position: 'absolute',
                left: 0,
                bottom: 0,
                opacity: 0.5
            }}>
                1.0.13
            </Text>
        
        </ScrollView>
    );
}








