
import React from "react";
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
// import { styles } from '../../styles/Standard/common/ProfileIcon.style';
import { useNavigation } from '@react-navigation/native';
import { Icon } from 'react-native-elements'
import { Dimensions } from 'react-native';
import { heightPercentageToDP } from "react-native-responsive-screen";
import { Firebase } from '../../firebase.config';


export function SignOutIcon() {
    const windowWidth = Dimensions.get('window').width
    const navigation = useNavigation();

    return (
        // <Icon name='person' size={heightPercentageToDP(7)} color='#FFF' onPress={() => navigation.navigate('profile')} iconStyle={{marginRight: windowWidth * 0.06}}/>

        // <Icon name='power-settings-new'
        //     size={heightPercentageToDP(7)}
        //     color='#FFF'
        //     onPress={() => {
        //         Firebase.auth().signOut().then(function () {
        //             // Sign-out successful.
        //             console.log("sign out successful")
        //         }).catch(function (error) {
        //             // An error happened.
        //             console.log("Sign out error")
        //         });
        //         // const navigation = useNavigation();
        //         navigation.replace('login')
        //     }}
        //     iconStyle={{ marginLeft: windowWidth * 0.06 }} />


        <TouchableOpacity onPress={
            () => {
                Firebase.auth().signOut().then(function () {
                    // Sign-out successful.
                    console.log("sign out successful")
                }).catch(function (error) {
                    // An error happened.
                    console.log("Sign out error")
                });
                // const navigation = useNavigation();
                navigation.replace('login')
            }
        }
            style={{ marginLeft: 0.04 * windowWidth }}>
            <Text style={{ color: '#FFF', fontSize: 22, opacity: 0.87 }}>logout</Text>
        </TouchableOpacity>
    )
}