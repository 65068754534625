import { View, ScrollView } from 'react-native';
import React, { useState } from "react";

import { styles, gameCardStyle, rewardsCardStyle, leaderboardCardStyle } from '../styles/Standard/screens/Rewards.styles'; //rename
import { dashboardImages, images } from '../assets/images';

import { CardButton } from '../components/common/CardButton';
import {
	DASHBOARD_TITLE, GAME_CARD_TITLE, GAME_CARD_DESCRIPTION, REWARDS_CARD_TITLE,
	REWARDS_CARD_DESCRIPTION, LEADERBOARD_CARD_TITLE, LEADERBOARD_CARD_DESCRIPTION
} from '../assets/text/eng';

import { getElement } from '../firebase.config';
import { LoadingIndicator } from '../components/common/LoadingIndicator';
import { primaryColour, primarySideColour, cardColours } from '../styles/globalColours';
import {EmptyPageBackground} from '../components/common/EmptyPageBackground'

export function Rewards({ navigation }, props) {
	const [isLoading, setIsLoading] = useState(true)
	const [isCompletedAchievementsSet, setIsCompletedAchievementsSet] = useState(false)
	const [achievementsList, setAchievementsList] = useState()
	const [completedAchievements, setCompletedAchievements] = useState(undefined)
	const [completedTieredAchievements, setCompletedTieredAchievements] = useState(undefined)
	const [isCompletedAchievementsRequested, setIsCompletedAchievementsRequested] = useState(false)

	const globalState = require('../assets/GlobalState')

	const userID = globalState.DBPath.userId

	//put in style folder
	const cardBackgroundColours = ["#FF5454", "#549EFF", "#FF924A", "#FF5454", "#549EFF",];
	const cardShadowColours = ['#EB3C3C', '#5378FF', '#FF6500', '#EB3C3C', '#5378FF',];

	var isEmptyPage = false
	if (isLoading == false && (achievementsList == undefined || achievementsList.length < 1)) {
		isEmptyPage = true
	}


	const achievementColours = [
		{
			shadowColour: cardShadowColours[0],
			backgroundColour: cardBackgroundColours[0],
		},
		{
			shadowColour: cardShadowColours[1],
			backgroundColour: cardBackgroundColours[1],
		},
		{
			shadowColour: cardShadowColours[2],
			backgroundColour: cardBackgroundColours[2],
		},
		{
			shadowColour: cardShadowColours[3],
			backgroundColour: cardBackgroundColours[3],
		},
		{
			shadowColour: cardShadowColours[4],
			backgroundColour: cardBackgroundColours[4],
		},
	]

	//  can infinte rere-quest
	if (completedAchievements == undefined && isCompletedAchievementsRequested == false) {
		var path = ['Companies', globalState.DBPath.companyId, 'Users', userID]
		getElement(path).get().then(function (doc) {
			if (doc.exists) {
				if (doc != undefined && doc.data() != undefined) {
					const completedAchievements = doc.data().achievements.completedAchievements
					// console.log(completedAchievements)
					setCompletedAchievements(completedAchievements)
					const completedTieredAchievements = doc.data().achievements.completedTieredAchievements
					// console.log(completedTieredAchievements)
					setCompletedTieredAchievements(completedTieredAchievements)
					updateCompletedAchievements()
				} else {
					setCompletedAchievements
				}
				setIsLoading(false)
			} else {
				console.log("Document not found");
			}
		}).catch(function (error) {
			console.log("Error getting doc", error);
		});
		setIsCompletedAchievementsRequested(true) // maybe rather in die
	}


	if (achievementsList == undefined) {
		// getAndSetTopics()
		newGetAndSetTopics()
	}


	function newGetAndSetTopics() {
		var path = ['Companies', globalState.DBPath.companyId, 'Departments', globalState.DBPath.departmentId, 'Achievements']
		getElement(path).get().then(function (docs) {
			// console.log("in2")
			// console.log('\n in2 \n')
			if (docs != undefined && docs.docs != undefined) {

				// console.log('\n in1 \n')
				var newAchievementList = []
				docs.docs.map(doc => {
					var newAchievement = doc.data()
					newAchievement['documentId'] = doc.id
					newAchievement['key'] = doc.id
					newAchievement['isCompleted'] = false
					newAchievementList.push(newAchievement)
				})

				// console.log(assignColours(achievementList))
				setAchievementsList(newAchievementList)
				updateCompletedAchievements()
				setIsLoading(false)
			} else {
				console.log("Document not found");
			}
		}).catch(function (error) {
			console.log("Error getting doc", error);
		});
	}

	// 
	//Old achievement functions
	function getAndSetTopics() {
		// console.log("NB OLD ONE EXECUTING")
		var path = ['Companies', globalState.DBPath.companyId, 'Departments', globalState.DBPath.departmentId, 'Achievements', 'Achievements']
		getElement(path).get().then(function (doc) {
			if (doc.exists) {
				const achievements = doc.data()
				var achievementKeys = Object.keys(achievements)
				achievementKeys.sort()
				var achievementList = []

				for (const key in achievementKeys) {
					var newAchievement = achievements[key]
					newAchievement['key'] = key
					newAchievement['isCompleted'] = false
					achievementList.push(newAchievement)
				}

				// console.log(assignColours(achievementList))
				setAchievementsList(achievementList)
				updateCompletedAchievements()
				setIsLoading(false)
			} else {
				console.log("Document not found");
			}
		}).catch(function (error) {
			console.log("Error getting doc", error);
		});
	}

	function assignColours(achievements) {
		for (var i = 0; i < achievements.length; i = i + 1) {
			if (achievements[i] != undefined) {
				achievements[i]["backgroundColour"] = achievementColours[i].backgroundColour
				achievements[i]["shadowColour"] = achievementColours[i].shadowColour
			}
		}
		return achievements
	}

	const currentAchievements = (achievementsList != undefined) ? achievementsList : []

	function getAchievementTier(achievementId, completedTieredAchievements) {
		if (completedTieredAchievements == undefined) {
			console.log("completedTieredAchievements empty")
			return false
		}
		var keys =  Object.keys(completedTieredAchievements)
		for (var i = 0; i < keys.length; i = i + 1) {
			if (keys[i] == achievementId && completedTieredAchievements[keys[i]] != undefined && completedTieredAchievements[keys[i]] >= 0) {
				return completedTieredAchievements[keys[i]] 
			}
		}
		return -1
	}

	// for both non-tiered and tiered
	function updateCompletedAchievements() {
		// console.log("updateCompletedAchievements()")
		// console.log(completedTieredAchievements)
		console.log("update A")
		console.log(achievementsList)
		console.log(completedAchievements)
		console.log(completedTieredAchievements)
		if (achievementsList != undefined) { //seaparate
			var updatedTopics = achievementsList
			if (completedAchievements != undefined) {
				updatedTopics.forEach(achievement => {
					if (achievement != undefined && completedAchievements.includes(achievement['documentId'])) {
						achievement.isCompleted = true
					}
				})
				setIsCompletedAchievementsSet(true)
			}
			if (completedTieredAchievements != undefined) {
				updatedTopics.forEach(achievement => {
					if (achievement != undefined && achievement.isTiered == true) {
						achievement.tier = getAchievementTier(achievement['documentId'], completedTieredAchievements)
						if (achievement.tier >= 0) {
							achievement.isCompleted = true
						}
						// console.log(achievement.tier)
					}
				})
			}
			
			
			setAchievementsList(updatedTopics)
		}
	}

	console.log("rerender")

	if (!isCompletedAchievementsSet) {
		console.log("UA not set")
		updateCompletedAchievements()
	}

	const cardColourCount = cardColours.length

	function renderAchievements() {
		//test for achievement existence wher eachievement is set - for safety
		return (currentAchievements.map((achievement, i) => {
			return (
				<CardButton key={"a" + i}
					// isReward={achievement.isMonetary}
					tier={achievement.tier!= undefined?achievement.tier: -2}
					isTransparent={!achievement.isCompleted}
					collection={'trainingMaterialTopicId'}
					document={achievement['documentId']}
					title={achievement.name} description={achievement.description}
					image={(achievement.imgUrl != undefined && achievement.imgUrl.length > 0) ? { uri: achievement.imgUrl } : images[Math.floor(Math.random() *( images.length - 1))]}
					shadowColour={cardColours[i % (cardColourCount - 1)].shadow}
					backgroundColour={cardColours[i % (cardColourCount - 1)].background}
					customStyle={rewardsCardStyle}
				/>
			);
		}))
	}

	return (
		<View style={styles.dashboardContainer}>
			<LoadingIndicator isLoading={isLoading} />
			<ScrollView style={styles.scrollView}>
				<View style={styles.wideContainer}>
					<View style={styles.cardsContainerContent}>
						{
							renderAchievements()
						}
					</View>
				</View>
			</ScrollView>
			<EmptyPageBackground isEmpty={isEmptyPage}/>
		</View>
	);
}