
import React from "react";
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { styles } from '../../styles/Standard/common/ProfileIcon.style';
import { useNavigation } from '@react-navigation/native';
import { Icon } from 'react-native-elements'
import { Dimensions } from 'react-native';
import { heightPercentageToDP } from "react-native-responsive-screen";

export function ProfileIcon() {
    const windowWidth = Dimensions.get('window').width
    const navigation = useNavigation();

    return (
        <Icon name='person' size={heightPercentageToDP(7)} color='#FFF' onPress={() => navigation.navigate('profile')} iconStyle={{marginRight: windowWidth * 0.01}}/>
    )
}