
import React from "react";
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { styles } from '../../styles/Standard/common/StyledButton.style';
import { useNavigation } from '@react-navigation/native';

export function StyledButton(props) {
    const navigation = useNavigation();
    const answerIndex = props.answerIndex;

    const customShadowStyle = StyleSheet.create({
        view: {
            backgroundColor: props.shadowColour,
        }
    });

    const customButtonStyle = StyleSheet.create({
        button: {
            backgroundColor: props.backgroundColor,
        }
    });
    // backgroundColor:props.shadowColour
    return (
        <View style={[styles.thickShadowView,customShadowStyle.view ]}>
            <TouchableOpacity
                style={[styles.button, customButtonStyle.button]}
                activeOpacity={0.5}
                //    rather set styles?
                onPress={() => {
                    if (props.onPressFunction != undefined) {
                        if (props.typedEmailAddress != undefined && props.typedPassword != undefined) {
                            props.onPressFunction(props.typedEmailAddress, props.typedPassword)
                        } else if (props.newFirstName != undefined && props.newLastName != undefined) {
                            props.onPressFunction(props.newFirstName,props.newLastName)
                        } else{
                            props.onPressFunction()
                        }  
                    } else if (props.destination != undefined) {
                        if (prop.replace != undefined && prop.replace == true) {
                            navigation.replace(props.destination)
                        } else {
                            navigation.navigate(props.destination)
                        }
                    } else {
                        
                    }
                }}
                disabled={props.disabled}
            >
                
                    <Text style={styles.buttonText}> {props.text == undefined ? "Option" : props.text}</Text>
            
            </TouchableOpacity>
         </View>
    )
}