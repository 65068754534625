import { StyleSheet, useWindowDimensions } from 'react-native';
import { Dimensions } from 'react-native';

const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height
//can this handle resizing

export var styles = StyleSheet.create({
    mainView: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        marginTop: 22,
        position:'absolute'
        
    },
    centeredView: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        marginTop: 22,        
    },
    modalView: {
        // flex: 1,
        minWidth: windowWidth * 0.80,
        // backgroundColor: '#000',
        width: '80%',
        // margin: 20,
        backgroundColor: "white",
        borderRadius: 20,
        // padding: 35,
        alignItems: "center",
        shadowColor: "#000",
        maxHeight: windowHeight * 0.7,
        shadowOffset: {
            width: 0,
            height: 2
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5
    },
    openButton: {
        backgroundColor: "#EEE",////////
        borderRadius: 20,
        padding: 10,
        // elevation: 2,
        // marginTop: windowHeight * 0.1,
        width: '100%'
    },
    textStyle: {
        color: "#6200EE",
        fontWeight: "bold",
        textAlign: "center"
    },
    modalText: {
        marginBottom: 15,
        textAlign: "center"
    }
});
// use start en end radius for the smooth

