import { View, ScrollView, Platform, Dimensions } from 'react-native';
import React, { useState, useContext } from "react";

import { CardButton } from '../components/common/CardButton';
import { images, dashboardImages } from '../assets/images';
import { getElement, Firebase } from '../firebase.config';
import { styles, rewardsCardStyle } from '../styles/Standard/screens/LearningCenterTopics'; //rename
import { LoadingIndicator } from '../components/common/LoadingIndicator';
import { primaryColour, primarySideColour, cardColours } from '../styles/globalColours';
import { useNavigation } from '@react-navigation/native';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';

import { EmptyPageBackground } from '../components/common/EmptyPageBackground'
import { ThemeContext } from '../App';

const windowHeight = Dimensions.get('window').height
const windowWidth = Dimensions.get('window').width

export function LearningCenterTopics({ navigation }) {
	const [groupedTopics, setGroupedTopics] = useState()
	const [isLoading, setIsLoading] = useState(true)
	const [departmentInfo, setDepartmentInfo] = useState()
	const [madeDepartmentRequest, setMadeDepartmentRequest] = useState(false)
	const [backgroundImageUrl, setBackgroundImageUrl] = useState()
	const [requestCount, setRequestCount] = useState(0)

	const context = useContext(ThemeContext)

	const globalColours = require('../styles/globalColours')

	{
		var gameState = require('../assets/GameState')
		const globalState = require('../assets/GlobalState')
		gameState.state.afterGameInfo = []
		gameState.state.uncompletedCategories = []
		gameState.state.categoryScores = {}
		gameState.isLeaderboardUpdated = true
		gameState.gameInfo.maximumGameScore = undefined
		globalState.DBPath.gameId = undefined
		globalState.DBPath.gameCategoryId = undefined
		globalState.DBPath.learningSubTopicId = undefined
		globalState.DBPath.trainingMaterialTopicId = undefined
	}


	{
		const globalState = require('../assets/GlobalState')
		if (Platform.OS != 'android' && Platform.OS != 'ios') {
			if (windowWidth / windowHeight > 0.9) {
				globalState.portraitView = false
			} else {
				globalState.portraitView = true
			}
		} else {
			globalState.portraitView = true
		}
	}

	if (departmentInfo == undefined && madeDepartmentRequest == false) {
		const globalState = require('../assets/GlobalState')
		// const globalState = require('../assets/GlobalState')
		if (globalState.DBPath.companyId != undefined && globalState.DBPath.departmentId != undefined) {
			var path = ['Companies', globalState.DBPath.companyId] // primary and secondary colour set at this time might not update sylesheets
			getElement(path).get().then(function (doc) {
				if (doc != undefined && doc.data() != undefined) {
					if (doc.data().colors != undefined && doc.data().colors['0'] != undefined) {
						const newPrimaryColor = doc.data().colors['0']
						if (typeof newPrimaryColor == "string" && newPrimaryColor[0] === "#") {
							globalColours.primaryColour = newPrimaryColor
							context.setColor(globalColours.primaryColour)
							setBackgroundImageUrl(doc.data().backgroundImgUrl)
						}
						if (doc.data().logoUrl != undefined) {
							globalState.companyLogoUrl = doc.data().logoUrl

						} else {
							console.log("CompanyLogoUndefined")
						}
					}

					// if (doc.data().colors['1'] != undefined) {
					// 	const newPrimarySideColor = doc.data().colors['1']
					// 		if (typeof newPrimarySideColor == "string" && newPrimarySideColor[0] === "#") {
					// 			globalColours.primarySideColor = newPrimarySideColor
					// 		}
					// }

				} else {
					console.log("Document not found");
					if (requestCount < 5) {
						setRequestCount(requestCount + 1)
						setMadeDepartmentRequest(false)

					} else {
						console.log("maximum dashboard requests reached")
					}
				}
			})
			setMadeDepartmentRequest(true)

			console.log("request department")
			var path = ['Companies', globalState.DBPath.companyId, 'Departments', globalState.DBPath.departmentId]
			getElement(path).get().then(function (doc) {
				if (doc != undefined) {
					setDepartmentInfo(doc.data())
				} else {
					console.log("Document not found");
				}
				// if (checkProfileFields(globalState.userProfile)) {
				setIsLoading(false)
				// }
			}).catch(function (error) {
				// console.log(error)
				console.log("Error getting department doc", error);
				Alert.alert("Error getting department doc", error.message)
				navigation.replace('login')
			});
		} else {
			console.log('An important property for the firestore path is not set in globalState.DBPath.')
			navigation.replace('login')
			// navigation.goBack()
		}

		if (globalState.isLeaderboardFunctionReady == undefined) {
			try {
				console.log("leaderboardFunction")
				var isLeaderboardFunctionReady = Firebase.functions().httpsCallable('leaderboardFunctionReady');
				isLeaderboardFunctionReady({}).then(function (response) {
					if (response != undefined && response.data != undefined && response.data == true) {
						globalState.isLeaderboardFunctionReady = true
					} else {
						globalState.isLeaderboardFunctionReady = false
					}
				})
			} catch (e) {
				console.log("leaderboardfunctionready problem")
				globalState.isLeaderboardFunctionReady = false
				console.log(e)
			}
		}
	}



	if (groupedTopics == undefined) {
		getAndSetTopics()
	}

	const cardBackgroundColours = ["#FF5454", "#549EFF", "#FF924A", "#FF5454", "#549EFF",];
	const cardShadowColours = ['#EB3C3C', '#5378FF', '#FF6500', '#EB3C3C', '#5378FF',];

	const achievementColours = [
		{
			shadowColour: cardShadowColours[0],
			backgroundColour: cardBackgroundColours[0],
		},
		{
			shadowColour: cardShadowColours[1],
			backgroundColour: cardBackgroundColours[1],
		},
		{
			shadowColour: cardShadowColours[2],
			backgroundColour: cardBackgroundColours[2],
		},
		{
			shadowColour: cardShadowColours[3],
			backgroundColour: cardBackgroundColours[3],
		},
		{
			shadowColour: cardShadowColours[4],
			backgroundColour: cardBackgroundColours[4],
		},
	]

	var isEmptyPage = false
	if (isLoading == false && (groupedTopics == undefined || groupedTopics.length < 1)) {
		isEmptyPage = true
	}

	function getAndSetTopics() {

		const globalState = require('../assets/GlobalState')
		if (globalState.DBPath.companyId == undefined || globalState.DBPath.departmentId == undefined || globalState.DBPath.companyId.length < 1 || globalState.DBPath.departmentId.length < 1) {
			// alert('Company or Department not set in context.')
			navigation.replace('login')
			return
		}
		var path = ['Companies', globalState.DBPath.companyId, 'Departments', globalState.DBPath.departmentId, 'TrainingMaterial']
		getElement(path).get().then(function (docs) {
			if (docs != undefined && docs.docs != undefined) {
				var topics = []
				docs.docs.map(doc => {
					var newTopic = doc.data()
					newTopic['documentId'] = doc.id
					if (doc.data() != undefined && doc.data().disabled != true) {
						topics.push(newTopic)
					}
				})
				setGroupedTopics(assignColours(topics))
				setIsLoading(false)
			} else {
				console.log("Document not found");
			}
		}).catch(function (error) {
			console.log("Error getting doc", error);
		});
	}

	//todo add icon/style for completion indication

	const currentGroupedTopics = (groupedTopics != undefined) ? groupedTopics : []

	function assignColours(list) {
		// console.log("assign")
		for (var i = 0; i < list.length; i = i + 1) {
			if (list[i] != undefined) {
				list[i]["backgroundColour"] = achievementColours[i].backgroundColour
				list[i]["shadowColour"] = achievementColours[i].shadowColour
			}
		}
		return list
	}



	function renderTopics() {
		const cardColourCount = cardColours.length
		return (currentGroupedTopics.map((topic, i) => {
			return (
				<CardButton key={"a" + i}
					destination='learning_center_subtopics'
					collection={'trainingMaterialTopicId'}
					document={topic['documentId']}
					title={topic.name}
					description={topic.subtitle}
					image={(topic.imgUrl != undefined && topic.imgUrl.length > 0) ? { uri: topic.imgUrl } : images[Math.floor(Math.random() * (images.length - 1))]}
					shadowColour={cardColours[i % (cardColourCount - 1)].shadow}
					backgroundColour={cardColours[i % (cardColourCount - 1)].background}
					customStyle={rewardsCardStyle} />
			)
		}))
	}

	return (
		<View style={styles.dashboardContainer}>
			<LoadingIndicator isLoading={isLoading} />
			<ScrollView style={styles.cardsContainer}>
				<View style={styles.wideContainer}>
					<View style={styles.cardsContainerContent}>
						{
							renderTopics()
						}
					</View>
				</View>
			</ScrollView>
			<EmptyPageBackground isEmpty={isEmptyPage} />
		</View>
	);
}


