import { Alert } from "react-native"

export function checkProfileFields(profile) {
    var isValid = true
    if (profile != undefined) {
        console.log("profile not defined")
        isValid = false
    } 
    if (profile.userInfo != undefined) {
        console.log("userInfo not defined")
        isValid = false
        } 
        if (profile.achievements != undefined ) {
            console.log("achievements not defined")
            isValid = false
        } 
        if (profile.gameStats != undefined) {
            console.log("gameStats not defined")
            isValid = false
        } 
        if (profile.gamesCompleted != undefined ) {
            console.log("gamecompleted not defined")
            isValid = false
        } 
        if (profile.unlistedGameStats != undefined) {
            console.log("unlistedGameStats not defined")
            isValid = false
    }
     return isValid
}