import firebase from 'firebase'; // add /app to reduce js size
import '@firebase/firestore';
import '@firebase/functions';

const devConfig = {
       apiKey: "AIzaSyCIDoTvuzR70Mng4F9Pf7NScpIhMS4PFEY",
       authDomain: "test-fddd5.firebaseapp.com",
       databaseURL: "https://test-fddd5.firebaseio.com",
       projectId: "test-fddd5",
       storageBucket: "test-fddd5.appspot.com",
       messagingSenderId: "114127228913",
       appId: "1:114127228913:web:e375d66d7a5c3430befe50"
}

const prodConfig =  {
  apiKey: "AIzaSyDuYPONzockLRy9I1fkNZAD3PwyfTbGbkM",
  authDomain: "gameon-97c5e.firebaseapp.com",
  databaseURL: "https://gameon-97c5e.firebaseio.com",
  projectId: "gameon-97c5e",
  storageBucket: "gameon-97c5e.appspot.com",
  messagingSenderId: "776164012568",
  appId: "1:776164012568:web:4fd22ea6f50eb65c28896d"
}
 export const Firebase =  firebase.initializeApp(prodConfig);
//  export const Firebase =   firebase.initializeApp(devConfig);

console.log(__dirname)

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('./firebase-messaging-sw.js')
    .then(function(registration) {
      console.log('Registration successful, scope is:', registration.scope);
    }).catch(function(err) {
      console.log('Service worker registration failed, error:', err);
    });
}
  
// if('serviceWorker' in navigator) { 
//   navigator.serviceWorker.register('../firebase-messaging-sw.js')
// .then(function(registration) {
//  console.log("Service Worker Registered");
// messaging.useServiceWorker(registration);  
//   }); 
//   }


export const db = firebase.firestore();
var storage = firebase.storage()
var storageRef = storage.ref()
var appImagesRef = storageRef.child('component_images')
var anImageRef = appImagesRef.child('Business-announcement.png')


export function getElement(path) {
    var nextPath = path
    var dbElement = db
	var nextDestination
	var currentDepth = 0
    while (path.length > 0) {
        nextDestination = path.shift()
		dbElement = (currentDepth % 2 == 0) ? dbElement.collection(nextDestination): dbElement.doc(nextDestination) 
		currentDepth++ 
    }
    return dbElement;
}

//path includes last slash
export function getStorageRef(uri) {
  return firebase.storage().ref(uri)
}

export function getFBIdToken() {
  console.log(firebase.auth().user)
  console.log("ID")
}



// note firebase is in testing mode, meaning internal data is exposed to anyone with the link to the database

// note RTDB does not support offline storage for web

// More notes: https://pamartinezandres.com/lessons-learnt-the-hard-way-using-firebase-realtime-database-c609b52b9afb
// We also enabled an extra feature “keepSync”, we thought that syncing the database node containing all user features will speed up app UX.That is probably true, but this was our terrible error and the major culprit of the 1000€ invoice.
// database.getReference(getUserFavoritesPath(getCurrentUid())).keepSynced(true)
// We thought that Firebase SDK was intelligent enough to avoid downloading data from the Cloud if the data hasn’t changed, but what was happening was that a full copy of all favorites was downloaded every time the user started the app. And billed, of course.