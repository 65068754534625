
import { StyleSheet } from 'react-native';
import { Dimensions } from 'react-native';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen'
import {isPortraitView} from '../../../utils/isPortraitView'

const portraitView = isPortraitView()

const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height

//make colour setting in style sheet a props parameter and put in main function

// if more flex help needed look at: https://stackoverflow.com/questions/43143258/flex-vs-flexgrow-vs-flexshrink-vs-flexbasis-in-react-native/43147710#43147710
export const styles = StyleSheet.create({
    button: {
        // backgroundColor: "#039",
        flex: 1,
        borderRadius: windowHeight * 0.05,
        // marginHorizontal: ' 3%',
        width: '100%',
        marginBottom: windowHeight * 0.012,
        marginVertical: 0,
        minWidth: portraitView?0.85* windowWidth:0,
        // alignItems: "center",
        justifyContent: "center",
        // maxHeight: windowHeight
        // height: 200,
        // textAlignVertical: 'middle',
        // width: widthPercentageToDP('45'),
        // height: heightPercentageToDP('45')

        // Shadow:
        // shadowOffset: {
        //     width: windowWidth*0.04,
        //     height: windowHeight * 0.01,
        //     blur: 20, // scale
        //     //scale these
        // },
        // elevation: windowHeight * 0.01,
        // shadowRadius: windowHeight * 0.004,
        // shadowOpacity: 0.75,
        // paddingBottom: windowHeight* 0.004,
        // marginBottom: windowHeight* 0.02
    },
    buttonText: {
        textAlign: "left",
        // textAlignVertical: "center",
        // fontSize: windowWidth * 0.05,
        fontSize:hp(4),
        fontWeight:'500',
        color: '#FFF',
        opacity:0.87,
        alignItems: 'center',
        // overflow: 'hidden',
        // marginTop:windowHeight * 0.04,///////////////////////////// HP or another way
        // flex: 1,
        // width:
        // marginTop: windowHeight * 0.002,
        // justifyContent: "center",
        // marginVertical:'10%',
        // flexWrap: 'wrap'
        // flexShrink: 1,
        // alignSelf: 'center',
        // wordWrap: 'break-word',
        // hyphens: 'auto',
    },
    thickShadowView: {
        flex: 1,
        borderRadius: windowHeight * 0.05,
        marginHorizontal: ' 3%',
        marginBottom: 0,
        alignItems: "center",
        justifyContent: "center",
        minHeight: windowHeight * 0.13,
        maxHeight: windowHeight * 0.13,
        marginTop: 0.05 * windowHeight,
        minWidth: portraitView ? 0.85 * windowWidth : 0,
        maxWidth:portraitView ? 1 * windowWidth : '100%',
        // shadowOffset: {
        //     width: windowWidth*0.04,
        //     height: windowHeight * 0.01,
        //     blur: 20, // scale
        //     //scale these
        // },
        // elevation: windowHeight * 0.01,
        // shadowRadius: windowHeight * 0.004,
        shadowOffset: {
            width: 0,
            height: windowHeight * 0.002
            //scale these
        },
        // elevation: windowHeight * 0.005,
        elevation: windowHeight * 0.01,
        shadowRadius: windowHeight * 0.004,
        shadowOpacity: 0.75,
        // paddingBottom: windowHeight* 0.004,
        marginBottom: windowHeight* 0.01,
        // backgroundColor: "#FF0",
    },
    descriptionText: {
        color: '#FFF',
		// fontSize: 14,
		fontSize: hp(2),
		opacity: 0.6,
		// marginHorizontal: windowHeight * 0.02,
    },
    textBox: {
        marginLeft: windowWidth * 0.05,
        // height: 100,
        minWidth: windowWidth * 0.4,
        // backgroundColor: '#000'
    },
    examHeadingRow: {
        flexDirection: "row"
    }
})