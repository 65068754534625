import { View, ScrollView, Dimensions } from 'react-native';
import React, { useState } from "react";

import { ListItem } from 'react-native-elements';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { heightPercentageToDP } from 'react-native-responsive-screen';

import { styles } from '../styles/Standard/screens/AfterGame.styles';
import { gameCategoriesImages } from '../assets/images';
import { Leaderboard } from './Leaderboard';

import { playSound } from '../utils/playSound';
import { categoryWinSound } from '../assets/sounds'
import { useNavigation } from '@react-navigation/native';
import { GameScreen } from './GameScreen';

import { isPortraitView } from '../utils/isPortraitView'
import { gameInfo } from '../assets/GameState';
import { primaryColour } from '../styles/globalColours';


const windowHeight = Dimensions.get('window').height
const windowWidth = Dimensions.get('window').width

const Tab = createMaterialTopTabNavigator();

var globalState = require('../assets/GlobalState')
var gameState = require('../assets/GameState')

export function AfterGame({ route, navigation }, props) {
    const [playedSound, setPlayedSound] = useState(false)

    globalState.isGameLeaderboard = true

    if (playedSound == false) {
        playSound(categoryWinSound)
        setPlayedSound(true)
    }

    // if (gameState != undefined && gameInfo != undefined) {
    //     gameState.gameInfo.maximumGameScore = 0
    // }
    // if (route.params == undefined) {
    //     navigation.replace('dashboard')
    //     return null
    // }

    // const { passedData } = route.params

    function myAnswers() {
        var list = []

        if (gameState != undefined && gameState.state.afterGameInfo != undefined) {
            // playSound(categoryWinSound)
            list = gameState.state.afterGameInfo
            // console.log(list)
        }
        // if (passedData != undefined && passedData.afterGameInfo != undefined) {
        //     // playSound(categoryWinSound)
        //     list = passedData.afterGameInfo
        // }

        if (list == undefined) {
            return (null)
        }

        function renderWebAnswers() {
            const cardsContainerWidth = (!isPortraitView()) ? 0.7 * windowHeight * 1.2 : windowWidth

            const navigation = useNavigation();

            return (list.map((l, i) => (
                <ListItem
                    key={i}
                    title={'Q: ' + l.questionText}
                    titleStyle={{ fontSize: heightPercentageToDP(3), marginLeft:0 }}
                    subtitle={'My Answer: ' + l.answerText}
                    subtitleStyle={{ fontSize: heightPercentageToDP(2) }}
                    contentContainerStyle={{ marginLeft: 0.01 * windowWidth, width: '100%' }}
                    // containerStyle={{width: (isPortraitView())?cardsContainerWidth : windowWidth}}
                    rightIcon={{ name: (l.isCorrect) ? 'check' : 'close',  color: (l.isCorrect == 1) ? '#00AD1F' : '#FF0000', size: heightPercentageToDP(5), iconStyle: { opacity: 0.87 } }}
                    containerStyle={{ width: '100%' }}
                    leftIcon={{
                        name: 'info', size: heightPercentageToDP(5), color:primaryColour,
                        iconStyle: { opacity: 0.87, marginRight:0},
                        style:{marginRight:0 },
                        containerStyle:{ marginRight:0},
                        onPress:
                            () => {
                                console.log("onpress")
                                console.log(l.whereToFindAnswer)
                                if (l.whereToFindAnswer != undefined &&
                                    l.whereToFindAnswer.learningTopicId != undefined && l.whereToFindAnswer.learningTopicId.length > 0 &&
                                    l.whereToFindAnswer.learningSubTopicId != undefined && l.whereToFindAnswer.learningSubTopicId.length > 0 &&
                                    l.whereToFindAnswer.whereToFind != undefined && l.whereToFindAnswer.whereToFind.length > 0
                                ) {
                                    globalState.DBPath.trainingMaterialTopicId = l.whereToFindAnswer.learningTopicId
                                    globalState.DBPath.learningSubTopicId = l.whereToFindAnswer.learningSubTopicId

                                    globalState.whereToFindAnswer = (l.whereToFindAnswer.whereToFind != undefined && l.whereToFindAnswer.whereToFind.length > 0) ?
                                        l.whereToFindAnswer.whereToFind : ""
                                    navigation.navigate('learning_center_material')

                                }
                            }
                    }}
                    // badge={{ value: l.isCorrect, textStyle: { color: '#000', opacity: 0.6, fontWeight: '500' },, containerStyle: { marginTop: 0 } }}
                    bottomDivider
                />
            )))
        }

        return (
            <ScrollView style={styles.background}>
                <View style={styles.wideContainer}>
                    <View style={styles.cardsContainerContent}>
                        {renderWebAnswers()}
                    </View>
                </View>
            </ScrollView>
        );
    }


    return (
        <View style={styles.background}>
            {/* <Text>{(props.passedData != undefined) ? "passed": "passedData undef"}</Text> */}
            <Tab.Navigator initialRouteName='leaderboard'>
                <Tab.Screen name="leaderboard" component={Leaderboard} options={{ title: 'Scoreboard' }} />
                <Tab.Screen name="my_answers" component={myAnswers} passedData={props.passedData} options={{ title: 'My Answers' }} />
            </Tab.Navigator>
        </View>
    );
}



